import styles from './ManuscriptsUploads.module.css';
import { FaGoogle, FaMicrosoft } from 'react-icons/fa'; // Example icons, you can use your preferred icons
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
// import gmail from '../../icons/google_signin_buttons 2/web/1x/btn_google_signin_light_normal_web.png';
import gmail from '../../icons/gmail.svg';
import gmailHover from '../../icons/google_signin_buttons 2/web/1x/btn_google_signin_light_focus_web.png';
import gmailPreesed from '../../icons/google_signin_buttons 2/web/1x/btn_google_signin_light_pressed_web.png';
import outlook from '../../icons/outlook.svg';
import GenreCard from '../home/GenreCard';
import CategoriesCard from '../home/CategoriesCard';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useRecoilState } from 'recoil';
import {
  companyInfoState,
  userInfoState,
  numberOfUploadsState,
  uploadMethodOptionState,
  manuscriptsUploadsState,
  uploadMethodcheckedState,
  uploadMethodProviderState,
  contentAtom,
} from '../../state';
import useApiUtils from '../../useApiUtils';
import CircularProgress from '@mui/material/CircularProgress';
import EmailIntegration from './EmailIntegration';
import MailBox_Conecter from './MailBox_Conecter';
import DadicatedEmail from './DadicatedEmail';
import CancelEmail from './CancelEmail';
import tooltipIcon from '../../icons/icons8-info (1).svg';
import { renderCenteredAlert } from '../CenteredAlert';

const ManuscriptsUploads = (props) => {
  const [integrationInfo, setIntegrationInfo] = React.useState(null);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [manuscriptsUploads, setManuscriptsUploads] = useRecoilState(
    manuscriptsUploadsState
  );
  const [userInfo, setUserInfo] = useRecoilState(userInfoState); // Update the path
  const [companyInfo, setCompanyInfo] = useRecoilState(companyInfoState);

  const [privacyPolicyChecked, setPrivacyPolicyChecked] = React.useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [numberOfUploads, setNumberOfUploads] =
    useRecoilState(numberOfUploadsState);
  const [code, setCode] = React.useState(null);
  const navigate = useNavigate();
  const [selectedProvider, setSelectedProvider] = useRecoilState(
    uploadMethodProviderState
  );

  const [options, setOptions] = useRecoilState(uploadMethodOptionState);

  const [checked, setChecked] = useRecoilState(uploadMethodcheckedState);

  const [imageGoogle, setImageGoogle] = React.useState(gmail);
  const [integration_window, setIntegration_window] = React.useState(false);
  const [saveButtonState, setSaveButtonState] = React.useState('normal'); // 'normal', 'saving', 'success', 'failure'

  const [mailBox_Conecter_window, setMailBox_Conecter_window] =
    React.useState(false);

  const [dedicated_window, setDedicated_window] = React.useState(false);

  const [gmailwasClicked, setGmailwasClicked] = React.useState(false);
  const [outlookwasClicked, setOutlookwasClicked] = React.useState(false);
  const [integrated_email_alias, setIntegrated_email_alias] =
    React.useState('');
  const [integradedEmailPending, setIntegradedEmailPending] =
    React.useState(false);

  const [integradedEmail, setIntegradedEmail] = React.useState(false);

  const [showCancelEmail, setShowCancelEmail] = React.useState(false);
  const [email_alias, setEmail_alias] = React.useState('');

  const {
    getManuscriptsInfo,
    initialgetBookChapter,
    get_book_scores,
    getbookgenrekeywords,
    get_user_info,
    getCompanyInformation,
    uploadMethod,

    // Add other functions from the hook if needed
  } = useApiUtils();

  const handleUploadsVia = async (category) => {
    // if useer is level 1, then he is not authorized to perform this action
    if (userInfo.role === 'lvl1') {
      props.showErrorMessage();
      renderCenteredAlert('You are not authorized to perform this action');
      return;
    }

    if (category === 'Forward emailed submissions straight to your dashboard') {
      if (
        options.find(
          (option) =>
            option.value ===
            'Forward emailed submissions straight to your dashboard'
        ).selected
      ) {
        setShowCancelEmail(true);
      } else {
        if (
          options.find(
            (option) => option.value === 'Integrate your existing email account'
          ).selected
        ) {
          renderCenteredAlert(
            'You have already integrated your email address. If you want to create a dedicated email account, please cancel the integration first'
          );
          return;
        }
        setDedicated_window(true);
        return;
      }
    }
    if (category === 'Manual uploads') {
      setSaveButtonState('normal');
      renderCenteredAlert(
        'The option for manual uploads is non-cancelable .If you want to cancel it, please contact us'
      );
      setSaveButtonState('normal');
      return;
    }
    if (category === 'Integrate your existing email account') {
      if (
        options.find(
          (option) => option.value === 'Integrate your existing email account'
        ).selected
      ) {
        setShowCancelEmail(true);
      } else {
        if (
          options.find(
            (option) =>
              option.value ===
              'Forward emailed submissions straight to your dashboard'
          ).selected
        ) {
          renderCenteredAlert(
            'You have already created a dedicated email account. If you want to Integrate your existing email account, please cancel the dedicated email account first'
          );
          return;
        }
        setSaveButtonState('normal');
        setIntegration_window(true);
        return;
      }
    }
  };

  useEffect(() => {
    const storedState = localStorage.getItem('currentState');
    const thecode = queryParams.get('code');
    if (!storedState && !thecode) {
      if (
        companyInfo &&
        companyInfo.upload_method_settings?.upload_preferences
      ) {
        const newOptions = options.map((c) => {
          if (c.value === 'Manual uploads') {
            return {
              ...c,
              selected:
                companyInfo?.upload_method_settings.upload_preferences
                  ?.uploads_via_manual,
            };
          }
          if (c.value === 'Integrate your existing email account') {
            if (
              companyInfo.upload_method_settings?.upload_preferences
                ?.uploads_via_integrated_email
            ) {
              const connectorsKeys = Object.keys(
                companyInfo.upload_method_settings?.ingest_connectors
              );
              // Access the first key, which in your case is 'gmail_5a3a6631be04'
              const firstConnectorKey = connectorsKeys[0];
              // Use the first key to access the corresponding connector object
              const firstConnector =
                companyInfo.upload_method_settings.ingest_connectors[
                  firstConnectorKey
                ];
              // Access the provider of the first connector
              const firstConnectorProvider = firstConnector?.provider;
              setNumberOfUploads(firstConnector?.max_lookback_days);

              setIntegrated_email_alias(
                firstConnector?.email_address
                  ? firstConnector?.email_address
                  : 'Temporarily Unavailable'
              );

              if (firstConnectorProvider === 'gmail') {
                setGmailwasClicked(true);
                setOutlookwasClicked(false);
                setSelectedProvider('gmail');
              } else if (firstConnectorProvider === 'microsoft') {
                setOutlookwasClicked(true);
                setGmailwasClicked(false);
                setSelectedProvider('outlook');
              } else {
                setGmailwasClicked(false);
                setOutlookwasClicked(false);
              }
              return {
                ...c,
                selected:
                  companyInfo.upload_method_settings?.upload_preferences
                    .uploads_via_integrated_email,
              };
            } else {
              return {
                ...c,
                selected: false,
              };
            }
          }
          if (
            c.value === 'Forward emailed submissions straight to your dashboard'
          ) {
            if (
              companyInfo.upload_method_settings?.upload_preferences
                ?.uploads_via_dedicated_email
            ) {
              const connectorsKeys = Object.keys(
                companyInfo.upload_method_settings?.ingest_connectors
              );
              // Access the first key, which in your case is 'gmail_5a3a6631be04'
              const firstConnectorKey = connectorsKeys[0];
              // Use the first key to access the corresponding connector object
              const firstConnector =
                companyInfo.upload_method_settings.ingest_connectors[
                  firstConnectorKey
                ];
              setEmail_alias(firstConnector?.email_address);
              setIntegradedEmailPending(firstConnector?.status === 'pending');
            }

            return {
              ...c,
              // selected: companyInfo.upload_method.uploads_via_dedicated_email,
              selected:
                companyInfo.upload_method_settings?.upload_preferences
                  ?.uploads_via_dedicated_email,
            };
          }
          return c;
        });
        setOptions(newOptions);
      }
    } else if (storedState && thecode) {
      const code = queryParams.get('code');

      const parsedState = JSON.parse(storedState);
      setManuscriptsUploads(parsedState.manuscriptsUploads);
      // setOptions(parsedState.options);
      setSelectedProvider(parsedState.selectedProvider);
      setNumberOfUploads(parsedState.numberOfUploads);
      const newOptions = options.map((c) => {
        if (c.value === 'Integrate your existing email account') {
          return {
            ...c,
            selected: true,
          };
        }
        return c;
      });
      setOptions(newOptions);
      const manualUploads = parsedState.options.find(
        (option) => option.value === 'Manual uploads'
      ).selected;
      //if selected provider is gmail, then we need to change the image of the gmail
      if (parsedState.selectedProvider === 'gmail') {
        setImageGoogle(gmailPreesed);
        setGmailwasClicked(true);
        setOutlookwasClicked(false);
      }
      if (parsedState.selectedProvider === 'outlook') {
        setOutlookwasClicked(true);
        setGmailwasClicked(false);
      }
      setChecked(parsedState.checked);
      setPrivacyPolicyChecked(parsedState.privacyPolicyChecked);
      // props.setManuscriptsUploads(parsedState.location_is_upload);

      // Clear the stored state from local storage
      localStorage.removeItem('currentState');
      const IntegrationInfo = {
        max_lookback: parsedState.numberOfUploads,
        manualUploads: manualUploads,
        selectedProvider: parsedState.selectedProvider,
        code: code,
      };
      // setSelectedProvider(parsedState.selectedProvider);
      setIntegrationInfo(IntegrationInfo);
      setMailBox_Conecter_window(true);

      // handleNewIntegration(parsedState.numberOfUploads, manualUploads, parsedState.selectedProvider, code);
    } else if (!thecode && storedState) {
      // remove the stored state
      localStorage.removeItem('currentState');
    }
  }, [companyInfo, navigate]);

  useEffect(() => {
    const code = queryParams.get('code');
    if (code) {
      setCode(code);
    }
  }, [queryParams]);

  // Define the button text based on the button state
  const getButtonText = () => {
    switch (saveButtonState) {
      case 'saving':
        return 'Saving...';
      case 'success':
        return 'Saved Successfully';
      case 'failure':
        return 'Failed to Save';
      case 'loading':
        return <CircularProgress size={15} color="inherit" />;
      default:
        return 'Save Changes';
    }
  };

  return (
    <div className={styles.logosignincontainer}>
      {integration_window && (
        <EmailIntegration
          selectedProvider="outlook"
          setIntegration_window={setIntegration_window}
        />
      )}
      {mailBox_Conecter_window && (
        <MailBox_Conecter
          setMailBox_Conecter_window={setMailBox_Conecter_window}
          selectedProvider={integradedEmail.selectedProvider}
          integrationInfo={integrationInfo}
        />
      )}
      {dedicated_window && (
        <DadicatedEmail setDedicated_window={setDedicated_window} />
      )}
      {showCancelEmail && (
        <CancelEmail setShowCancelEmail={setShowCancelEmail} />
      )}
      <div className={styles.signcontainer}>
        {options.find(
          (option) => option.value === 'Integrate your existing email account'
        ).selected &&
          !options.find(
            (option) =>
              option.value ===
              'Forward emailed submissions straight to your dashboard'
          ).selected && (
            <div className={styles.body}>
              <div className={styles.titlelogo}>
                <div className={styles.loginparttitle}>
                  Choose Upload Method
                </div>
              </div>
              <div className={styles.loginpartsubtitle}>
                <strong>Need help? </strong> You can reach us anytime via{' '}
                <a
                  className={styles.linktomail}
                  href="mailto:info@storywise.ai"
                >
                  info@storywise.ai
                </a>
              </div>
              <div className={styles.titles}>Manuscript uploads via..</div>

              <div className={styles.listofcard}>
                {options.map((option, index) => (
                  <CategoriesCard
                    key={index}
                    categoty={option.value}
                    selected={option.selected}
                    onClick={handleUploadsVia}
                    clickable={true}
                    greenButtons={true}
                    hasCheckbox={true}
                  />
                ))}
              </div>
              <div className={styles.titles}>
                Your email provider:{' '}
                {gmailwasClicked && <React.Fragment>Gmail</React.Fragment>}
                {outlookwasClicked && <React.Fragment>Outlook</React.Fragment>}
              </div>

              <div className={styles['word-count-container']}>
                <div className={styles.titles}>
                  Integrated Email Address:{' '}
                  {integrated_email_alias ? integrated_email_alias : ''}
                </div>
              </div>
            </div>
          )}
        {options.find(
          (option) =>
            option.value ===
            'Forward emailed submissions straight to your dashboard'
        ).selected &&
          !options.find(
            (option) => option.value === 'Integrate your existing email account'
          ).selected && (
            <div className={styles.body}>
              <div className={styles.titlelogo}>
                <div className={styles.loginparttitle}>
                  Choose Upload Method
                </div>
              </div>
              <div className={styles.loginpartsubtitle}>
                <strong>Need help? </strong> You can reach us anytime via{' '}
                <a
                  className={styles.linktomail}
                  href="mailto:info@storywise.ai"
                >
                  info@storywise.ai
                </a>
              </div>
              <div className={styles.titles}>Manuscript uploads via..</div>

              <div className={styles.listofcard}>
                {options.map((option, index) => (
                  <CategoriesCard
                    key={index}
                    categoty={option.value}
                    selected={option.selected}
                    onClick={handleUploadsVia}
                    greenButtons={true}
                    hasCheckbox={true}
                  />
                ))}
              </div>
              {!integradedEmailPending && (
                <div className={styles['word-count-container']}>
                  <div className={styles.titles}>
                    Integrated Email Address:{' '}
                    {email_alias ? email_alias : 'Temporarily Unavailable'}
                  </div>
                </div>
              )}
              {/* if integradedEmailPending so display hear a message about this  */}
              {integradedEmailPending && (
                <div className={styles.loginpartsubtitle}>
                  Your request is pending. We will create your dedicated email
                  account shortly.
                </div>
              )}
            </div>
          )}
        {!options.find(
          (option) => option.value === 'Integrate your existing email account'
        ).selected &&
          !options.find(
            (option) =>
              option.value ===
              'Forward emailed submissions straight to your dashboard'
          ).selected && (
            <div className={styles.body}>
              <div className={styles.titlelogo}>
                <div className={styles.loginparttitle}>
                  Choose Upload Method
                </div>
              </div>
              <div className={styles.loginpartsubtitle}>
                <strong>Need help? </strong> You can reach us anytime via{' '}
                <a
                  className={styles.linktomail}
                  href="mailto:info@storywise.ai"
                >
                  info@storywise.ai
                </a>
              </div>
              <div className={styles.titles}>Manuscript uploads via..</div>

              <div className={styles.listofcard}>
                {options.map((option, index) => (
                  <CategoriesCard
                    key={index}
                    categoty={option.value}
                    selected={option.selected}
                    onClick={handleUploadsVia}
                    greenButtons={true}
                    showhand={true}
                    hasCheckbox={true}
                  />
                ))}
              </div>
            </div>
          )}
      </div>
    </div>
  );
};

export default ManuscriptsUploads;
