import React from 'react';
import ReactApexChart from 'react-apexcharts';
import styles from './ApexChart.module.css';

class ApexChart extends React.Component {
  constructor(props) {
    super(props);

    const { series, color, backgroundColor, textMame, fontSizeP, fontSizeLabel, offsetYP, isLabel } = props;

        this.state = {
            series: [series],
            options: {
                chart: {
                    type: 'radialBar',
                    // width: '50%',
                    offsetY: 0,
                    margin: 0,
                    padding: 0,
                    sparkline: {
                        enabled: true
                    }
                },
                plotOptions: {
                    radialBar: {
                        startAngle: -90,
                        endAngle: 90,
                        hollow: {
                            margin: 0,
                            size: '75%',
                        },
                        stroke: {
                            lineCap: 'round',
                        },
                        track: {
                            background: backgroundColor || '#B4FACD',
                            strokeWidth:  '65%',
                            margin: 0, // margin is in pixels
                        },
                        dataLabels: {
                            name: {
                                show: isLabel
                            },
                            value: {
                                show: true,
                                fontFamily: 'Zilla Slab',
                                fontWeight: 800,
                                fontSize: fontSizeP || '20px',
                                color: '#373d3f',
                                offsetY: offsetYP || -40,
                                formatter: function (val) {
                                    return val + '%'
                                }
                            },
                            
                            total: {
                                show: true,
                                label: textMame || `Match`,
                                color: '#373d3f',
                                fontSize: fontSizeLabel ||'17px',
                                fontFamily: 'Zilla Slab',
                                fontWeight: 700,
                                formatter: function (w) {
                                    return w.globals.seriesTotals.reduce((a, b) => {
                                        return a + b
                                    }, 0) / w.globals.series.length + '%'
                                }
                            }
                        }
                    }
                },
                grid: {
                    padding: {
                    }
                },
                fill: {
                    colors: [color || '#19E261'],
                    opacity: 1,
                    strokeWidth:'70%',
                    type: 'solid',
                    type: 'gradient',
                    gradient: {
                        shade: 'light',
                        shadeIntensity: 0.4,
                        inverseColors: false,
                        opacityFrom: 1,
                        opacityTo: 1,
                        stops: [0, 50, 53, 91]
                    },
                },
                //   labels: ['Average Results'],
            },
        };
    }

    render() {
        return (
            <div id="chart" className={styles.chartContainer}>
                <ReactApexChart options={this.state.options} series={this.state.series} type="radialBar" />
            </div>
        );
    }
}

export default ApexChart;
