
import styles from './Delete.module.css';
import trash from '../../../icons/vuesax/outline/trash.svg';
import xxx from '../../../icons/xxx.svg';
import { useEffect, useRef } from 'react';
import { useRecoilState } from "recoil";
import { pendingProcessingState, clickedManuscriptAtom, manuscriptInfoListAtom } from "../../../state";
import useApiUtils from '../../../useApiUtils';


const Delete = (props) => {
    const [clickedManuscript, setClickedManuscript] = useRecoilState(clickedManuscriptAtom);
    const [manuscriptInfoList, setManuscriptInfoList] = useRecoilState(manuscriptInfoListAtom);
    const [pendingProcessing, setPendingProcessing] = useRecoilState(pendingProcessingState);

     const {
        getManuscriptsInfo,
        initialgetBookChapter,
        get_book_scores,
        getbookgenrekeywords,
        Deletemanuscript,
        // Add other functions from the hook if needed
      } = useApiUtils()
    
//i want to detect click outside of the deletebox
    const deleteboxRef = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (deleteboxRef.current && !deleteboxRef.current.contains(event.target)) {
                props.handleDelete(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [deleteboxRef]);





    const handleOut = () => {
        props.handleDelete(false);
    };

    const handleDelete = () => {
        props.handleDelete(false);
        Deletemanuscript(clickedManuscript.book_id);
    };





    const handleCancel = () => {
        props.handleDelete(false);
    };



   
    return (
        <div className={styles.container}  >
            <div className={styles.deletebox} ref={deleteboxRef}>
                <div className={styles.boxsplit}>
                    <div className={styles.title}>
                        <div className={styles.titleText}>
                            Delete Manuscript?
                        </div>
                        <div className={styles.buttonOut} onClick={handleOut}>
                            <img className={styles.xbutton} src={xxx} />
                        </div>
                    </div>
                    <div className={styles.deleteboxContent}>
                        <div className={styles.editboxContentTextNimage}>
                            <div className={styles.imageUploadPart}>
                                <img className={styles.book_image}
                                    src={clickedManuscript.image}
                                />
                                <div className={styles.book_name}>
                                    {/* {props.manuscriptName} */}
                                    {clickedManuscript.manuscript_name}
                                </div>
                            </div>
                            <div className={styles.editboxContentTextP}>
                                <div className={styles.editboxContentTextTitle}>
                                    Are you sure you want to delete this manuscript?
                                </div>
                            </div>
                        </div>
                        <div className={styles.buttons} >
                            <div className={styles.cancelButtton} onClick={handleCancel}>
                                <div className={styles.cancelButttonText}>
                                    Cancel
                                </div>
                            </div>
                            <div className={styles.deleteButtton} onClick={handleDelete}>
                                <img className={styles.deleteButttonImage} src={trash} alt="delete" />
                                <div className={styles.deleteButttonText}>
                                    Delete
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}



export default Delete;
