import { useEffect, useState } from 'react';
import styles from './ComparePage.module.css';
import ComparisonItems from './comparisonItems/ComparisonItems';
import MatchGenresList from './machCompare/MatchGenresList';
import axios from 'axios';
import storyWiswLogo from '../../icons/storywise.svg';
import book from '../../icons/book.svg';
import { useNavigate } from 'react-router-dom';
import NavigationBar from '../../NavigationBar';
import Header from '../header/header';
import { useRecoilState } from 'recoil';
import useApiUtils from '../../useApiUtils';

import {
  genresState,
  keywordsState,
  manuscriptInfoListAtom,
  userInfoState,
  selectManuscriptByRatingState,
  clickedManuscriptAtom,
  currentManuscriptAtom,
} from '../../state';
import AboutBook from '../submission/popupBook/AboutBook';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';

const ComparePage = () => {
  const [userInfo, setUserInfo] = useRecoilState(userInfoState); // Update the path

  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate('/submissions');
  };
  const [clickedManuscript, setClickedManuscript] = useRecoilState(
    clickedManuscriptAtom
  );
  const [currentManuscript, setCurrentManuscript] = useRecoilState(
    currentManuscriptAtom
  );
  const [manuscriptInfoList, setManuscriptInfoList] = useRecoilState(
    manuscriptInfoListAtom
  );
  const [selectManuscriptByRating, setSelectManuscriptByRating] =
    useRecoilState(selectManuscriptByRatingState);
  const [showBookinfo, setShowBookInfo] = useState(false);

  const { bookId } = useParams();
  useEffect(() => {
    if (!localStorage.getItem('id_token')) {
      navigate('/');
    }
  }, []);

  const {
    getManuscriptsInfo,
    initialgetBookChapter,
    get_book_scores,
    getbookgenrekeywords,
    handleCompareApp,
    get_user_info,
    // Add other functions from the hook if needed
  } = useApiUtils();

  const [keywords, setKeywords] = useRecoilState(keywordsState);
  const [genres, setGenres] = useRecoilState(genresState);

  useEffect(() => {
    // or if keywords  []
    if (keywords.length === 0 && genres.length === 0) {
      if (
        bookId &&
        userInfo &&
        manuscriptInfoList &&
        manuscriptInfoList.length > 0
      ) {
        // select the first index of a book that is not have status Upload Failed
        let index = 0;
        while (manuscriptInfoList[index].book_id != bookId) {
          index++;
        }
        setClickedManuscript(manuscriptInfoList[index]);
        setCurrentManuscript({ bookId: bookId });
        getbookgenrekeywords(bookId);
        handleCompareApp(null, null, bookId, null);
      }
      //console.log(bookId);
    }
  }, [bookId, userInfo, manuscriptInfoList]);

  useEffect(() => {
    if (selectManuscriptByRating) {
      setShowBookInfo(true);
      setSelectManuscriptByRating(false);
    }
  }, [selectManuscriptByRating]);

  /////////show success message////////

  const [showSuccess, setShowSuccess] = useState(false);

  const showSuccessMessage = (message) => {
    setShowSuccess(true);
    setTimeout(() => {
      setShowSuccess(false);
    }, 5000);
  };

  return (
    <div className={styles.PageRoot}>
      <Header issubmission={false} issettings={false} />

      <div className={styles.comparePageTitle}>
        <Link to="/submissions" className={styles.linktoManuscripts}>
          My Manuscripts
        </Link>
        <div>
          {' '}
          {'>'} {clickedManuscript?.manuscript_name} {'>'} Compare
        </div>
      </div>

      {showSuccess && (
        <div
          className={`${styles.successMessage} ${styles.successMessageLeft}`}
        >
          <Stack sx={{ width: '50%' }} spacing={2}>
            <Alert severity="success">
              {' '}
              Your File Was downloaded Successfully!
            </Alert>
          </Stack>
        </div>
      )}
      {showBookinfo && (
        <AboutBook
          id_manuscript={clickedManuscript.book_id}
          return={setShowBookInfo}
          showSuccessMessage={showSuccessMessage}
        />
      )}

      <div className={styles.container}>
        <div className={styles.items}>
          <div className={styles.item}>
            <ComparisonItems />
          </div>
          <div className={styles.item}>
            <MatchGenresList />
          </div>
        </div>
      </div>
    </div>
  );
};
export default ComparePage;
