import styles from './Upload.module.css';
import handpoint from '../../icons/handpoint.svg';
import React, { useCallback, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  numberOfUploadedFilesRecentlyState,
  fileState,
  fileInfoState,
} from '../../state';
import useApiUtils from '../../useApiUtils';
import { useRecoilState } from 'recoil';
import { renderCenteredAlert } from '../CenteredAlert';

const baseStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '100px 10px 100px 10px',
  // gap: "10px",
  height: '100%',
  width: '97%',
  cursor: 'pointer',
  // background: "#F7F7F7",
  border: '1px dashed #71858B',
  borderRadius: '12px',
  // flex: "none",
  // order: 0,
  // alignSelf: "stretch",
  // flexGrow: 0,
};

const activeStyle = {
  background: '#F7F7F7',
};

// const acceptStyle = {
//   borderColor: '#00e676'
// };

const rejectStyle = {
  borderColor: '#ff1744',
};

function DropzoneComponent(props) {
  const [files, setFiles] = useRecoilState(fileState);
  const [fileInfo, setFileInfo] = useRecoilState(fileInfoState);
  const [numberOfUploadedFilesRecently, setNumberOfUploadedFilesRecently] =
    useRecoilState(numberOfUploadedFilesRecentlyState);

  const {
    getManuscriptsInfo,
    initialgetBookChapter,
    get_book_scores,
    getbookgenrekeywords,
    get_user_info,
    getCompanyInformation,
    setPreferanses,
    handleUploadFile,

    // Add other functions from the hook if needed
  } = useApiUtils();

  const onDropRejected = useCallback((rejectedFiles) => {
    rejectedFiles.forEach((file) => {
      let message = `File ${file.file.name} was rejected. Please upload valid files`;
      if (
        !file.accepted &&
        !file.errors.find((e) => e.code === 'file-too-large')
      ) {
        message += 'Invalid file type.';
      }
      if (file.errors.find((e) => e.code === 'file-too-large')) {
        message +=
          'File size exceeds the 10 MB limit. Please upload valid files';
      }
      renderCenteredAlert(message);
    });
  }, []);

  const onDrop = useCallback(
    async (acceptedFiles) => {
      console.log('inside the dropzone');
      // Store number of uploaded files
      const newNumberOfUploadedFiles = acceptedFiles.length;

      if (newNumberOfUploadedFiles > 20) {
        renderCenteredAlert(`You can only upload 20 files at a time.`);
        setFiles([]);
        return;
      }
      if (newNumberOfUploadedFiles == 0) {
        renderCenteredAlert(`Please upload a valid file.`);
        setFiles([]);
        return;
      }
      // Check if there are valid files
      if (acceptedFiles.length > 0) {
        props.setDuringUpload(true);
        setFiles(acceptedFiles);
        const prevLength = fileInfo.length;
        try {
          // Wait for all file uploads to complete
          await Promise.all(
            acceptedFiles.map((file, index) =>
              handleUploadFile(file, prevLength + index)
            )
          );
          setNumberOfUploadedFilesRecently(newNumberOfUploadedFiles);
          props.setEditChain(true);
          props.wasUploaded();
          props.setDuringUpload(false);
          // Optionally, call uploadmanuscript here if needed
        } catch (error) {
          renderCenteredAlert('Error in uploading one or more files.');
          props.setDuringUpload(false);
        }
      }
    },
    [setFiles]
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    // isDragAccept,
    // isDragReject
  } = useDropzone({
    onDrop,
    onDropRejected,
    accept: '.pdf, .epub, .txt , .docx, .doc, .rtf',
    maxSize: 10 * 1024 * 1024, // 10 MB
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      // ...(isDragAccept ? acceptStyle : {}),
      // ...(isDragReject ? rejectStyle : {})
      display: 'inline-flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }),
    [
      isDragActive,
      // isDragReject,
      // isDragAccept
    ]
  );
  return (
    <div {...getRootProps({ style })}>
      <input {...getInputProps()} />
      {/* <div className={styles.dragDropContent} style={{ padding: "0px", gap: "20px" }}> */}
      {/* <div className={styles.dragDropIcon}> */}
      <img src={handpoint} alt="non" style={{ width: '60px' }} />
      {/* </div> */}
      <div className={styles.dragDropText}>Drag and drop your files here</div>
      {/* </div> */}
    </div>
  );
}

export default DropzoneComponent;
