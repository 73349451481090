import styles from './Filter.module.css';

const Filter = (props) => {

    return (
        <div className={styles.backdrop}  >
            <div className={styles.container}>
                <div className={styles.filter}>
                    <div className={styles.filterContainer}>
                        <div className={styles.filterTitle}>
                            Filter
                        </div>
                        <div className={styles.filterButton}>
                            <div className={styles.clearAll}>
                                <div className={styles.clearAllText}>
                                    Clear All
                                </div>
                            </div>
                            <div className={styles.save}>
                                <div className={styles.saveText}>
                                    Save
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.filterContent}>
                        <div className={styles.editboxContentTextTitle}>
                            <label className={styles.lable} for="Author"> <div className={styles.labelText} > Author</div></label>
                            <input className={styles.input} type="text" id="Author" name="Author" placeholder="Author" />
                        </div>
                        <div className={styles.editboxContentTextTitle}>
                            <label className={styles.lable} for="Genre"> <div className={styles.labelText} > Genre </div></label>
                            <input className={styles.input} type="text" id="Genre" name="Genre" placeholder="Genre" />
                        </div>
                        <div className={styles.editboxContentTextTitle}>
                            <label className={styles.lable} for="Word"> <div className={styles.labelText} > Word </div></label>
                            <input className={styles.input} type="text" id="Word" name="Word" placeholder="Word" />
                        </div>
                        <div className={styles.editboxContentTextTitle}>
                            <label className={styles.lable} for="Recommendation"> <div className={styles.labelText} > Recommendation </div></label>
                            <input className={styles.input} type="text" id="Recommendation" name="Recommendation" placeholder="Recommendation" />
                        </div>
                        <div className={styles.editboxContentTextTitle}>
                            <label className={styles.lable} for="Owner"> <div className={styles.labelText} > Owner </div></label>
                            <input className={styles.input} type="text" id="Owner" name="Owner" placeholder="Owner" />
                        </div>
                        <div className={styles.editboxContentTextTitle}>
                            <label className={styles.lable} for="Status"> <div className={styles.labelText} > Status </div></label>
                            <input className={styles.input} type="text" id="Status" name="Status" placeholder="Status" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Filter;