import styles from './CancelEmail.module.css';
import xxx from '../../icons/xxx.svg';
import trash from '../../icons/vuesax/outline/trash.svg';
import Checkbox from '@mui/material/Checkbox';
import React, { useState } from 'react';

import CircularProgress from '@mui/material/CircularProgress';

import { useEffect, useRef } from 'react';
import { useRecoilState } from "recoil";
import { companyInfoState, userInfoState, numberOfUploadsState, uploadMethodOptionState, manuscriptsUploadsState, uploadMethodcheckedState, uploadMethodProviderState, contentAtom, } from "../../state";
import useApiUtils from '../../useApiUtils';


const CancelEmail = (props) => {
    const [options, setOptions] = useRecoilState(uploadMethodOptionState);
    const [userInfo, setUserInfo] = useRecoilState(userInfoState); // Update the path
    const [companyInfo, setCompanyInfo] = useRecoilState(companyInfoState);
    const [saveButtonState, setSaveButtonState] = useState('normal'); // 'normal', 'saving', 'success', 'failure'

    const [errorMessage, setErrorMessage] = React.useState('');

    const {
        getManuscriptsInfo,
        initialgetBookChapter,
        get_book_scores,
        getbookgenrekeywords,
        get_user_info,
        getCompanyInformation,
        uploadMethod,

        // Add other functions from the hook if needed
    } = useApiUtils()

    //i want to detect click outside of the deletebox
    const deleteboxRef = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (deleteboxRef.current && !deleteboxRef.current.contains(event.target)) {
                props.setShowCancelEmail(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [deleteboxRef]);

    const handleCancel = () => {
        props.setShowCancelEmail(false);
    }


    const getButtonText = () => {
        switch (saveButtonState) {
            case 'saving':
                return 'Saving...';
            case 'success':
                return 'Saved Successfully';
            case 'failure':
                return 'Failed to Save';
            case 'loading':
                return <CircularProgress size={15} color="inherit" />;
            default:
                return 'Disconnect email';
        }
    };
    const handleCancleEmail = async () => {
        if (saveButtonState === 'loading') {
            return;
        }
        setSaveButtonState('loading');
        const upload_preferences = {
            uploads_via_manual: options.find(option => option.value === 'Manual uploads').selected,
            uploads_via_integrated_email: false,
            uploads_via_dedicated_email: false,
            integrate_email: {
                provider: null,
                code: null,
                type: null,

            }
        }
        const payload = {
            user_id: userInfo.user_id,
            upload_preferences: upload_preferences,
            ingest_connectors: {}
        };
        try {
            await uploadMethod(payload);
            props.setShowCancelEmail(false);
            setSaveButtonState('normal');
        } catch (error) {
        }
        props.setShowCancelEmail(false);
    }


    return (
        <div className={styles.container}  >
            <div className={styles.deletebox} ref={deleteboxRef}>
                <div className={styles.boxsplit}>
                    <div className={styles.title}>
                        <div className={styles.titleText}>
                            {/*  props cancel integration or cancel yout dedicated email  */}
                            Cancel Email?
                        </div>
                        <div className={styles.buttonOut} onClick={handleCancel}>
                            <img className={styles.xbutton} src={xxx} />
                        </div>
                    </div>
                    <div className={styles.deleteboxContent}>
                        <div className={styles.editboxContentTextNimage}>
                            <div className={styles.imageUploadPart}>
                                {/* <img className={styles.book_image}
                                    src={trash}
                                /> */}
                                <div className={styles.editboxContentTextTitle}>
                                    Are you sure you want to disconnect this email connection?                                </div>
                            </div>
                            <div className={styles.editboxContentTextP}>
                                <div className={styles.book_name}>
                                    Your dashboard will stop receiving manuscripts automatically from this email account.                                </div>
                            </div>
                        </div>
                        <div className={styles.buttons} >
                            <div className={styles.cancelButtton} onClick={handleCancel}>
                                <div className={styles.cancelButttonText}>
                                    Cancel
                                </div>
                            </div>
                            <div className={styles.deleteButtton} onClick={handleCancleEmail}>
                                <img className={styles.deleteButttonImage} src={trash} alt="delete" />
                                <div className={`${styles.deleteButttonText}${saveButtonState === 'success' ? styles.successButton : saveButtonState === 'failure' ? styles.failureButton : ''}`}>
                                    {getButtonText()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default CancelEmail;