import styles from './BookCard.module.css';
import ApexChart from '../../../UI/ApexChart';
import { useRecoilState } from 'recoil';
import { isNewVersionSimilarBooksState } from '../../../state';
import React, { useState, useEffect } from 'react';

function openUrlInNewTab(url) {
  window.open(url, '_blank');
}

const BookCard = (props) => {
  const [nelsonSimilaBooks, setNelsonSimilaBooks] = useRecoilState(
    isNewVersionSimilarBooksState
  );
  const [similarityPercentage, setSimilarityPercentage] = useState(null);

  useEffect(() => {
    if (props.match_score_normalized) {
      var percentage = props.match_score_normalized;
      if (percentage <= 1) {
        // percentage <= 1 is the old format
        percentage *= 100;
      }
      const integerPercentage = Math.round(percentage);
      //console.log('Parsed Similarity Percentage:', integerPercentage);
      setSimilarityPercentage(integerPercentage);
    }
  }, [props.match_score_normalized]);

  let strokeColor = '#19E261';
  let backgroundColor = '#B4FACD';

  if (props.precent < 50) {
    strokeColor = '#F91B1B';
    backgroundColor = '#FEB6B1';
  }

  const formatDate = (dateString) => {
    const year = dateString.slice(0, 4);
    const month = dateString.slice(4, 6);
    const day = dateString.slice(6, 8);

    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    const monthIndex = parseInt(month, 10) - 1; // Convert "08" to 7 for August
    const monthName = monthNames[monthIndex];

    const shortYear = year.slice(2, 4);

    const formattedDay = parseInt(day, 10);

    return `${formattedDay}-${monthName}-${shortYear}`;
  };

  const handleError = (e) => {
    e.target.style.visibility = 'hidden';
  };

  if (nelsonSimilaBooks) {
    return (
      <div
        className={styles.cardContainer}
        onClick={() => {
          openUrlInNewTab(props.buy_url);
        }}
      >
        <img
          className={styles.cardImage}
          src={props.image_url ? props.image_url : 'default_image_url'}
          onError={handleError}
          alt="Book Cover"
        />

        <div className={styles.cardInfo}>
          <div className={styles.cardTitle}>
            <div className={styles.BookName}>{props.title}</div>
            <div className={styles.BookAuthor}>{props.author_name}</div>
          </div>
          <div className={styles.isbn}>
            {' '}
            ISBN: <div className={styles.isbntext}>{props.isbn} , </div>
            Published:{' '}
            <div className={styles.isbntext}>
              {formatDate(props.publication_date)}
            </div>
          </div>
          <div className={styles.isbn}>
            Publisher: <div className={styles.isbntext}> {props.publisher}</div>
          </div>
          {props.subject_theme && (
            <div className={styles.bookGenres}>
              {props.subject_theme.split(', ').map((genre, index) => (
                <div className={styles.genre} key={index}>
                  <div className={styles.genreName}>{genre}</div>
                </div>
              ))}
            </div>
          )}
        </div>
        {/* {similarityPercentage !== null && (
          <div className={styles.machPrecent}>
            <ApexChart
              series={[similarityPercentage]}
              color={strokeColor}
              backgroundColor={backgroundColor}
              textMame="Match"
              fontSizeP="15px"
              fontSizeLabel="12px"
              offsetYP={-30}
              isLabel={true}
            />
          </div>
        )} */}
      </div>
    );
  }

  return (
    <div
      className={styles.cardContainer}
      onClick={() => {
        openUrlInNewTab(props.buy);
      }}
    >
      <img
        className={styles.cardImage}
        src={`https://${props.image}`}
        onError={handleError}
        alt="Book Cover"
      />

      <div className={styles.cardInfo}>
        <div className={styles.cardTitle}>
          <div className={styles.BookName}>{props.book_name}</div>
          <div className={styles.BookAuthor}>{props.author}</div>
        </div>
        <div className={styles.isbn}> ISBN: {props.isbn}</div>
        <div className={styles.bookGenres}>
          {props.genres.map((genre, index) =>
            genre ? (
              <div className={styles.genre} key={index}>
                <div className={styles.genreName}>{genre}</div>
              </div>
            ) : null
          )}
        </div>
      </div>
      {/* <div className={styles.machPrecent}>
        <ApexChart
          series={props.match}
          color={strokeColor}
          backgroundColor={backgroundColor}
          textMame="Match"
          fontSizeP="15px"
          fontSizeLabel="12px"
          offsetYP={-30}
          isLabel={true}
        />
      </div> */}
    </div>
  );
};

export default BookCard;
