import styles from './ApproveManuscript.module.css';
import { useState, useEffect, useRef } from 'react';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Select from 'react-select';
import {
  emailToApproveState,
  clickedManuscriptAtom,
  currentManuscriptAtom,
  notesAtom,
  userInfoState,
} from '../../../state';
import { useRecoilState } from 'recoil';
import useApiUtils from '../../../useApiUtils';
import AvatarCostom from '../../../UI/AvatarCostom';
import CircularProgress from '@mui/material/CircularProgress';
import { renderCenteredAlert } from '../../CenteredAlert';

const ApproveManuscript = (props) => {
  const [notes, setNotes] = useRecoilState(notesAtom);
  const [clickedManuscript, setClickedManuscript] = useRecoilState(
    clickedManuscriptAtom
  );
  const [emailToApprove, setEmailToApprove] =
    useRecoilState(emailToApproveState);
  const [userInfo, setUserInfo] = useRecoilState(userInfoState); // Update the path

  const [selectedOption, setSelectedOption] = useState(null);
  const [options, setOptions] = useState([]);
  const [currentManuscript, setCurrentManuscript] = useRecoilState(
    currentManuscriptAtom
  );
  const [email, setEmail] = useState('');

  const [next_user_option, setNext_user_option] = useState([]);
  const [saveButtonState, setSaveButtonState] = useState('normal'); // 'normal', 'saving', 'success', 'failure'

  const {
    getManuscriptsInfo,
    initialgetBookChapter,
    get_book_scores,
    getbookgenrekeywords,
    sendRejection,
    sendApprove,
    // Add other functions from the hook if needed
  } = useApiUtils();

  //setNext_user_option from  userInfo.next_users list
  useEffect(() => {
    if (userInfo && userInfo.next_users.length > 0) {
      let next_user_option_temp = [];
      userInfo.next_users.forEach((user) => {
        next_user_option_temp.push({
          value: user.user_id,
          label: user.name,
          avatarSrc: 'https://www.w3schools.com/howto/img_avatar.png',
        });
      });
      setNext_user_option(next_user_option_temp);
    }
  }, [userInfo]);

  const optionsMock = [
    {
      value: '1',
      label: 'User 1',
      avatarSrc: 'https://www.w3schools.com/howto/img_avatar.png',
    },
    {
      value: '2',
      label: 'User 2',
      avatarSrc: 'https://www.w3schools.com/howto/img_avatar.png',
    },
    // Add more users as needed
  ];

  //i want to detect click outside of the deletebox
  const approveboxRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        approveboxRef.current &&
        !approveboxRef.current.contains(event.target)
      ) {
        props.return(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [approveboxRef]);

  const handelbutton = () => {
    props.return(false);
  };

  const handelbuttonApproved = async () => {
    if (userInfo.role === 'lvl1') {
      if (selectedOption == null && email == '') {
        renderCenteredAlert('Please select a user to send the manuscript to');
        props.setUserApproved(false);
        return;
      }
    }
    if (saveButtonState === 'loading') {
      return;
    }
    setSaveButtonState('loading');

    let emailToSendMethod;
    let emailToSend;
    if (selectedOption != null) {
      emailToSend = selectedOption.value;
      emailToSendMethod = 'user';
    } else if (email != '') {
      emailToSend = email;
      emailToSendMethod = 'email';
    } else {
      emailToSend = email;
      emailToSendMethod = 'email';
    }
    //set emailToApprove with emailToSend , emailToSendMethod
    // setEmailToApprove();
    await sendApprove(clickedManuscript.book_id, {
      email: emailToSend,
      method: emailToSendMethod,
    });
    setSaveButtonState('normal');

    props.return(false);
    // props.setUserApproved(true);
  };

  const getButtonText = () => {
    switch (saveButtonState) {
      case 'saving':
        return 'Saving...';
      case 'success':
        return 'Saved Successfully';
      case 'failure':
        return 'Failed to Save';
      case 'loading':
        return <CircularProgress size={15} color="inherit" />;
      default:
        return 'Yes, Approve';
    }
  };

  const handleEmailChange = (event) => {
    //if selectedOption is not null then alert that you can select only one of the option and clear both
    if (selectedOption != null) {
      renderCenteredAlert(
        'You can select only one of the options, via email or select user'
      );
      setEmail('');
      setSelectedOption(null);
      return;
    }
    setEmail(event.target.value);
  };

  useEffect(() => {
    setOptions(next_user_option);
  }, [next_user_option]);

  const handleOptionChange = (selected) => {
    //if email is not empty then alert that you can select only one of the option and clear both
    if (email != '') {
      renderCenteredAlert(
        'You can select only one of the options, via email or select user'
      );
      setEmail('');
      setSelectedOption(null);
      return;
    }
    setSelectedOption(selected);
  };

  const CustomSingleValue = ({ innerProps, label, data }) => (
    <div {...innerProps} className={styles.customOption}>
      <Stack direction="row" spacing={2} alignItems="center">
        {/* <Avatar alt={data.label} src={data.avatarSrc} /> */}
        <AvatarCostom name={data.label} />

        <span className={styles.optionLabel}>{data.label}</span>
      </Stack>
    </div>
  );
  const CustomOptionComponent = ({ innerProps, label, data }) => (
    <div {...innerProps} className={styles.customOption}>
      <Stack direction="row" spacing={2} alignItems="center">
        {/* <Avatar alt={data.label} src={data.avatarSrc} /> */}
        <AvatarCostom name={data.label} />
        <span className={styles.optionLabel}>{data.label}</span>
      </Stack>
    </div>
  );

  const NoInput = () => null;

  const customStyles = {
    container: (provided) => ({
      ...provided,
      padding: 0,
      marginRight: 0,
      width: '100%',
    }),
    control: (provided) => ({
      ...provided,
      borderRadius: '16px', // Add border-radius attribute
      border: '1px solid var(--sass-gray-100, #C4CAD4)', // Add border attribute
      background: 'var(--sass-neutral-white, #FFF)', // Add background attribute
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: 0,
      gap: 0,
      backgroundColor: '#f6f7fa',
      width: '100%',
    }),
    option: (
      styles,
      { data, isDisabled, isFocused, isSelected, isHovered }
    ) => ({
      ...styles,
    }),
  };

  return (
    <div className={styles.backdrop}>
      <div className={styles.container} ref={approveboxRef}>
        <div className={styles.TopSection}>
          <div className={styles.titleTEXT}>Approve Manuscript?</div>
          <div className={styles.buttonOut}>
            <button className={styles.xbutton} onClick={handelbutton}>
              X
            </button>
          </div>
        </div>
        <div className={styles.MiddleSectionall}>
          <div className={styles.MiddleSection}>
            <div className={styles.ImageContainer}>
              <img
                className={styles.Image}
                src={clickedManuscript.image}
                alt=""
              />
            </div>
            <div className={styles.title}>
              {clickedManuscript.manuscript_name}
            </div>
            <div className={styles.outherName}>
              By{' '}
              <div className={styles.outher}>
                {clickedManuscript.author_name}
              </div>
            </div>
          </div>
          <div className={styles.Confirmationsection}>
            <div className={styles.ConfirmationTitle}>
              Are You Sure You Want to Approve This Manuscript?
            </div>
            {userInfo?.role === 'lvl1' && (
              <div className={styles.ConfirmationSubtitle}>
                If you approve this manuscript, a notification will be sent to
                the following user:
              </div>
            )}
            {userInfo?.role === 'lvl2' && (
              <div className={styles.ConfirmationSubtitle1}>
                If you approve this manuscript, you will complete the review
                process.
              </div>
            )}
          </div>
          {userInfo?.role === 'lvl1' && (
            <div className={styles.emailpart}>
              <div className={styles.dropdownmail}>
                <label className={styles.dropdownLabel}>Select User</label>

                {/* {options && options.length > 0 && ( */}
                <Select
                  value={selectedOption}
                  onChange={handleOptionChange}
                  options={options}
                  styles={customStyles}
                  components={{
                    Option: CustomOptionComponent,
                    SingleValue: CustomSingleValue,
                    Input: NoInput, // Override default input rendering
                  }}
                />
                {/* )} */}
              </div>
              <div className={styles.orLine}>
                <div className={styles.Line} />
                <div className={styles.orLineText}>OR</div>
                <div className={styles.Line} />
              </div>
              <div className={styles.enteremailto}>
                <label className={styles.emailText}>Enter Email</label>
                <div className={styles.emailInputContainer}>
                  <input
                    type="text"
                    className={styles.emailInput}
                    placeholder="Enter email    @"
                    value={email}
                    onChange={handleEmailChange}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <div className={styles.ButtonSection}>
          <div className={styles.YesButtonContainer} onClick={handelbutton}>
            <div className={styles.noButton}>No, Don’t Approve </div>
          </div>
          <div
            className={`${styles.NoButtonContainer}${
              saveButtonState === 'success'
                ? styles.successButton
                : saveButtonState === 'failure'
                ? styles.failureButton
                : ''
            }`}
            onClick={handelbuttonApproved}
          >
            <div className={styles.yesButton}>{getButtonText()}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApproveManuscript;
