//new version of static compare
import React, { useState } from "react";
import CoosenCard from "./CoosenCard";
import styles from './Keywords.module.css';
import { useRecoilState, useRecoilValue } from 'recoil';
import { keywordsState } from '../../../state';



const Keywords = (props) => {
  const [newKeyword, setNewKeyword] = useState("");
  const [keywords, setKeywords] = useRecoilState(keywordsState);


  const handleRemoveKeyword = keyword => {
    setKeywords(prevKeywords => {
      return prevKeywords.filter(item => item !== keyword);
    });
  };
  const handleInputChange = (e) => {
    setNewKeyword(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleAddKeyword();
    }
  };

  
  const handleAddKeyword = () => {
    if (newKeyword.trim() !== '') {
      setKeywords(prevKeywords => {
        return [newKeyword, ...prevKeywords];
      });
      setNewKeyword('');
    }
  };


  return (
    <div className={styles.keywordsContainer}>
      <div className={styles.constPart}>
        <div className={styles.title}>
          <div className={styles.titleText}>
            Keywords ({keywords.length})</div>
        </div>
        {/* <input
          type="text"
          value={newKeyword}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          placeholder="+Add New "
        /> */}
      </div>
      <div className={styles.keywords}>
        {keywords.map((keyword, index) => (
          <div className={styles.keyword}>
            <CoosenCard
              key={index}
              genre={keyword}
              removeGenre={handleRemoveKeyword}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Keywords;





//old version of dynamic compare
// import React, { useState } from "react";
// import CoosenCard from "./CoosenCard";
// import styles from './Keywords.module.css';
// import { useRecoilState, useRecoilValue } from 'recoil';
// import { keywordsState } from '../../../state';



// const Keywords = (props) => {
//   const [newKeyword, setNewKeyword] = useState("");
//   const [keywords, setKeywords] = useRecoilState(keywordsState);


//   const handleRemoveKeyword = keyword => {
//     setKeywords(prevKeywords => {
//       return prevKeywords.filter(item => item !== keyword);
//     });
//   };
//   const handleInputChange = (e) => {
//     setNewKeyword(e.target.value);
//   };

//   const handleKeyDown = (e) => {
//     if (e.key === 'Enter') {
//       handleAddKeyword();
//     }
//   };

  
//   const handleAddKeyword = () => {
//     if (newKeyword.trim() !== '') {
//       setKeywords(prevKeywords => {
//         return [newKeyword, ...prevKeywords];
//       });
//       setNewKeyword('');
//     }
//   };


//   return (
//     <div className={styles.keywordsContainer}>
//       <div className={styles.constPart}>
//         <div className={styles.title}>
//           <div className={styles.titleText}>
//             Keywords ({keywords.length})</div>
//         </div>
//         <input
//           type="text"
//           value={newKeyword}
//           onChange={handleInputChange}
//           onKeyDown={handleKeyDown}
//           placeholder="+Add New "
//         />
//       </div>
//       <div className={styles.keywords}>
//         {keywords.map((keyword, index) => (
//           <div className={styles.keyword}>
//             <CoosenCard
//               key={index}
//               genre={keyword}
//               removeGenre={handleRemoveKeyword}
//             />
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default Keywords;
