import styles from './MailBox_Conecter.module.css';
import React, { useState } from 'react';
import { useEffect, useRef } from 'react';
import xxx from '../../icons/xxx.svg';
import trash from '../../icons/vuesax/outline/trash.svg';
import CircularProgress from '@mui/material/CircularProgress';

import gmail from '../../icons/gmail.svg';
import gmailHover from '../../icons/google_signin_buttons 2/web/1x/btn_google_signin_light_focus_web.png';
import gmailPreesed from '../../icons/google_signin_buttons 2/web/1x/btn_google_signin_light_pressed_web.png';
import outlook from '../../icons/outlook.svg';
import { useNavigate } from 'react-router-dom';
import useApiUtils from '../../useApiUtils';
import { useRecoilState } from 'recoil';
import {
  companyInfoState,
  userInfoState,
  numberOfUploadsState,
  uploadMethodOptionState,
  manuscriptsUploadsState,
  uploadMethodcheckedState,
  uploadMethodProviderState,
  contentAtom,
} from '../../state';
import { renderCenteredAlert, renderCenteredInfo } from '../CenteredAlert';

const MailBox_Conecter = (props) => {
  const [companyInfo, setCompanyInfo] = useState(null);
  const [goodIntegration, setGoodIntegration] = useState(false);

  const {
    getManuscriptsInfo,
    initialgetBookChapter,
    get_book_scores,
    getbookgenrekeywords,
    get_user_info,
    getCompanyInformation,
    uploadMethod,

    // Add other functions from the hook if needed
  } = useApiUtils();
  const navigate = useNavigate();

  const setupPollingAndTimeout = () => {
    let timeoutId;
    const intervalId = setInterval(async () => {
      const user_id = await get_user_info(true, true);
      const info = await getCompanyInformation({ user_id: user_id }); // Assuming getCompanyInformation expects an object
      const connectors = info?.upload_method_settings?.ingest_connectors;
      if (connectors && Object.keys(connectors).length > 0) {
        const connectorsKeys = Object.keys(connectors);
        // Assuming you're trying to access the first key to get to the first connector object
        const firstConnectorKey = connectorsKeys[0];
        const firstConnector = connectors[firstConnectorKey];
        // Access the provider of the first connector
        const firstConnectorProvider = firstConnector?.provider;
        if (firstConnectorProvider) {
          clearInterval(intervalId);
          clearTimeout(timeoutId);
          setGoodIntegration(true);
          props.setMailBox_Conecter_window(false);
          navigate('/user-profile');
        }
      }
    }, 3000); // Poll every 3 seconds as per your comment

    timeoutId = setTimeout(() => {
      clearInterval(intervalId);
      if (!goodIntegration) {
        renderCenteredAlert(
          'An error occurred during the time of the integration.'
        );
        props.setMailBox_Conecter_window(false);
        navigate('/user-profile');
      }
    }, 50000); // Timeout after 50 seconds
  };

  useEffect(() => {
    handleNewIntegration();
    setupPollingAndTimeout();
  }, []);

  //i want to detect click outside of the deletebox
  const deleteboxRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        deleteboxRef.current &&
        !deleteboxRef.current.contains(event.target)
      ) {
        handleCancel();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [deleteboxRef]);

  const handleCancel = () => {
    //  alert that if user close this window, the process will be continued in the background and he can refresh the page to see the results
    renderCenteredInfo(
      'if you close this window, the process will be continued in the background and you can refresh the page to see the results'
    );
    navigate('/user-profile');
    props.setMailBox_Conecter_window(false);
    navigate('/user-profile');
  };

  const handleNewIntegration = async () => {
    //    max_lookback, manualUploads, selectedProvider, code == props.integrationInfo.
    const max_lookback = props.integrationInfo.max_lookback;
    const manualUploads = props.integrationInfo.manualUploads;
    const selectedProvider = props.integrationInfo.selectedProvider;
    const code = props.integrationInfo.code;

    let provider = selectedProvider;
    if (selectedProvider === 'outlook') {
      provider = 'microsoft';
    }

    const upload_preferences = {
      uploads_via_manual: manualUploads,
      uploads_via_integrated_email: true,
      uploads_via_dedicated_email: false,
      integrate_email: {
        provider: provider,
        code: code,
        type: 'external_email',
        status: 'pending',
        max_lookback_days: max_lookback,
        number_manuscripts_to_upload: null,
      },
    };

    const user_id = await get_user_info(true, true);
    // setSaveButtonState('loading');
    const payload = {
      user_id: user_id,
      upload_preferences: upload_preferences,
      ingest_connectors: {},
    };
    try {
      const response = await uploadMethod(payload);
      if (!response) {
        props.setMailBox_Conecter_window(false);
        navigate('/user-profile');
        return;
      }

      navigate('/user-profile');

      // setSaveButtonState('normal');
    } catch (error) {
      // setSaveButtonState('normal');
      navigate('/user-profile');
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.deletebox} ref={deleteboxRef}>
        <div className={styles.boxsplit}>
          <div className={styles.title}>
            <div className={styles.titleText}>Mailbox Connector</div>
            <div className={styles.buttonOut} onClick={handleCancel}>
              <img className={styles.xbutton} src={xxx} />
            </div>
          </div>
          <div className={styles.deleteboxContent}>
            <div className={styles.editboxContentTextNimage}>
              <div className={styles.imageUploadPart}>
                {props.integrationInfo.selectedProvider === 'gmail' ? (
                  <img className={styles.book_image} src={gmail} alt="book" />
                ) : (
                  <img
                    className={styles.outlook_image}
                    src={outlook}
                    alt="book"
                  />
                )}
                <div className={styles.book_name}>
                  <CircularProgress size={15} color="inherit" />{' '}
                  {props.integrationInfo.selectedProvider === 'gmail'
                    ? 'We are connecting to your Gmail inbox'
                    : 'We are connecting to your Outlook inbox'}
                </div>
              </div>
              <div className={styles.editboxContentTextNimage}>
                We will search your inbox for manuscripts sent in the previous{' '}
                {props.number_of_days} days...
              </div>
            </div>

            <div className={styles.buttons} onClick={handleCancel}>
              <div className={styles.deleteButtton} onClick={handleCancel}>
                {/* <img className={styles.deleteButttonImage} src={trash} alt="delete" /> */}
                <div className={styles.deleteButttonText}>Close</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MailBox_Conecter;
