import styles from './Preferences.module.css';
import React, { useState, useEffect } from 'react';

import GenreCard from '../home/GenreCard';
import CategoriesCard from '../home/CategoriesCard';
import tooltipIcon from '../../icons/icons8-info (1).svg';

import { useRecoilState } from 'recoil';
import { companyInfoState, userInfoState } from '../../state';

import { pink } from '@mui/material/colors';
import Checkbox from '@mui/material/Checkbox';
import expend_score from '../../icons/expend_score copy 2.svg';
import despend from '../../icons/despend copy 2.svg';
import Thriller from '../../icons/genreImage/MysteryThriller.svg';
import Mystery from '../../icons/genreImage/MysteryThriller.svg';
import HistoricalFiction from '../../icons/genreImage/HistoricalFiction.svg';
import Romance from '../../icons/genreImage/Romance.svg';
import Horror from '../../icons/genreImage/Horror.svg';
import Fantasy from '../../icons/genreImage/ScifiFantasy.svg';
import SciFi from '../../icons/genreImage/genre/genre/architype/Rebirth.svg';
import Memoir from '../../icons/genreImage/Memoir.svg';
import YA from '../../icons/genreImage/genre/ya.svg';
import LiteraryFiction from '../../icons/genreImage/LiteraryFiction.svg';
import defaultbook from '../../icons/genreImage/defaultbook.svg';
import CircularProgress from '@mui/material/CircularProgress';

import useApiUtils from '../../useApiUtils';

import { useNavigate } from 'react-router-dom';
import { renderCenteredAlert } from '../../components/CenteredAlert';

import Business from '../../icons/genreImage/genre/Business.svg';
import Educational from '../../icons/genreImage/genre/Educational.svg';
import fantasy from '../../icons/genreImage/genre/fantasy.svg';
import GamesGaming from '../../icons/genreImage/genre/image 15.svg';
import historic from '../../icons/genreImage/genre/historic.svg';
import History from '../../icons/genreImage/genre/architype/Rags to Riches.svg';
import HomeLifestyle from '../../icons/genreImage/genre/Home & Lifestyle.svg';
import horror from '../../icons/genreImage/genre/horror.svg';
import Memoir1 from '../../icons/genreImage/genre/genre/genre/genre/fiction.svg';
import NatureClimate from '../../icons/genreImage/genre/Nature & Climate.svg';
import Paranormalromance from '../../icons/genreImage/genre/genre/adult.svg';
import Parenting from '../../icons/genreImage/genre/Parenting.svg';
import PeriodRomance from '../../icons/genreImage/genre/Period Romance.svg';
import PopularScience from '../../icons/genreImage/genre/Popular Science.svg';
import Religion from '../../icons/genreImage/genre/Religion.svg';
import romance from '../../icons/genreImage/genre/romance.svg';
import Selfhelp from '../../icons/genreImage/genre/Self help.svg';
import SportsHobbies from '../../icons/genreImage/genre/Sport & Hobbies.svg';
import Travel from '../../icons/genreImage/genre/Travel.svg';
import mysteryCrime from '../../icons/genreImage/Mystery:Crime.svg';
import ContemporaryRomance from '../../icons/genreImage/genre/genre/romance.svg';
import Academic from '../../icons/genreImage/genre/Academic.svg';
import generalfiction from '../../icons/genreImage/genre/genre/architype/Comedy.svg';
import generalnonfiction from '../../icons/genreImage/genre/genre/architype/Tragedy.svg';
import Collectibles from '../../icons/genreImage/genre/genre/Collectibles.svg';

const fictionGenreList = [
  {
    genre: 'Mystery/Crime',
    genre_image: mysteryCrime,
    selected: false,
  },
  {
    genre: 'Thriller',
    genre_image: Thriller,
    selected: false,
  },
  {
    genre: 'Historical Fiction',
    genre_image: HistoricalFiction,
    selected: false,
  },
  {
    genre: 'Contemporary Romance',
    genre_image: ContemporaryRomance,
    selected: false,
  },
  {
    genre: 'Paranormal Romance',
    genre_image: Paranormalromance,
    selected: false,
  },
  {
    genre: 'Period Romance',
    genre_image: PeriodRomance,
    selected: false,
  },
  {
    genre: 'Horror',
    genre_image: Horror,
    selected: false,
  },
  {
    genre: 'Sci-Fi',
    genre_image: SciFi,
    selected: false,
  },
  {
    genre: 'Fantasy',
    genre_image: Fantasy,
    selected: false,
  },
  {
    genre: 'YA',
    genre_image: YA,
    selected: false,
  },
  {
    genre: 'General Fiction',
    genre_image: generalfiction,
    selected: false,
  },
];

// create a list of non-fiction genres
const nonFictionGenreList = [
  {
    genre: 'Memoir',
    selected: false,
    genre_image: Memoir1,
  },
  {
    genre: 'Academic',
    selected: false,
    genre_image: Academic,
  },
  {
    genre: 'Collectables',
    selected: false,
    genre_image: Collectibles,
  },
  {
    genre: 'Sports & hobbies',
    selected: false,
    genre_image: SportsHobbies,
  },
  {
    genre: 'Self Help',
    selected: false,
    genre_image: Selfhelp,
  },
  {
    genre: 'Games',
    selected: false,
    genre_image: GamesGaming,
  },
  {
    genre: 'Religion',
    selected: false,
    genre_image: Religion,
  },
  {
    genre: 'Home & Lifestyle',
    selected: false,
    genre_image: HomeLifestyle,
  },
  {
    genre: 'Travel',
    selected: false,
    genre_image: Travel,
  },
  {
    genre: 'Popular Science',
    selected: false,
    genre_image: PopularScience,
  },
  {
    genre: 'Parenting',
    selected: false,
    genre_image: Parenting,
  },
  {
    genre: 'Educational',
    selected: false,
    genre_image: Educational,
  },
  {
    genre: 'History',
    selected: false,
    genre_image: History,
  },
  {
    genre: 'Business',
    selected: false,
    genre_image: Business,
  },
  {
    genre: 'Nature & Climate',
    selected: false,
    genre_image: NatureClimate,
  },
  {
    genre: 'General non-fiction',
    selected: false,
    genre_image: generalnonfiction,
  },
];

const Preferences = (props) => {
  const [userInfo, setUserInfo] = useRecoilState(userInfoState); // Update the path
  const [companyInfo, setCompanyInfo] = useRecoilState(companyInfoState);
  const [saveButtonState, setSaveButtonState] = useState('normal'); // 'normal', 'saving', 'success', 'failure'

  const [selectedFictionGenres, setSelectedFictionGenres] =
    useState(fictionGenreList);
  const [selectedNonFictionGenres, setSelectedNonFictionGenres] =
    useState(nonFictionGenreList);

  const [relevantPreferences, setRelevantPreferences] = useState(false);
  const [promisingPreferences, setPromisingPreferences] = useState(false);
  const [eligiblePreferences, setEligiblePreferences] = useState(false);

  const [expandState, setExpandState] = useState({
    Eligible: false,
    Relevant: false,
    Promising: false,
  });

  // Toggle expand/collapse
  const toggleExpand = (category) => {
    setExpandState((prevState) => ({
      ...prevState,
      [category]: !prevState[category],
    }));
  };

  const [selectedEligible, setSelectedEligible] = useState(true);
  const [selectedEligibleList, setSelectedEligibleList] = useState([
    { value: 'Genre', selected: true },
    { value: 'Word Count', selected: true },
  ]);
  const [selectedRelevant, setSelectedRelevant] = useState(false);
  const [selectedRelevantList, setSelectedRelevantList] = useState([
    { value: 'Spelling', selected: false },
    { value: 'Cliches', selected: false },
    { value: 'Passive Voice', selected: false },
    // the new list
    { value: 'Authors Voice', selected: false },
    { value: 'Writing Style', selected: false },
    { value: 'Pacing', selected: false },
    { value: 'Dialogue Quality', selected: false },
    { value: 'Grammar and Mechanics', selected: false },
  ]);
  const [selectedPromising, setSelectedPromising] = useState(false);
  const [selectedPromisingList, setSelectedPromisingList] = useState([
    { value: 'Plot Structure', selected: false },
    { value: 'Plot Beats', selected: false },
    { value: 'Compelling topic', selected: false },
    // minimal_paraphrasing , minimal_permissions
    { value: 'Minimal Paraphrasing', selected: false },
    { value: 'Minimal Permissions', selected: false },
    { value: 'Setting and World Building', selected: false },
    { value: 'World Building', selected: false },
    { value: 'Character Development', selected: false },
    { value: 'Marketability', selected: false },
    { value: 'Concept Originality', selected: false },
  ]);
  const [wordCountMin, setWordCountMin] = useState(0);
  const [wordCountMax, setWordCountMax] = useState(0);

  const [keywordsList, setKeywordsList] = useState([]);

  const navigate = useNavigate();

  const handlesetKeywordsList = (keyword, addOrRemove) => {
    if (!addOrRemove) {
      if (keywordsList.length >= 5) {
        // Corrected line
        renderCenteredAlert('You can select a maximum of 5 keywords');
        return;
      }
      const newKeywords = [...keywordsList, keyword];
      setKeywordsList(newKeywords);
    } else {
      const newKeywords = keywordsList.filter((c) => c !== keyword); // Corrected line
      setKeywordsList(newKeywords); // Corrected line
    }
  };

  const {
    setPreferanses,

    // Add other functions from the hook if needed
  } = useApiUtils();

  useEffect(() => {
    if (companyInfo && companyInfo.Preferences) {
      if (companyInfo.Preferences.genres) {
        const newGenres = selectedFictionGenres.map((genre) => {
          if (companyInfo.Preferences.genres.includes(genre.genre)) {
            return {
              ...genre,
              selected: true,
            };
          }
          return genre;
        });
        setSelectedFictionGenres(newGenres);
      }
      // do it for non fiction
      if (companyInfo.Preferences.non_fiction_genres) {
        const newGenres = selectedNonFictionGenres.map((genre) => {
          if (
            companyInfo.Preferences.non_fiction_genres.includes(genre.genre)
          ) {
            return {
              ...genre,
              selected: true,
            };
          }
          return genre;
        });
        setSelectedNonFictionGenres(newGenres);
      }
      if (companyInfo.Preferences.themes_campaign?.length > 0) {
        setKeywordsList(companyInfo.Preferences.themes_campaign);
      }

      let newSelectedEligibleList = [...selectedEligibleList];
      let newSelectedRelevantList = [...selectedRelevantList];
      let newSelectedPromisingList = [...selectedPromisingList];
      let newselectedEligible = selectedEligible;
      let newselectedRelevant = selectedRelevant;
      let newselectedPromising = selectedPromising;

      if (companyInfo.Preferences.genre_score === true) {
        newselectedEligible = true;
        newSelectedEligibleList.forEach((c) => {
          if (c.value === 'Genre') {
            c.selected = companyInfo.Preferences.genre_score;
          }
        });
      }

      if (companyInfo.Preferences.word_count_score) {
        newselectedEligible = true;
        newSelectedEligibleList.forEach((c) => {
          if (c.value === 'Word Count') {
            c.selected = companyInfo.Preferences.word_count_score;
          }
        });
      }

      let isAnyEligibleCategorySelected = newSelectedEligibleList.some(
        (c) => c.selected
      );
      if (isAnyEligibleCategorySelected) {
        newSelectedEligibleList = newSelectedEligibleList.map((c) => ({
          ...c,
          selected: true,
        }));
        setSelectedEligibleList(newSelectedEligibleList);
        setEligiblePreferences(true); // Update the eligiblePreferences to true
      } else {
        setEligiblePreferences(false); // This is for the case where none are selected
      }

      if (companyInfo.Preferences.spelling_score) {
        newselectedRelevant = true;
        newSelectedRelevantList.forEach((c) => {
          if (c.value === 'Spelling') {
            c.selected = companyInfo.Preferences.spelling_score;
          }
        });
      }

      if (companyInfo.Preferences.cliches_score) {
        newselectedRelevant = true;
        newSelectedRelevantList.forEach((c) => {
          if (c.value === 'Cliches') {
            c.selected = companyInfo.Preferences.cliches_score;
          }
        });
      }

      if (companyInfo.Preferences.passive_voice) {
        newselectedRelevant = true;
        newSelectedRelevantList.forEach((c) => {
          if (c.value === 'Passive Voice') {
            c.selected = companyInfo.Preferences.passive_voice;
          }
        });
      }
      if (companyInfo.Preferences.authors_voice) {
        newselectedRelevant = true;
        newSelectedRelevantList.forEach((c) => {
          if (c.value === 'Authors Voice') {
            c.selected = companyInfo.Preferences.authors_voice;
          }
        });
      }
      if (companyInfo.Preferences.writing_style_score) {
        newselectedRelevant = true;
        newSelectedRelevantList.forEach((c) => {
          if (c.value === 'Writing Style') {
            c.selected = companyInfo.Preferences.writing_style_score;
          }
        });
      }
      if (companyInfo.Preferences.pacing_score) {
        newselectedRelevant = true;
        newSelectedRelevantList.forEach((c) => {
          if (c.value === 'Pacing') {
            c.selected = companyInfo.Preferences.pacing_score;
          }
        });
      }
      if (companyInfo.Preferences.dialogue_quality) {
        newselectedRelevant = true;
        newSelectedRelevantList.forEach((c) => {
          if (c.value === 'Dialogue Quality') {
            c.selected = companyInfo.Preferences.dialogue_quality;
          }
        });
      }
      if (companyInfo.Preferences.grammar_and_mechanics) {
        newselectedRelevant = true;
        newSelectedRelevantList.forEach((c) => {
          if (c.value === 'Grammar and Mechanics') {
            c.selected = companyInfo.Preferences.grammar_and_mechanics;
          }
        });
      }

      if (companyInfo.Preferences.ps_score) {
        newselectedPromising = true;
        newSelectedPromisingList.forEach((c) => {
          if (c.value === 'Plot Structure') {
            c.selected = companyInfo.Preferences.ps_score;
          }
        });
      }

      if (companyInfo.Preferences.pb_score) {
        newselectedPromising = true;
        newSelectedPromisingList.forEach((c) => {
          if (c.value === 'Plot Beats') {
            c.selected = companyInfo.Preferences.pb_score;
          }
        });
      }

      if (companyInfo.Preferences.compelling_topic) {
        newselectedPromising = true;
        newSelectedPromisingList.forEach((c) => {
          if (c.value === 'Compelling topic') {
            c.selected = companyInfo.Preferences.compelling_topic;
          }
        });
      }
      if (companyInfo.Preferences.minimal_paraphrasing) {
        newselectedPromising = true;
        newSelectedPromisingList.forEach((c) => {
          if (c.value === 'Minimal Paraphrasing') {
            c.selected = companyInfo.Preferences.minimal_paraphrasing;
          }
        });
      }
      if (companyInfo.Preferences.minimal_permissions) {
        newselectedPromising = true;
        newSelectedPromisingList.forEach((c) => {
          if (c.value === 'Minimal Permissions') {
            c.selected = companyInfo.Preferences.minimal_permissions;
          }
        });
      }

      if (companyInfo.Preferences.setting_and_world_building) {
        newselectedPromising = true;
        newSelectedPromisingList.forEach((c) => {
          if (c.value === 'Setting and World Building') {
            c.selected = companyInfo.Preferences.setting_and_world_building;
          }
        });
      }
      if (companyInfo.Preferences.world_building) {
        newselectedPromising = true;
        newSelectedPromisingList.forEach((c) => {
          if (c.value === 'World Building') {
            c.selected = companyInfo.Preferences.world_building;
          }
        });
      }
      if (companyInfo.Preferences.character_development) {
        newselectedPromising = true;
        newSelectedPromisingList.forEach((c) => {
          if (c.value === 'Character Development') {
            c.selected = companyInfo.Preferences.character_development;
          }
        });
      }
      if (companyInfo.Preferences.marketability) {
        newselectedPromising = true;
        newSelectedPromisingList.forEach((c) => {
          if (c.value === 'Marketability') {
            c.selected = companyInfo.Preferences.marketability;
          }
        });
      }
      if (companyInfo.Preferences.concept_originality) {
        newselectedPromising = true;
        newSelectedPromisingList.forEach((c) => {
          if (c.value === 'Concept Originality') {
            c.selected = companyInfo.Preferences.concept_originality;
          }
        });
      }

      if (companyInfo.Preferences.wordcount_min) {
        setWordCountMin(companyInfo.Preferences.wordcount_min);
      }
      if (companyInfo.Preferences.wordcount_max) {
        setWordCountMax(companyInfo.Preferences.wordcount_max);
      }

      let isAnyRelevantCategorySelected = newSelectedRelevantList.some(
        (c) => c.selected
      );
      let isAnyPromisingCategorySelected = newSelectedPromisingList.some(
        (c) => c.selected
      );

      if (isAnyRelevantCategorySelected) {
        newSelectedRelevantList = newSelectedRelevantList.map((c) => ({
          ...c,
          selected: true,
        }));
        setSelectedRelevantList(newSelectedRelevantList);
        setRelevantPreferences(true); // Update the relevantPreferences to true
      } else {
        setRelevantPreferences(false); // This is for the case where none are selected
      }

      if (isAnyPromisingCategorySelected) {
        newSelectedPromisingList = newSelectedPromisingList.map((c) => ({
          ...c,
          selected: true,
        }));
        setSelectedPromisingList(newSelectedPromisingList);
        setPromisingPreferences(true); // Update the promisingPreferences to true
      } else {
        setPromisingPreferences(false); // This is for the case where none are selected
      }
    }
  }, [companyInfo]);

  // Validation for word count
  const isValidWordCount = () => {
    const min = parseInt(wordCountMin, 10);
    const max = parseInt(wordCountMax, 10);
    return min >= 0 && max <= 10000000 && max >= min;
  };

  const handleNonFictionGenreFictionSelection = (genre) => {
    const newGenres = selectedNonFictionGenres.map((g) => {
      if (g.genre === genre) {
        return {
          ...g,
          selected: !g.selected,
        };
      }
      return g;
    });
    setSelectedNonFictionGenres(newGenres);
  };

  const handleGenreFictionSelection = (genre) => {
    const newGenres = selectedFictionGenres.map((g) => {
      if (g.genre === genre) {
        return {
          ...g,
          selected: !g.selected,
        };
      }
      return g;
    });
    setSelectedFictionGenres(newGenres);
  };

  const handleRelevantSelectioncategoty = (category) => {};

  const handlePromisingSelectioncategoty = (category) => {};

  const handleSavePreferanses = async () => {
    if (userInfo.role === 'lvl1') {
      props.showErrorMessage();
      renderCenteredAlert('You are not authorized to perform this action');
      return;
    }

    // if selectedGenres.filter(genre => genre.selected) is empty, show error message
    if (
      selectedFictionGenres.filter((genre) => genre.selected).length === 0 &&
      selectedNonFictionGenres.filter((genre) => genre.selected).length === 0
    ) {
      renderCenteredAlert('Please select at least one genre');
      return;
    }

    //if wordCountMin is empty, show error message
    if (wordCountMin === '') {
      renderCenteredAlert('Please enter minimum word count ');
      return;
    }
    if (wordCountMax === '') {
      renderCenteredAlert('Please enter maximum word count ');
      return;
    }
    if (!isValidWordCount()) {
      renderCenteredAlert(
        'Invalid word count range. Please check the minimum and maximum values. The minimum value should be greater than 0 and the maximum value should be less than 10000000 and greater than the minimum value.'
      );
      return;
    }
    if (saveButtonState === 'loading') {
      return;
    }

    setSaveButtonState('loading');

    // Construct the data to be sent to the backend API
    const dataToSend = {
      user_id: userInfo.user_id,
      // genres: selectedGenres.map(genre => genre.genre),
      // send onlt selected genres
      genres: selectedFictionGenres
        .filter((genre) => genre.selected)
        .map((genre) => genre.genre),
      genre_score: selectedEligibleList.find(
        (category) => category.value === 'Genre'
      ).selected,
      non_fiction_genres: selectedNonFictionGenres
        .filter((genre) => genre.selected)
        .map((genre) => genre.genre),

      word_count_score: selectedEligibleList.find(
        (category) => category.value === 'Word Count'
      ).selected,
      spelling_score: selectedRelevantList.find(
        (category) => category.value === 'Spelling'
      ).selected,
      cliches_score: selectedRelevantList.find(
        (category) => category.value === 'Cliches'
      ).selected,
      passive_voice: selectedRelevantList.find(
        (category) => category.value === 'Passive Voice'
      ).selected,
      authors_voice: selectedRelevantList.find(
        (category) => category.value === 'Authors Voice'
      ).selected,
      writing_style_score: selectedRelevantList.find(
        (category) => category.value === 'Writing Style'
      ).selected,
      pacing_score: selectedRelevantList.find(
        (category) => category.value === 'Pacing'
      ).selected,
      dialogue_quality: selectedRelevantList.find(
        (category) => category.value === 'Dialogue Quality'
      ).selected,
      grammar_and_mechanics: selectedRelevantList.find(
        (category) => category.value === 'Grammar and Mechanics'
      ).selected,
      ps_score: selectedPromisingList.find(
        (category) => category.value === 'Plot Structure'
      ).selected,
      pb_score: selectedPromisingList.find(
        (category) => category.value === 'Plot Beats'
      ).selected,
      compelling_topic: selectedPromisingList.find(
        (category) => category.value === 'Compelling topic'
      ).selected,
      minimal_paraphrasing: selectedPromisingList.find(
        (category) => category.value === 'Minimal Paraphrasing'
      ).selected,
      minimal_permissions: selectedPromisingList.find(
        (category) => category.value === 'Minimal Permissions'
      ).selected,
      setting_and_world_building: selectedPromisingList.find(
        (category) => category.value === 'Setting and World Building'
      ).selected,
      world_building: selectedPromisingList.find(
        (category) => category.value === 'World Building'
      ).selected,
      character_development: selectedPromisingList.find(
        (category) => category.value === 'Character Development'
      ).selected,
      marketability: selectedPromisingList.find(
        (category) => category.value === 'Marketability'
      ).selected,
      concept_originality: selectedPromisingList.find(
        (category) => category.value === 'Concept Originality'
      ).selected,
      wordcount_min: wordCountMin,
      wordcount_max: wordCountMax,
      themes_campaign: keywordsList,
    };
    try {
      await setPreferanses(dataToSend, true);
      setSaveButtonState('normal');
    } catch (error) {
      setSaveButtonState('normal');
    }
  };

  const getButtonText = () => {
    switch (saveButtonState) {
      case 'saving':
        return 'Saving...';
      case 'success':
        return 'Saved Successfully';
      case 'failure':
        return 'Failed to Save';
      case 'loading':
        return <CircularProgress size={15} color="inherit" />;
      default:
        return 'Save Changes';
    }
  };

  const handleEligiblePreferences = () => {
    // if this is a check so update allselectedEligibleList to true
    //  alert that this category cannot be unchecked
    renderCenteredAlert(
      'The Eligibility  is essential and cannot be deselected'
    );
    return;
    if (!eligiblePreferences) {
      const newSelectedEligibleList = [
        { value: 'Genre', selected: true },
        { value: 'Word Count', selected: true },
      ];
      setSelectedEligibleList(newSelectedEligibleList);
    } else {
      const newSelectedEligibleList = [
        { value: 'Genre', selected: false },
        { value: 'Word Count', selected: false },
      ];
      setSelectedEligibleList(newSelectedEligibleList);
    }
    setEligiblePreferences(!eligiblePreferences);
  };

  const handleRelevantPreferences = () => {
    if (!relevantPreferences) {
      const newSelectedRelevantList = [
        { value: 'Spelling', selected: true },
        { value: 'Cliches', selected: true },
        { value: 'Passive Voice', selected: true },
        // the new list
        { value: 'Authors Voice', selected: true },
        { value: 'Writing Style', selected: true },
        { value: 'Pacing', selected: true },
        { value: 'Dialogue Quality', selected: true },
        { value: 'Grammar and Mechanics', selected: true },
      ];
      setSelectedRelevantList(newSelectedRelevantList);
    } else {
      const newSelectedRelevantList = [
        { value: 'Spelling', selected: false },
        { value: 'Cliches', selected: false },
        { value: 'Passive Voice', selected: false },
        // the new list
        { value: 'Authors Voice', selected: false },
        { value: 'Writing Style', selected: false },
        { value: 'Pacing', selected: false },
        { value: 'Dialogue Quality', selected: false },
        { value: 'Grammar and Mechanics', selected: false },
      ];
      setSelectedRelevantList(newSelectedRelevantList);
    }
    setRelevantPreferences(!relevantPreferences);
  };

  const handlePromisingPreferences = () => {
    if (!promisingPreferences) {
      const newSelectedPromisingList = [
        { value: 'Plot Structure', selected: true },
        { value: 'Plot Beats', selected: true },
        { value: 'Compelling topic', selected: true },
        // minimal_paraphrasing , minimal_permissions
        { value: 'Minimal Paraphrasing', selected: true },
        { value: 'Minimal Permissions', selected: true },
        // the new list
        { value: 'Setting and World Building', selected: true },
        { value: 'World Building', selected: true },
        { value: 'Character Development', selected: true },
        { value: 'Marketability', selected: true },
        { value: 'Concept Originality', selected: true },
      ];
      setSelectedPromisingList(newSelectedPromisingList);
    } else {
      const newSelectedPromisingList = [
        { value: 'Plot Structure', selected: false },
        { value: 'Plot Beats', selected: false },
        // the new list
        { value: 'Setting and World Building', selected: false },
        { value: 'World Building', selected: false },
        { value: 'Character Development', selected: false },
        { value: 'Marketability', selected: false },
        { value: 'Concept Originality', selected: false },
        { value: 'Compelling topic', selected: false },
        { value: 'Minimal Paraphrasing', selected: false },
        { value: 'Minimal Permissions', selected: false },
      ];
      setSelectedPromisingList(newSelectedPromisingList);
    }
    setPromisingPreferences(!promisingPreferences);
  };

  return (
    <div className={styles.logosignincontainer}>
      <div className={styles.signcontainer}>
        <div className={styles.body}>
          <div className={styles.titlelogo}>
            <div className={styles.loginparttitle}>
              Automatic Matching Guidelines{' '}
            </div>
          </div>

          <div className={styles.loginpartsubtitle}>
            <strong>Need help? </strong> You can reach us anytime via{' '}
            <a className={styles.linktomail} href="mailto:info@storywise.ai">
              info@storywise.ai
            </a>
          </div>
          <div className={styles.maindescription}>
            Prioritize your submissions. Manuscripts that do not meet your
            requirements will be downgraded. You can still review them later.
          </div>
          <div className={styles.cat_title}>
            Auto Decline if Manuscripts are not:
          </div>

          <div className={styles.Qualification_Categories}>
            <div className={styles.categoriesCard1}>
              <Checkbox
                checked={eligiblePreferences}
                onChange={handleEligiblePreferences}
                id="Eligible"
                sx={{
                  color: '#1ceaa0',
                  '&.Mui-checked': {
                    color: '#1ceaa0',
                  },
                }}
              />

              <div className={styles.cat_title}>Eligible</div>
              <div onClick={() => toggleExpand('Eligible')} id="expandE">
                <img
                  src={expandState.Eligible ? despend : expend_score}
                  alt="expand"
                  className={styles.expand}
                />
              </div>
            </div>
            {expandState.Eligible && (
              <div className={styles.category_content}>
                <div className={styles.titlesword}>Word count range</div>
                <div className={styles.description}>
                  Manuscripts with a word count not in this range will
                  automatically be declined.
                </div>

                <div className={styles['word-count-container']}>
                  <input
                    type="number"
                    value={wordCountMin}
                    onChange={(e) => setWordCountMin(e.target.value)}
                    placeholder="Min"
                    className={styles.input}
                  />
                  <div className={styles.rangeIndicator}>-</div>
                  <input
                    type="number"
                    value={wordCountMax}
                    onChange={(e) => setWordCountMax(e.target.value)}
                    placeholder="Max"
                    className={styles.input}
                  />
                </div>

                <div className={styles.titles}>Select Your Wishlist</div>
                <div className={styles.description}>
                  Choose the book genres that you are interested in publishing:{' '}
                </div>
                <div className={styles.titles}>Fiction</div>
                <div className={styles.genres_container}>
                  {selectedFictionGenres.map((genre, index) => (
                    <GenreCard
                      key={index}
                      genre={genre.genre}
                      genre_image={genre.genre_image}
                      selected={genre.selected}
                      onClick={handleGenreFictionSelection}
                    />
                  ))}
                </div>

                <div className={styles.titles}>Non-Fiction</div>
                <div className={styles.genres_container}>
                  {selectedNonFictionGenres.map((genre, index) => (
                    <GenreCard
                      key={index}
                      genre={genre.genre}
                      genre_image={genre.genre_image}
                      selected={genre.selected}
                      onClick={handleNonFictionGenreFictionSelection}
                    />
                  ))}
                </div>

                <div className={styles.titles}>Campaign Themes</div>
                <div className={styles.description}>
                  For submissions not aligning with the preferred genres, do not
                  automatically decline. Instead, if they include any of the
                  listed keywords, highlight them with a star for further
                  review.
                </div>
                <div className={styles.listofcard}>
                  {keywordsList?.map((category, index) => (
                    <CategoriesCard
                      key={index}
                      categoty={category}
                      selected={true}
                      handleKeywordsList={handlesetKeywordsList}
                      thisKeywordsRemovable={true}
                      greenButtons={true}
                    />
                  ))}
                  <CategoriesCard
                    category={'Add+'}
                    selected={false}
                    handleKeywordsList={handlesetKeywordsList}
                    addCard={true}
                  />
                </div>
              </div>
            )}
          </div>
          <div className={styles.Qualification_Categories}>
            <div className={styles.categoriesCard1}>
              <Checkbox
                id="Relevant"
                checked={relevantPreferences}
                onChange={handleRelevantPreferences}
                inputProps={{ 'aria-label': 'controlled' }}
                sx={{
                  color: '#1ceaa0',
                  '&.Mui-checked': {
                    color: '#1ceaa0',
                  },
                }}
              />

              <div className={styles.cat_title}>Relevant</div>
              <div onClick={() => toggleExpand('Relevant')} id="expandR">
                <img
                  src={expandState.Relevant ? despend : expend_score}
                  alt="expand"
                  className={styles.expand}
                />
              </div>
            </div>
            {expandState.Relevant && (
              <div className={styles.category_content}>
                <div className={styles.description}>
                  If checked, books that do not sufficiently meet the following
                  criteria will be auto-declined:{' '}
                </div>
                {/* list of relevant  */}
                <div className={styles.listofcard}>
                  {selectedRelevantList.map((category, index) => (
                    <CategoriesCard
                      key={index}
                      categoty={category.value}
                      selected={category.selected}
                      onClick={handleRelevantSelectioncategoty}
                      greenButtons={true}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
          <div className={styles.Qualification_Categories}>
            <div className={styles.categoriesCard1}>
              <Checkbox
                id="Promising"
                checked={promisingPreferences}
                onChange={handlePromisingPreferences}
                sx={{
                  color: '#1ceaa0',
                  '&.Mui-checked': {
                    color: '#1ceaa0',
                  },
                }}
              />

              <div className={styles.cat_title}>Promising</div>
              <div onClick={() => toggleExpand('Promising')} id="expandP">
                <img
                  src={expandState.Promising ? despend : expend_score}
                  alt="expand"
                  className={styles.expand}
                />{' '}
              </div>
            </div>
            {expandState.Promising && (
              <div className={styles.category_content}>
                <div className={styles.description}>
                  If checked, books that do not sufficiently meet the following
                  criteria will be auto-declined:{' '}
                </div>
                {/* list of promising  */}
                <div className={styles.listofcard}>
                  {selectedPromisingList.map((category, index) => (
                    <CategoriesCard
                      key={index}
                      categoty={category.value}
                      selected={category.selected}
                      onClick={handlePromisingSelectioncategoty}
                      greenButtons={true}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={styles.fotter}>
          <div
            className={`${styles.button}
                         ${
                           saveButtonState === 'success'
                             ? styles.successButton
                             : saveButtonState === 'failure'
                             ? styles.failureButton
                             : ''
                         }`}
            onClick={handleSavePreferanses}
          >
            <div type="submit" className={styles.button}>
              <div className={styles.textbutton}>{getButtonText()}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Preferences;
