import styles from './EmailTemplate.module.css';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { alpha, styled } from '@mui/material/styles';
import { pink } from '@mui/material/colors';
import Switch from '@mui/material/Switch';

import { useRecoilState } from 'recoil';
import { companyInfoState, userInfoState } from '../../state';
import useApiUtils from '../../useApiUtils';
import CircularProgress from '@mui/material/CircularProgress';
import ApproveAD from './ApproveAD';
import { renderCenteredAlert } from '../CenteredAlert';

const EmailTemplate = (props) => {
  const [companyInfo, setCompanyInfo] = useRecoilState(companyInfoState);
  const navigate = useNavigate();
  const [checked, setChecked] = React.useState(false);
  const [delay, setDelay] = React.useState(7);

  const [showApproveAD, setShowApproveAD] = useState(false);
  const [saveButtonState, setSaveButtonState] = useState('normal'); // 'normal', 'saving', 'success', 'failure'
  const defaultSubject = 'Default Subject';
  const defaultContent = 'Default Content';
  const [subject, setSubject] = useState(defaultSubject);
  const [emailContent, setEmailContent] = useState(defaultContent);

  const {
    getManuscriptsInfo,
    initialgetBookChapter,
    get_book_scores,
    getbookgenrekeywords,
    get_user_info,
    getCompanyInformation,
    emailTemplate,

    // Add other functions from the hook if needed
  } = useApiUtils();

  useEffect(() => {
    if (!companyInfo || !companyInfo.rejection_template) return;
    setSubject(companyInfo.rejection_template.subject);
    setEmailContent(companyInfo.rejection_template.content);
    setChecked(companyInfo.rejection_template.send_auto_decline_emails);
    setDelay(companyInfo.rejection_template.decline_email_delay_days);
  }, [companyInfo]);

  const [userInfo, setUserInfo] = useRecoilState(userInfoState); // Update the path

  const handleSaveChanges = async () => {
    if (userInfo.role === 'lvl1') {
      props.showErrorMessage();
      renderCenteredAlert('You are not authorized to perform this action');
      return;
    }
    // if (!emailContent.includes('{{Author name}}') || !emailContent.includes('{{Company Name}}')) {
    if (!emailContent.includes('{{Author name}}')) {
      // Show an alert to inform the user
      renderCenteredAlert(
        'You cannot remove the placeholders "{{Author name}}"'
      );
      setEmailContent(companyInfo.rejection_template.content);
      return; // Prevent further execution
    }

    if (!emailContent.includes('{{observations}}')) {
      // Show an alert to inform the user
      renderCenteredAlert(
        'Please note: The placeholder "{{observations}}" must remain in the template to ensure personalized feedback is included. You can customize the specific details of the observation during the decline process. Restoring the original template to maintain template integrity.'
      );
      setEmailContent(companyInfo.rejection_template.content);
      return; // Prevent further execution
    }

    if (saveButtonState === 'loading') {
      return;
    }
    // Update button state to indicate saving
    setSaveButtonState('loading');

    const requestBody = {
      user_id: userInfo.user_id,
      new_rejection_template: emailContent,
      new_subject: subject,
      send_auto_decline_emails: checked,
      decline_email_delay_days: delay,
    };

    try {
      // Call the emailTemplate function
      await emailTemplate(requestBody);

      // If successful, update button state to indicate success
      setSaveButtonState('normal');

      // Reset button state to normal after 3 seconds
      // setTimeout(() => {
      //     setSaveButtonState('normal');
      // }, 3000);
    } catch (error) {
      // If there's an error, update button state to indicate failure
      setSaveButtonState('normal');

      // Reset button state to normal after 3 seconds
      // setTimeout(() => {
      //     setSaveButtonState('normal');
      // }, 3000);
    }
  };

  // Define the button text based on the button state
  const getButtonText = () => {
    switch (saveButtonState) {
      case 'saving':
        return 'Saving...';
      case 'success':
        return 'Saved Successfully';
      case 'failure':
        return 'Failed to Save';
      case 'loading':
        return <CircularProgress size={15} color="inherit" />;

      default:
        return 'Save Changes';
    }
  };

  const handleChange = (event) => {
    //if the event is checked, then set the state to true
    //if the event is uncheck do it
    if (!event.target.checked) {
      setChecked(event.target.checked);
    } else {
      setShowApproveAD(true);
    }
  };

  const PinkSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: '#1CEAA0',
      '&:hover': {
        backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#a1e0c9',
    },
  }));

  return (
    <div className={styles.logosignincontainer}>
      <div className={styles.signcontainer}>
        {showApproveAD && (
          <ApproveAD return={setShowApproveAD} handleApprove={setChecked} />
        )}
        <div className={styles.body}>
          <div className={styles.titlelogo}>
            <div className={styles.loginparttitle}>Edit Rejection Template</div>
          </div>
          <div className={styles.loginpartsubtitle}>
            <strong>Need help? </strong> You can reach us anytime via{' '}
            <a className={styles.linktomail} href="mailto:info@storywise.ai">
              info@storywise.ai
            </a>
          </div>
          <div className={styles.loginpartsubtitle1}>
            Edit your standard email rejection mail below:
          </div>
          <div className={styles.labelInputContainer}>
            <label className={styles.label}>Subject</label>
            <input
              className={styles.input}
              type="text"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            />
          </div>
          <div className={styles.labelInputContainer}>
            <textarea
              className={`${styles.input} ${styles.textarea}`}
              value={emailContent}
              onChange={(e) => setEmailContent(e.target.value)}
              placeholder={emailContent}
            />
          </div>
          <div className={styles.label_enable_auto_delay}>
            <div className={styles.label_enable_auto}>
              Number of days to delay sending declined mails:
            </div>
            <input
              className={styles.inputnumber}
              type="number"
              value={delay}
              onChange={(e) => setDelay(e.target.value)}
            />
          </div>
          <div className={styles.enable_auto}>
            <div className={styles.label_enable_auto}>
              Enable sending decline mails for auto-declined submissions
            </div>
            <PinkSwitch
              checked={checked}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </div>
        </div>
        <div className={styles.fotter}>
          <div className={styles.buttoncontainer}>
            <div
              className={`${styles.button} ${
                saveButtonState === 'success'
                  ? styles.successButton
                  : saveButtonState === 'failure'
                  ? styles.failureButton
                  : ''
              }`}
              onClick={handleSaveChanges}
            >
              <div className={styles.textbutton}>{getButtonText()}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailTemplate;
