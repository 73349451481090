import styles from './Edit.module.css';
import xxx from '../../../icons/xxx.svg';
import handpoint from '../../../icons/vuesax/bulk/vuesax/bulk/imageuploadI.svg';
import React, {
  useCallback,
  useMemo,
  useState,
  useRef,
  useEffect,
} from 'react';

import { useRecoilState } from 'recoil';
import {
  fileState,
  fileInfoState,
  clickedUploadFileState,
} from '../../../state';
import { useDropzone } from 'react-dropzone';
import useApiUtils from '../../../useApiUtils';
import { renderCenteredAlert } from '../../CenteredAlert';

const baseStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  // gap: "10px",
  height: '50%',
  width: '100%',
  cursor: 'pointer',
  // padding: "10px",
  // background: "#F7F7F7",
  border: '1px dashed #71858B',
  borderRadius: '12px',
  // flex: "none",
  // order: 0,
  // alignSelf: "stretch",
  // flexGrow: 0,
};

const activeStyle = {
  background: '#F7F7F7',
};

const Edit = (props) => {
  const [clickedUploadFile, setClickedUploadFile] = useRecoilState(
    clickedUploadFileState
  );
  const [fileInfo, setFileInfo] = useRecoilState(fileInfoState);

  const [wasUploaded, setWasUploaded] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [files, setFiles] = useState([]);
  //creat sates for the input fields
  const [manuscriptNameInput, setManuscriptNameInput] = useState(
    clickedUploadFile.manuscript_name
  );

  const [authorNameInput, setAuthorNameInput] = useState('');
  const [authorEmailInput, setAuthorEmailInput] = useState('');

  const {
    getManuscriptsInfo,
    initialgetBookChapter,
    get_book_scores,
    getbookgenrekeywords,
    get_user_info,
    getCompanyInformation,
    setPreferanses,
    handleUploadFileImage,

    // Add other functions from the hook if needed
  } = useApiUtils();

  //i want to detect click outside of the deletebox
  const editRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (editRef.current && !editRef.current.contains(event.target)) {
        props.handleEdit(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [editRef]);

  const handleOut = () => {
    props.handleEdit(false);
    setWasUploaded(false);
  };

  const handleSave = async () => {
    if (authorEmailInput !== '') {
      // Check if authorEmailInput is a valid email address using a regular expression
      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      if (!emailPattern.test(authorEmailInput)) {
        // Invalid email address, show an error message or take appropriate action
        renderCenteredAlert(
          'Invalid email address. Please enter a valid email address.'
        );
        return; // Don't proceed with saving changes
      }
    }
    props.handleEdit(false);
    setWasUploaded(false);
    const updatedList = fileInfo.map(async (manuscript) => {
      if (manuscript.id === clickedUploadFile.id) {
        const manuscriptNameInput_ = manuscriptNameInput;
        const authorNameInput_ = authorNameInput;
        const AuthorEmailInput_ = authorEmailInput;

        let imageUrl = null;
        if (imagePreview !== null) {
          imageUrl = await handleUploadFileImage(files[0]);
        }

        return {
          ...manuscript,
          manuscript_name:
            manuscriptNameInput_ !== ''
              ? manuscriptNameInput_
              : manuscript.manuscript_name,
          author_name:
            authorNameInput_ !== '' ? authorNameInput_ : manuscript.author_name,
          img: imageUrl,
          image: imagePreview !== null ? imagePreview : manuscript.image,
          author_email:
            AuthorEmailInput_ !== ''
              ? AuthorEmailInput_
              : manuscript.author_email,
        };
      }
      return manuscript;
    });

    // Since updatedList is an array of promises, we can use Promise.all to resolve them
    const updatedManuscripts = await Promise.all(updatedList);

    setFileInfo(updatedManuscripts);
  };

  const handleCancel = () => {
    props.handleEdit(false);
    setWasUploaded(false);
  };

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];

      setFiles([file]);

      const reader = new FileReader();

      reader.onloadend = () => {
        setImagePreview(reader.result);
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    }
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    // isDragAccept,
    // isDragReject
  } = useDropzone({
    onDrop,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      // ...(isDragAccept ? acceptStyle : {}),
      // ...(isDragReject ? rejectStyle : {})
      display: 'inline-flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }),
    [
      isDragActive,
      // isDragReject,
      // isDragAccept
    ]
  );

  const previewStyle = {
    width: '100%',
    height: '100%',
    backgroundColor: '#f0f0f0',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // marginTop: '10px',
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setFiles([file]);
    const reader = new FileReader();

    reader.onloadend = () => {
      setImagePreview(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.editbox} ref={editRef}>
        <div className={styles.boxsplit}>
          <div className={styles.title}>
            <div className={styles.titleText}>Edit Manuscript metadata</div>
            <div className={styles.buttonOut} onClick={handleOut}>
              <img className={styles.xbutton} src={xxx} />
            </div>
          </div>
          <div className={styles.editboxContent}>
            <div className={styles.editboxContentTextNimage}>
              <div className={styles.editboxContentTextPart}>
                <div className={styles.editboxContentTextTitle}>
                  <label className={styles.lable} htmlFor="ManuscriptName">
                    {' '}
                    <div className={styles.labelText}> Manuscript Name </div>
                  </label>
                  <input
                    className={styles.input}
                    type="text"
                    id="ManuscriptName"
                    name="ManuscriptName"
                    placeholder={clickedUploadFile.manuscript_name}
                    value={manuscriptNameInput}
                    onChange={(e) => setManuscriptNameInput(e.target.value)}
                  />
                </div>
                <div className={styles.editboxContentTextTitle}>
                  <label className={styles.lable} htmlFor="AuthorName">
                    {' '}
                    <div className={styles.labelText}> Author </div>
                  </label>
                  <input
                    className={styles.input}
                    type="text"
                    id="AuthorName"
                    name="AuthorName"
                    placeholder="Author Name"
                    value={authorNameInput}
                    onChange={(e) => setAuthorNameInput(e.target.value)}
                  />
                </div>
                {/* <div className={styles.editboxContentTextTitle}>
                                    <label className={styles.lable} for="Email"> <div className={styles.labelText} > Email </div> </label>
                                    <input className={styles.input} type="text" id="Email" name="Email" placeholder="Enter Author’s Email" />
                                </div> */}
                <div className={styles.editboxContentTextTitle}>
                  <label className={styles.lable} htmlFor="AuthorEmail">
                    {' '}
                    <div className={styles.labelText}> Email </div>
                  </label>
                  <input
                    className={styles.input}
                    type="email"
                    id="AuthorEmail"
                    name="AuthorEmail"
                    placeholder="Enter Author’s Email"
                    value={authorEmailInput}
                    onChange={(e) => setAuthorEmailInput(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={styles.buttons}>
            <div className={styles.cancelButtton} onClick={handleCancel}>
              <div className={styles.cancelButttonText}>Cancel</div>
            </div>
            <div className={styles.saveButtton} onClick={handleSave}>
              <div className={styles.saveButttonText}>Save</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Edit;
