import React, { useState, useEffect } from 'react';
import { CognitoUserPool, CognitoUser } from 'amazon-cognito-identity-js';
import { Link, useLocation } from 'react-router-dom';
import styles from './ForgotPassword.module.css';
import { useNavigate } from 'react-router-dom';
import UncoverLeft from './UncoverLeft';
import CircularProgress from '@mui/material/CircularProgress';





const REACT_APP_BUILD_ENV = process.env.REACT_APP_BUILD_ENV || "REACT_APP_BUILD_ENV-build_env";
const REACT_APP_API_ID = process.env.REACT_APP_API_ID || "REACT_APP_BUILD_ENV2-build_env";

const poolData  = {
  UserPoolId: process.env.REACT_APP_USER_POOL_ID || "USER_POOL_ID-build_env" ,
  ClientId: process.env.REACT_APP_CLIENT_ID_USER_POOL || "CLIENT_ID_USER_POOL-build_env",
};


const userPool = new CognitoUserPool(poolData);

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [isEmailSent, setIsEmailSent] = useState(false);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const [saveButtonState, setSaveButtonState] = useState('normal'); // 'normal', 'saving', 'success', 'failure'


  const location = useLocation();
  useEffect(() => {
    // Extract the email from the query parameter
    const queryParams = new URLSearchParams(location.search);
    const emailFromQuery = queryParams.get('email');
    if (emailFromQuery) {
      setEmail(emailFromQuery);
    }
  }, [location.search]);

  const handleForgotPassword = (event) => {
    event.preventDefault();
    if(saveButtonState === 'loading'){
      return;
  }
  setSaveButtonState('loading');

    const userData = {
      Username: email,
      Pool: userPool,
    };

    const cognitoUser = new CognitoUser(userData);
    cognitoUser.forgotPassword({
      onSuccess: () => {
        // <Link to={`/forgot-password?email=${encodeURIComponent(email)}`}>Forgot password</Link>
        // navigate to the reset password page with email query parameter

        setSaveButtonState('normal');
        navigate(`/reset-password?email=${encodeURIComponent(email)}`);
        setIsEmailSent(true);


      },
      onFailure: (error) => {
        setSaveButtonState('normal');
        console.error('Error sending password reset email', error);
        setErrorMessage(error.message);
      },
    });
  };

  const getButtonText = () => {
    switch (saveButtonState) {
        case 'saving':
            return 'Saving...';
        case 'success':
            return 'Saved Successfully';
        case 'failure':
            return 'Failed to Save';
        case 'loading':
            return <CircularProgress size={15} color="inherit" />;    
        default:
            return 'Continue';
    }
};

  return (
    <div className={styles.container}>
      <UncoverLeft />
      <div className={styles.logocontainer}>
        <div className={styles.logincontainer}>
          <div className={styles.linktosignup}>
            <div className={styles.beforelink}>
              Back to
            </div>
            <Link to="/" className={styles.linkto}> Login</Link>
          </div>
          <div className={styles.loginpart}>

            <div className={styles.loginparttitle}>Forgot Your Password?</div>
            {!isEmailSent ? (
              <>
                <div className={styles.loginpartsubtitle}>

                  Please enter the email address you’d like your password reset information sent to.
                </div>
                {errorMessage && <p className={styles['error-message']}>{errorMessage}</p>}

                <div className={styles.formpart} onSubmit={handleForgotPassword}>
                  <label className={styles.label}>Email</label>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    className={styles.input}
                    placeholder='Email'
                  />
                  <div className={styles.lastrow}>
                    <div type="submit" className={styles.loginbutton} onClick={handleForgotPassword}>
                      <div 
                        className={`${styles.loginbuttontext}
                        ${saveButtonState === 'success' ? styles.successButton : saveButtonState === 'failure' ? styles.failureButton : ''}`}         >
                                              {getButtonText()}
                        
                      </div>
                    </div>
                  </div>
                </div>
                </>
                ) : (
                <p>Email sent</p>
            )}
              </div>
          </div>
        </div>
      </div>
      );
};

      export default ForgotPassword;