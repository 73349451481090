import styles from './ReviwePage.module.css';
import InfoBookProg from './progressbar/InfoBookProg';
import DisplayMenuscript from './manuscript/DisplayMenuscript';
import SubmissionNotes from './allNote/SubmissionNotes';
import ApproveManuscript from './R&A/ApproveManuscript';
import RejectManuscript from './R&A/RejectManuscript';
import { useState, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { useParams } from 'react-router-dom';
import {
  selectManuscriptByRatingState,
  clickedManuscriptAtom,
  showChatState,
  userInfoState,
  scoreDataAtom,
  manuscriptInfoListAtom,
  currentManuscriptAtom,

} from '../../state';
import { useNavigate } from 'react-router-dom';
import NavigationBar from '../../NavigationBar';
import Header from '../header/header';
import Chat from './chat/Chat';
import ScoreDisplay from '../../UI/ScoreDisplay';
import expend_score from '../../icons/expend_score copy.svg';
import despend from '../../icons/despend copy.svg';
import useApiUtils from '../../useApiUtils';

import Writing from '../../icons/scores/Writing.svg';
import Editing from '../../icons/scores/Editing.svg';
import Story from '../../icons/scores/Story.svg';
import Characters from '../../icons/scores/Characters.svg';
import World from '../../icons/scores/World.svg';
import AboutBook from '../submission/popupBook/AboutBook';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { Link } from 'react-router-dom';


const ReviwePage = (props) => {
  const [userInfo, setUserInfo] = useRecoilState(userInfoState); // Update the path

  const [showRejectManuscript, setShowRejectManuscript] = useState(false);
  const [showApproveManuscript, setShowApproveManuscript] = useState(false);

  const [userRejected, setUserRejected] = useState(false);
  const [userApproved, setUserApproved] = useState(false);

  const [scoreData, setScoreData] = useRecoilState(scoreDataAtom);
  const [filteredScoreData, setFilteredScoreData] = useState({});
  const [currentManuscript, setCurrentManuscript] = useRecoilState(
    currentManuscriptAtom
  );
  const [showChat, setShowChat] = useRecoilState(showChatState);
  const navigate = useNavigate();

  const [expanded, setExpanded] = useState({});
  const [activeDescription, setActiveDescription] = useState(null);
  const [clickedManuscript, setClickedManuscript] = useRecoilState(
    clickedManuscriptAtom
  );

  const { bookId } = useParams();
  const [manuscriptInfoList, setManuscriptInfoList] = useRecoilState(
    manuscriptInfoListAtom
  );
  useEffect(() => {
    // or scoreData is {}
    if (!scoreData || Object.keys(scoreData).length === 0) {
    if (bookId && userInfo &&  manuscriptInfoList &&manuscriptInfoList.length > 0) {
      // select the first index of a book that is not have status Upload Failed
      let index = 0;
      while (manuscriptInfoList[index].book_id != bookId) {
        index++;
      }
      setClickedManuscript(manuscriptInfoList[index]);
      console.log(manuscriptInfoList[index]);
      setCurrentManuscript({ bookId: bookId });
      initialgetBookChapter(bookId);
      get_book_scores(
        bookId,
      );
      get_conversation_history(
        bookId,
      );
    }
    console.log(bookId);
  }
  }, [bookId, userInfo ,manuscriptInfoList]);



  const toggleExpand = (key) => {
    // closing the active description if it is clicked again
    if (activeDescription === key) {
      setActiveDescription(null);
      setExpanded({ ...expanded, [key]: !expanded[key] });
    } else {
      // Expand the clicked key
      setActiveDescription(key);
      // set the rest of the keys to false and open the clicked key
      const newExpanded = Object.keys(expanded).reduce((acc, curr) => {
        acc[curr] = false;
        return acc;
      }, {});
      setExpanded({ ...newExpanded, [key]: !newExpanded[key] });
    }
  };
  
  useEffect(() => {
    if (scoreData && scoreData.parameters_items) {
      const initialExpandedState = Object.keys(scoreData.parameters_items).map(
        (key) => ({ [key]: false })
      );
      setExpanded(...initialExpandedState);
    }
  }, [scoreData]);

  const {
    getManuscriptsInfo,
    initialgetBookChapter,
    get_book_scores,
    get_conversation_history,
    downloadManuscripts,
    AssignUser,
    handleLogoutClick,
    // Add other functions from the hook if needed
  } = useApiUtils();

  useEffect(() => {
    if (scoreData) {
      const filteredData = Object.keys(scoreData)
        .filter((key) => key !== 'readability')
        .reduce((filtered, key) => {
          filtered[key] = scoreData[key];
          return filtered;
        }, {});
      setFilteredScoreData(filteredData);
    }
  }, [scoreData]);

  const handleNavigation = () => {
    navigate('/submissions');
  };

  useEffect(() => {
    if (!localStorage.getItem('id_token')) {
      navigate('/');
    }
  }, []);

  const handleReject = (ans) => {
    if (ans === false) {
      setShowRejectManuscript(false);
    } else setShowRejectManuscript(true);
  };

  const handleApprove = (ans) => {
    if (ans === false) {
      setShowApproveManuscript(false);
    } else setShowApproveManuscript(true);
  };

  const removePercentSymbol = (scoreString) => {
    return parseFloat(scoreString.replace('%', ''));
  };

  // funcrion that return svg by the key
  const getIcon = (key) => {
    switch (key) {
      case 'Writing Level':
        return Writing;
      case 'Editing Level':
        return Editing;
      case 'Story':
        return Story;
      case 'Characters':
        return Characters;
      case 'World':
        return World;
      default:
        return null;
    }
  };

  const descriptionHeights = {
    'Writing Level': '17vh',
    'Editing Level': '14vh',
    Story: '20vh',
    World: '11vh',
    Characters: '11vh',
    Eligibility: '11vh',
  };

  const [descriptionContainerHeight, setDescriptionContainerHeight] =
    useState(null);

  const [selectManuscriptByRating, setSelectManuscriptByRating] =
    useRecoilState(selectManuscriptByRatingState);
  const [showBookinfo, setShowBookInfo] = useState(false);

  useEffect(() => {
    if (selectManuscriptByRating) {
      setShowBookInfo(true);
      setSelectManuscriptByRating(false);
    }
  }, [selectManuscriptByRating]);

  /////////show success message////////

  const [showSuccess, setShowSuccess] = useState(false);

  const showSuccessMessage = (message) => {
    setShowSuccess(true);
    setTimeout(() => {
      setShowSuccess(false);
    }, 5000);
  };

  return (
    <div className={styles.PageRoot}>
      <Header issubmission={false} issettings={false} />
      <div className={styles.reviewsPageTitle}>
        <Link to="/submissions" className={styles.linktoManuscripts}>
          My Manuscripts
        </Link>
        <div>
          {' '}
          {'>'} {clickedManuscript?.manuscript_name} {'>'} Review
        </div>
      </div>
      {/* <div className={styles.navBar}>{clickedManuscript?.manuscript_name}</div> */}
      {showSuccess && (
        <div
          className={`${styles.successMessage} ${styles.successMessageLeft}`}
        >
          <Stack sx={{ width: '50%' }} spacing={2}>
            <Alert severity="success">
              {' '}
              Your File Was downloaded Successfully!
            </Alert>
          </Stack>
        </div>
      )}
      {showBookinfo && (
        <AboutBook
          id_manuscript={clickedManuscript.book_id}
          return={setShowBookInfo}
          showSuccessMessage={showSuccessMessage}
        />
      )}

      <div className={styles.ReviwePageRoot}>
        {showRejectManuscript && (
          <RejectManuscript
            return={handleReject}
            setUserRejected={setUserRejected}
          />
        )}
        {showApproveManuscript && (
          <ApproveManuscript
            return={handleApprove}
            userApproved={userApproved}
            setUserApproved={setUserApproved}
          />
        )}
        {showChat && <Chat closeChat={setShowChat} />}
        <div className={styles.allmenuscriptPart}>
          <div className={styles.allmenuscript}>
            <DisplayMenuscript
              onReject={handleReject}
              onApprove={handleApprove}
              userRejected={userRejected}
              setUserRejected={setUserRejected}
              userApproved={userApproved}
              setUserApproved={setUserApproved}
            />
          </div>
        </div>
        <div className={styles.NotesPart}>
          <SubmissionNotes />
        </div>
      </div>
    </div>
  );
};

export default ReviwePage;
