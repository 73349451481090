


import styles from './EditFields.module.css';
import xxx from '../../../icons/xxx.svg';
import CoosenCard from "./CoosenCard";
import { useState, useEffect, useRef } from 'react';
import DynamicColumnCreator from './DynamicColumnCreator';

import { useRecoilState } from "recoil";
import {userInfoState, headerColumnsTranslationState, pendingProcessingState ,manuscriptInfoListAtom  } from "../../../state";

import useApiUtils from '../../../useApiUtils';



const EditFields = (props) => {

  const {
    getManuscriptsInfo,
    initialgetBookChapter,
    get_book_scores,
    getbookgenrekeywords,
    editColomnTable,
    // Add other functions from the hook if needed
  } = useApiUtils()
  const [pendingProcessing, setPendingProcessing] = useRecoilState(pendingProcessingState);

  const [headerColumnsTranslationAtom, setHeaderColumnsTranslationAtom] = useRecoilState(headerColumnsTranslationState);
  const [headerColumnsTranslation, setHeaderColumnsTranslation] = useState(headerColumnsTranslationAtom);
  const [question, setQuestion] = useState('');
  const [manuscriptInfoList, setManuscriptInfoList]= useRecoilState(manuscriptInfoListAtom);
 const [deletedfield, setDeletedfield] = useState([]);
 const [userInfo, setUserInfo] = useRecoilState(userInfoState); // Update the path

  // Instead of using columnOrder, get the keys from headerColumnsTranslation and sort them
  const sortedKeys = Object.keys(headerColumnsTranslation).sort((a, b) => a - b);

  //i want to detect click outside of the deletebox
  const edittableboxRef = useRef(null);


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (edittableboxRef.current && !edittableboxRef.current.contains(event.target)) {
        props.setShowEditFields(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [edittableboxRef]);



  const handleCancel = () => {
    props.setShowEditFields(false);
  };

  // const handleSave = () => {
  //   props.setShowEditFields(false);
  // };

  const handleRemoveGenre = (genreId,  column_field) => {

    // Create a copy of the headerColumnsTranslation object
    const updatedHeaderColumnsTranslation = { ...headerColumnsTranslation };

    // Find the key in the headerColumnsTranslation that matches the given genreId
    const keyToRemove = Object.keys(updatedHeaderColumnsTranslation).find(
      (key) => updatedHeaderColumnsTranslation[key].column_name === genreId
    );

    // If the key is found, delete the property corresponding to the genreId
    if (keyToRemove !== undefined) {
      delete updatedHeaderColumnsTranslation[keyToRemove];
      // Update the state with the new object
      setHeaderColumnsTranslation(updatedHeaderColumnsTranslation);
      setDeletedfield([...deletedfield, column_field]);
    }
  };


  const handleDragStart = (event, index) => {
    event.dataTransfer.setData('text/plain', index);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event, index) => {
    const movingIndex = event.dataTransfer.getData('text/plain');
    const updatedColumnOrder = [...sortedKeys];
    updatedColumnOrder.splice(index, 0, updatedColumnOrder.splice(movingIndex, 1)[0]);

    // Create a new headerColumnsTranslation object with the updated order
    const updatedHeaderColumnsTranslation = {};
    updatedColumnOrder.forEach((key, newIndex) => {
      updatedHeaderColumnsTranslation[newIndex] = headerColumnsTranslation[key];
    });

    setHeaderColumnsTranslation(updatedHeaderColumnsTranslation);
  };

  const handleOut = () => {
    props.setShowEditFields(false);
  };




  const handleSave = async () => {
    props.setShowEditFields(false);
   // Prepare the add_manuscript_columns array
   const add_manuscript_columns = question
   ? [
       {
         question: question,
         order_index: Object.keys(headerColumnsTranslation).length,
       },
     ]
   : [];

 // Check if the headerColumnsTranslation has changed
 const isHeaderColumnsChanged =
   JSON.stringify(headerColumnsTranslation) !==
   JSON.stringify(headerColumnsTranslationAtom);

 // Prepare
const edit_manuscript_columns = isHeaderColumnsChanged
  ? Object.keys(headerColumnsTranslation).reduce((acc, key, index) => {
      acc[index] = { ...headerColumnsTranslation[key] };
      return acc;
    }, {})
  : {};



 // Prepare the delete_manuscript_columns array if deletedfield is not empty
 const delete_manuscript_columns =
   deletedfield.length > 0
     ? deletedfield.map((column_field) => ({
         column_field: column_field,
       }))
     : [];

 // Create the requestPayload object by filtering out the empty arrays
 const requestPayload = {
   user_id:  userInfo.user_id,
   ...(add_manuscript_columns.length > 0 && {
     add_manuscript_columns: add_manuscript_columns,
   }),
   ...(isHeaderColumnsChanged && {
     edit_manuscript_columns: edit_manuscript_columns,
   }),
   ...(delete_manuscript_columns.length > 0 && {
     delete_manuscript_columns: delete_manuscript_columns,
   }),
 };

 // Check if any data exists in the requestPayload, and only then make the API call
 if (Object.keys(requestPayload).length > 1) {
  setPendingProcessing(pendingProcessing + 1);
  editColomnTable(requestPayload);
  setQuestion('');
  setDeletedfield([]);

  }
  };





  return (
    <div className={styles.container}>
      <div className={styles.editbox} ref={edittableboxRef}>
        <div className={styles.boxsplit}>
          <div className={styles.title}>
            <div className={styles.titleText}>Edit Fields</div>
            <div className={styles.buttonOut} onClick={handleOut}>
              <img className={styles.xbutton} src={xxx} alt="Close" />
            </div>
          </div>
          <div className={styles.boxsplitfirstPart}>
            <div className={styles.editboxContent}>
              <div className={styles.columnOrderContainer}>
                <div className={styles.firstColumnOrderHeader}>
                  <div className={styles.columnOrderHeader}>Column Order</div>
                </div>
                <div className={styles.secondColumnOrderHeader}>
                  {sortedKeys.map((key,index) => {
                    const column = headerColumnsTranslation[key];
                    return (
                      <div
                        className={styles.genre}
                        key={index}
                        draggable
                        onDragStart={(event) => handleDragStart(event, key)}
                        onDragOver={handleDragOver}
                        onDrop={(event) => handleDrop(event, key)}
                      >
                        <CoosenCard
                          column_field={column.column_field}
                          genre={column.column_name}
                          removable={column.removable}
                          // removable="true"
                          removeGenre={handleRemoveGenre}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            {/* <div className={styles.DynamicColumnCreator}>
              <DynamicColumnCreator question={question} setQuestion={setQuestion}
              />
            </div> */}

          </div>


          <div className={styles.buttons}>
            <div className={styles.cancelButtton} onClick={handleCancel}>
              <div className={styles.cancelButttonText}>Cancel</div>
            </div>
            <div className={styles.saveButtton} onClick={handleSave}>
              <div className={styles.saveButttonText}>Save</div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default EditFields;

