import { useCallback, useState, useRef, useEffect } from 'react';
import styles from './Upload.module.css';
import handpoint from '../../icons/handpoint.svg';
import { useDropzone } from 'react-dropzone';
import DropzoneComponent from './DropzoneComponent';
import { useRecoilState } from 'recoil';
import {
  numberOfUploadedFilesRecentlyState,
  fileState,
  fileInfoState,
} from '../../state';
import useApiUtils from '../../useApiUtils';
import CircularProgress from '@mui/material/CircularProgress';
import { renderCenteredAlert } from '../CenteredAlert';

const Upload = (props) => {
  const { handleUploaded } = props;
  const [uploadProgress, setUploadProgress] = useState(0);

  const [files, setFiles] = useRecoilState(fileState);
  const [fileInfo, setFileInfo] = useRecoilState(fileInfoState);

  const [duringUpload, setDuringUpload] = useState(false);

  const inputRef = useRef();
  const [numberOfUploadedFilesRecently, setNumberOfUploadedFilesRecently] =
    useRecoilState(numberOfUploadedFilesRecentlyState);

  const {
    getManuscriptsInfo,
    initialgetBookChapter,
    get_book_scores,
    getbookgenrekeywords,
    get_user_info,
    getCompanyInformation,
    setPreferanses,
    handleUploadFile,

    // Add other functions from the hook if needed
  } = useApiUtils();

  const handleButtonClick = () => {
    inputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const allowedExtensions = ['pdf', 'epub', 'rtf', 'doc', 'docx', 'txt'];
    const files = Array.from(event.target.files);
    const sizeLimit = 10 * 1024 * 1024; // 10 MB in bytes

    // Early exit if more than 20 files are uploaded
    if (files.length > 20) {
      renderCenteredAlert(`You can only upload 20 files at a time.`);
      setFiles([]);
      return;
    }

    for (const file of files) {
      const fileExtension = file.name.split('.').pop().toLowerCase();
      const isAllowedExtension = allowedExtensions.includes(fileExtension);
      const isWithinSizeLimit = file.size <= sizeLimit;

      if (!isAllowedExtension) {
        renderCenteredAlert(
          `File '${file.name}' is not allowed. Only PDF, EPUB, RTF, DOC, DOCX, and TXT files are accepted. Please upload valid files.`
        );
        setFiles([]);
        return;
      }
      if (!isWithinSizeLimit) {
        renderCenteredAlert(
          `File '${file.name}' exceeds the 10 MB size limit. Please upload valid files.`
        );
        setFiles([]);
        return;
      }
    }

    if (files.length === 0) {
      renderCenteredAlert(`Please upload a valid file.`);
      setFiles([]);
      return;
    }
    // If all files are valid and within size limits, process them
    setDuringUpload(true);
    setFiles(files);
    const prevLength = fileInfo.length;
    try {
      await Promise.all(
        files.map((file, index) => handleUploadFile(file, prevLength + index))
      );
      const newNumberOfUploadedFiles = files.length;
      setNumberOfUploadedFilesRecently(newNumberOfUploadedFiles);
      props.setEditChain(true);
      props.wasUploaded();
      setDuringUpload(false);
    } catch (error) {
      renderCenteredAlert('Error in uploading one or more files.');
    } finally {
      setDuringUpload(false);
    }
  };

  return (
    <div className={styles.container}>
      {duringUpload && (
        <div className={styles.backdrop}>
          <div className={styles.uploadProgressValue}>
            <CircularProgress size={70} style={{ color: 'white' }} />{' '}
            <div className={styles.uploadProgressText}>Uploading...</div>
          </div>
        </div>
      )}
      <div className={styles.title}>
        Files should be PDF, EPUB ,RTF ,DOC ,DOCX or TXT
      </div>
      <div className={styles.selectfiles}>
        <div className={styles.dragNdrop}>
          <DropzoneComponent
            wasUploaded={props.wasUploaded}
            setEditChain={props.setEditChain}
            setDuringUpload={setDuringUpload}
          />
        </div>
        <div className={styles.orLine}>
          <div className={styles.Line} />
          <div className={styles.orLineText}>OR</div>
          <div className={styles.Line} />
        </div>
        <div className={styles.uploadb}>
          <input
            type="file"
            ref={inputRef}
            style={{ display: 'none' }}
            multiple
            onChange={handleFileChange}
          />
          <button className={styles.uploadbutton} onClick={handleButtonClick}>
            <div className={styles.uploadbuttonText}>Select Files</div>
          </button>
        </div>

        <div />
      </div>
    </div>
  );
};

export default Upload;
