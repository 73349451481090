import styles from './BotM.module.css';
// import Typing from './Typing';
const   BotM = (props) => {


    // if (props.message === '') {
    //     <Typing />
    // }
    return (
        <div className={styles.userMContainer}>
            {/* <div className={styles.userMBody}> */}
                <div className={styles.userMText}>
                  {props.message}
                </div>
                {/* <div className={styles.userMTime}>
                    12:00
                </div> */}
            {/* </div> */}
        </div>
    )

}

export default BotM;
