
///new version of static compare
import React from 'react';
import styles from './CoosenCard.module.css';


const CoosenCard = (props) => {
  const { index, genre, removeGenre } = props;
  
   return (
    <div className={styles.cardContainer}>
      <div className={styles.genre}>{genre}</div>
      {/* <button className={styles.deleteButton} onClick={() => removeGenre(genre)}> */}
      {/* <div className={styles.deletexmark} >&#88;</div> */}
      {/* </button> */}
    </div>
  );
};

export default CoosenCard;



//old version of dynamic compare




// import React from 'react';
// import styles from './CoosenCard.module.css';


// const CoosenCard = (props) => {
//   const { index, genre, removeGenre } = props;
  
//   //create mock data
// // const removeGenrel=(genre) =>{
// // const a=1
// // }
// //   const mockData = 
// //     { index: 1, genre: "Dramagggggggggggg"  ,removeGenre :{removeGenrel}};

// //   const { index, genre, removeGenre } = mockData;
//   return (
//     <div className={styles.cardContainer}>
//       <div className={styles.genre}>{genre}</div>
//       <button className={styles.deleteButton} onClick={() => removeGenre(genre)}>
//       <div className={styles.deletexmark} >&#88;</div>
//       </button>
//     </div>
//   );
// };

// export default CoosenCard;
