
import styles from './DisplayMenuscript.module.css';
import Contens from './Contens';
import Manuscript from './manuscriptText/Manuscript';
import ButtonMenuscript from './ButtonMenuscript';
import ApproveManuscript from '../R&A/ApproveManuscript';
import RejectManuscript from '../R&A/RejectManuscript';
import { useState } from 'react';
import { useRecoilState } from "recoil";
import { selectedChapterAtom} from "../../../state"; // Assuming you have a selectedChapterAtom to track the selected chapter index



const DisplayMenuscript = (props) => {

    const [selectedChapter, setSelectedChapter] = useRecoilState(selectedChapterAtom);


    return (
        <div className={styles.containr}>
            <div className={styles.menu_conten}>
                <div className={styles.content}>
                    <Contens selectedChapter={selectedChapter} setSelectedChapter={setSelectedChapter} />
                </div>
                <div className={styles.manuscript}>
                    <Manuscript selectedChapter={selectedChapter} />
                </div>
            </div>
            <div className={styles.button}>
                <ButtonMenuscript onReject={props.onReject} onApprove={props.onApprove}userRejected={props.userRejected} setUserRejected={props.setUserRejected}  userApproved={props.userApproved} setUserApproved={props.setUserApproved}/>
            </div>
        </div>
    );

}

export default DisplayMenuscript;
