

import styles from './Contens.module.css';
import { useRecoilState } from "recoil";
import { chapterNamesAtom, selectedChapterAtom } from "../../../state"; // Assuming you have a selectedChapterAtom to track the selected chapter index

const Contens = (props) => {
  const [chapterNames, setChapterNames] = useRecoilState(chapterNamesAtom);

  const handleChapterClick = (index) => {
    props.setSelectedChapter(index);
  };

  return (
    <div className={styles.container}>
      {/* <div className={styles.title}></div> */}
      {chapterNames.map((content, index) => {
        const contentTitle = content;
        const contentLink = `#chapter-${index + 1}`;
        return (
          <div key={index} className={styles.chaptercontainer}>
            <a
              className={`${styles.linkChapter} ${
                props.selectedChapter === index ? styles.active : ""
              }`}
              href={contentLink}
              onClick={() => handleChapterClick(index)}
            >
              {contentTitle}
            </a>
          </div>
        );
      })}
    </div>
  );
};

export default Contens;
