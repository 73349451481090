



// new version of static compare
import React, { useEffect, useState } from "react";
import CoosenCard from "./CoosenCard";
import styles from './Genre.module.css';
import { useRecoilState, useRecoilValue } from 'recoil';
import { genresState } from '../../../state';

//create options of genres
const options = [
  { value: 'Thriller', label: 'Thriller' },
  { value: 'Mystery', label: 'Mystery' },
  { value: 'Historical Fiction', label: 'Historical Fiction' },
  { value: 'Romance', label: 'Romance' },
  { value: 'Horror', label: 'Horror' },
  { value: 'Fantasy', label: 'Fantasy' },
  { value: 'Sci-Fi', label: 'Sci-Fi' },
  { value: 'Memoir', label: 'Memoir' },
  { value: 'YA', label: 'YA' },
  // { value: 'Literary Fiction', label: 'Literary Fiction' },
  
 ];

const Genre = (props) => {
  const [selectedGenre, setSelectedGenre] = useState("");
  const [genres, setGenres] = useRecoilState(genresState);
  
  
  const handleAddGenre = () => {
    if (selectedGenre.trim() !== '') {
      setGenres(prevGenres => {
        return [selectedGenre ,...prevGenres];
      });
      setSelectedGenre("");    }
  };

  useEffect(() => {
    if (selectedGenre.trim() !== "") {
      handleAddGenre();
    }
  }, [selectedGenre]);

  const handleRemoveGenre = genre => {
    setGenres(prevGenres => {
      return prevGenres.filter(item => item !== genre);
    });
  };


  return (
    <div className={styles.genreContainer}>
      <div className={styles.constPart}>
        <div className={styles.title}>
          <div className={styles.titleText}>
            Genre ({genres.length})</div>
        </div>
        {/* <div className={styles.dropdown}>
          <select
            value={selectedGenre}
            onChange={(e) => setSelectedGenre(e.target.value)}
          >
            <option value="" className={styles.addNew}>+Add New </option>
            {options.map((option) => (
              <option
                key={option.value}
                value={option.value}
                disabled={genres.includes(option.value)}
                className={styles.addNew}>{option.label}
              </option>
            ))}
          </select>
        </div> */}
      </div>
      <div className={styles.genres}>
        {genres.map((genre, index) => (
          <div className={styles.genre}>
            <CoosenCard
              key={index}
              genre={genre}
              removeGenre={handleRemoveGenre}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Genre;




////// old version of dynamic compare
// import React, { useEffect, useState } from "react";
// import CoosenCard from "./CoosenCard";
// import styles from './Genre.module.css';
// import { useRecoilState, useRecoilValue } from 'recoil';
// import { genresState } from '../../../state';

// //create options of genres
// const options = [
//   { value: 'Thriller', label: 'Thriller' },
//   { value: 'Mystery', label: 'Mystery' },
//   { value: 'Historical Fiction', label: 'Historical Fiction' },
//   { value: 'Romance', label: 'Romance' },
//   { value: 'Horror', label: 'Horror' },
//   { value: 'Fantasy', label: 'Fantasy' },
//   { value: 'Sci-Fi', label: 'Sci-Fi' },
//   { value: 'Memoir', label: 'Memoir' },
//   { value: 'YA', label: 'YA' },
//   // { value: 'Literary Fiction', label: 'Literary Fiction' },
  
//  ];

// const Genre = (props) => {
//   const [selectedGenre, setSelectedGenre] = useState("");
//   const [genres, setGenres] = useRecoilState(genresState);
  
  
//   const handleAddGenre = () => {
//     if (selectedGenre.trim() !== '') {
//       setGenres(prevGenres => {
//         return [selectedGenre ,...prevGenres];
//       });
//       setSelectedGenre("");    }
//   };

//   useEffect(() => {
//     if (selectedGenre.trim() !== "") {
//       handleAddGenre();
//     }
//   }, [selectedGenre]);

//   const handleRemoveGenre = genre => {
//     setGenres(prevGenres => {
//       return prevGenres.filter(item => item !== genre);
//     });
//   };


//   return (
//     <div className={styles.genreContainer}>
//       <div className={styles.constPart}>
//         <div className={styles.title}>
//           <div className={styles.titleText}>
//             Genre ({genres.length})</div>
//         </div>
//         <div className={styles.dropdown}>
//           <select
//             value={selectedGenre}
//             onChange={(e) => setSelectedGenre(e.target.value)}
//           >
//             <option value="" className={styles.addNew}>+Add New </option>
//             {options.map((option) => (
//               <option
//                 key={option.value}
//                 value={option.value}
//                 disabled={genres.includes(option.value)}
//                 className={styles.addNew}>{option.label}
//               </option>
//             ))}
//           </select>
//         </div>
//       </div>
//       <div className={styles.genres}>
//         {genres.map((genre, index) => (
//           <div className={styles.genre}>
//             <CoosenCard
//               key={index}
//               genre={genre}
//               removeGenre={handleRemoveGenre}
//             />
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default Genre;



