import * as React from 'react';
import {
  DataGrid,
  GridToolbar,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useRecoilState } from 'recoil';
import {
  pendingProcessingState,
  goToApproveState,
  ingestDetailsState,
  goToRejectState,
  authorInfoState,
  books_id_dwonloadState,
  accessTokenState,
  startPollingState,
  userInfoState,
  headerColumnsTranslationState,
  columnsState,
  loadingmanuscriptState,
  most_similar_bookAtom,
  scoreDataAtom,
  manuscriptInfoListAtom,
  clickedManuscriptAtom,
  currentManuscriptAtom,
} from '../../state';
import Status from './Status';
import dots from '../../icons/vuesax/outline/UniononlyDots.svg';
import styles from './TableManuscripts.module.css';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import { styled } from '@mui/material/styles';
import eye from '../../icons/Icon/eye.svg';
import useApiUtils from '../../useApiUtils';
import { useState, useEffect } from 'react';
import { stepButtonClasses } from '@mui/material';
import expand from '../../icons/sendIcon.svg';
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import ScoreDisplay from '../../UI/ScoreDisplay';
import { renderCenteredAlert } from '../CenteredAlert';
import './TableManuscripts.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';

import { format } from 'date-fns';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1ceaa0',
    },
  },
});

const TableManuscripts = (props) => {
  const [manuscriptInfoList, setManuscriptInfoList] = useRecoilState(
    manuscriptInfoListAtom
  );
  const [clickedManuscript, setClickedManuscript] = useRecoilState(
    clickedManuscriptAtom
  );
  const [currentManuscript, setCurrentManuscript] = useRecoilState(
    currentManuscriptAtom
  );
  const [userInfo, setUserInfo] = useRecoilState(userInfoState); // Update the path
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [pendingProcessing, setPendingProcessing] = useRecoilState(
    pendingProcessingState
  );

  const [rowCount, setRowCount] = React.useState(
    manuscriptInfoList?.length ?? 0
  );
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [dwonload, setDwonload] = useRecoilState(books_id_dwonloadState);

  const [most_similar_book, setMost_similar_book] = useRecoilState(
    most_similar_bookAtom
  );
  const [scoreData, setScoreData] = useRecoilState(scoreDataAtom);
  const [loadingmanuscript, setLoadingmanuscript] = useRecoilState(
    loadingmanuscriptState
  );
  const [columns, setColumns] = useRecoilState(columnsState);
  const [headerColumnsTranslation, setHeaderColumnsTranslation] =
    useRecoilState(headerColumnsTranslationState);
  const [authorInfo, setAuthorInfo] = useRecoilState(authorInfoState);

  const [ispolling, setIspolling] = useRecoilState(startPollingState);

  const [selectedStatus, setSelectedStatus] = useState(null);

  const [goToApprove, setGoToApprove] = useRecoilState(goToApproveState);
  const [goToReject, setGoToReject] = useRecoilState(goToRejectState);

  const compareAlphabeticScores = (a, b) => {
    // Handle null, undefined, or '-' in alphabetic_score
    if (!a || !a.alphabetic_score || a.alphabetic_score === '-') {
      return 1;
    }
    if (!b || !b.alphabetic_score || b.alphabetic_score === '-') {
      return -1;
    }

    // Compare the alphabetic_score strings
    return a.alphabetic_score.localeCompare(b.alphabetic_score);
  };

  //       // Function to generate the columns array dynamically based on the header_columns_translation data
  const generateColumns = () => {
    setColumns([]);
    const newColumns = [];

    // Get the entries from the header_columns_translation object
    const entries = Object.entries(headerColumnsTranslation);
    // Sort the entries based on the number provided in the API response
    entries.sort((a, b) => a[0] - b[0]);

    // Iterate through the header_columns_translation object
    entries.forEach(([key, column]) => {
      if (column.show) {
        // Create a new column object

        const newColumn = {
          field: column.column_field,
          headerName: column.column_name,
          width: 150,
          // flex :1,          // Add other properties specific to your columns here, such as `align`, `type`, etc.
          //devivde the width equally
        };
        // Check if the column should have a custom renderCell function
        if (column.column_field === 'manuscript_name') {
          newColumn.renderCell = (params) => (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
              }}
            >
              <img
                src={params.row.image}
                alt="Manuscript"
                style={{ marginRight: 8, width: '50px', height: '50px' }} // Adjust the width and height as per your preference
              />
              <span>{params.value}</span>
            </div>
          );
          newColumn.flex = 1;
          newColumn.minWidth = 250;
        } else if (column.column_field === 'owner') {
          newColumn.renderCell = (params) => {
            const ownerImage = params.row.owner_image; // Check if owner_image exists

            if (ownerImage) {
              // If owner_image exists, render an image avatar
              return <Avatar alt={params.value} src={ownerImage} />;
            } else {
              if (userInfo && userInfo.user_to_name_owner) {
                const firstLetter = getInitials(params.value ?? 'Unassigned');
                // const colorCode = generateColorCode(params.value); // Generate color code based on owner's name
                const colorCode = generateColorCode(firstLetter); // Generate color code based on owner's name

                const avatarStyle = {
                  backgroundColor: colorCode,
                  width: '30px',
                  height: '30px',
                  fontSize: '14px',
                };

                const selectStyle = {
                  cursor: 'arrow',
                  border: 'none', // Remove the border
                  outline: 'none', // Remove the focus outline
                  width: '100%', // Set the width to fill the cell (excluding the avatar)
                  marginLeft: 8, // Add margin to the left of the select box
                  background: 'transparent', // Make the background transparent
                  fontSize: 'inherit', // Inherit font size from the parent
                  color: 'inherit', // Inherit color from the parent
                  textAlign: 'left', // Align text to the left
                  padding: 0, // Remove padding
                };

                const selectedOwner = params.row.owner || ''; // Current owner value

                return (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {selectedOwner !== 'Unassigned' && (
                      <Avatar style={avatarStyle}>{firstLetter}</Avatar>
                    )}

                    <select
                      value={selectedOwner}
                      onChange={(event) => handleOwnerSelect(params, event)}
                      style={selectStyle}
                    >
                      {!userInfo && <option value="unassign">unassign</option>}
                      {userInfo.user_to_name_owner.length === 0 && (
                        <option value="unassign">unassign</option>
                      )}
                      {userInfo &&
                        userInfo.user_to_name_owner &&
                        userInfo.user_to_name_owner.length > 0 && (
                          <>
                            <option disabled={true} value={params.row.owner}>
                              {params.row.owner}
                            </option>
                            {userInfo.user_to_name_owner.map((user) => (
                              <option
                                key={user.user_id}
                                value={user.name}
                                data-user-id={user.user_id} // Store user.user_id as data attribute
                              >
                                {user.name}
                              </option>
                            ))}
                          </>
                        )}
                    </select>
                  </div>
                );
              } else {
                // Handle the case where userInfo or userInfo.user_to_name_owner is null
                return <div>No users available</div>;
              }
            }
          };
          // newColumn.flex = 1;
        } else if (column.column_field === 'status') {
          newColumn.renderCell = (params) => {
            const { processing_status, status, status_option } = params.row;

            return (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {/* show spinner if processing_status is pending or processing */}
                {processing_status !== 'pending' &&
                processing_status !== 'processing' ? (
                  <>
                    <div
                      style={{
                        width: 1,
                        height: 25,
                        borderRadius: '50%',
                        background: params.row.background,
                      }}
                    >
                      <select
                        value={''}
                        onChange={(event) => handleStatusSelect(params, event)}
                        style={{
                          cursor: 'arrow',
                          border: 'none',
                          outline: 'none',
                          zIndex: 100,
                          height: '30px',
                          width: '150px',
                          background: 'transparent',
                          fontSize: 'inherit',
                          color: 'inherit',
                          textAlign: 'left',
                          padding: 0,
                        }}
                      >
                        <option value=""> </option>
                        {status_option.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>
                    <Status
                      value={params.value}
                      tc_star={params.row.tc_star}
                      id="tc_star"
                    />
                  </>
                ) : (
                  <div className={styles.processing}>
                    <CircularProgress size={15} color="primary" />
                    <span>processing</span>
                  </div>
                )}
              </div>
            );
          };
        } else if (column.column_field === 'overall_rating') {
          newColumns.sortable = true;
          newColumn.align = 'center';
          newColumn.justifyContent = 'center';
          newColumn.width = 60;

          newColumn.sortComparator = compareAlphabeticScores;
          newColumn.renderCell = (params) => {
            // Check if params.row or params.row.overall_rating is undefined or null
            if (!params.row || !params.row.overall_rating) {
              return <div></div>; // Return an empty div for blank cell
            }

            const { alphabetic_score, numeric_score } =
              params.row.overall_rating;
            return (
              <ScoreDisplay
                letterScore={alphabetic_score}
                numberScore={numeric_score}
              />
            );
          };
        } else if (column.column_field === 'date_uploaded') {
          newColumn.type = 'date'; // Set the column type to "date"

          newColumn.valueGetter = (params) => {
            const value = params.value;
            return value ? new Date(value) : null;
          };

          // Set the valueFormatter only if the column exists in the data
          newColumn.valueFormatter = (params) => {
            const dateString = params.value;
            if (dateString) {
              const date = format(new Date(dateString), 'MMM dd, yyyy');
              return date;
            }
            return null; // Return null if the value is null or undefined
          };

          newColumn.sortComparator = (v1, v2, param1, param2) => {
            return new Date(v1).getTime() - new Date(v2).getTime();
          };
        } else if (column.column_field === 'ingest_details') {
          newColumns.sortable = false;
          newColumns.filterable = false;
          newColumns.hideable = false;
          newColumn.align = 'center';
          newColumn.justifyContent = 'center';
          newColumn.width = 110;
          newColumn.renderCell = (params) => {
            if (params.row.ingest_details) {
              return (
                <div
                  className={styles.containereye}
                  onClick={() => handleEmailIngest(params)}
                >
                  <img src={eye} alt="eye" className={styles.containereyeimg} />
                  {/* < FaRegEye  size="1.5em" className={styles.containereyeimg}/>   */}
                </div>
              );
            } else {
              return (
                <div className={styles.containereye}>
                  <FaRegEyeSlash size="1.5em" color="gray" />
                </div>
              );
            }
          };
        }

        newColumns.push(newColumn);
      }
    });

    // Add the actions column
    newColumns.push({
      field: 'Actions',
      headerName: '',
      width: 10,
      align: 'center',
      // sortable: false,
      filterable: false,
      // hideable: false,
      renderCell: (params) => {
        return (
          <BasicMenu
            handleDelete={props.handleDelete}
            handleEdit={props.handleEdit}
            params={params}
          />
        );
      },
    });

    // Update the state with the dynamically generated columns
    setColumns(newColumns);
  };

  // Utility function to get the month number from abbreviation
  function getMonthNumber(abbreviation) {
    const months = {
      Jan: 0,
      Feb: 1,
      Mar: 2,
      Apr: 3,
      May: 4,
      Jun: 5,
      Jul: 6,
      Aug: 7,
      Sep: 8,
      Oct: 9,
      Nov: 10,
      Dec: 11,
    };
    return months[abbreviation];
  }


  const [ingestionDetails, setIngestionDetails] = useRecoilState(ingestDetailsState);  


  const handleEmailIngest = (param) => {
    setIngestionDetails(null)
    const bookId = param.row.book_id;
    get_manuscript_ingestion_details(bookId);
    props.openEmail(true);
  };
  const handleStatusSelect = (params, event) => {
    const selectedStatuss = event.target.value;
    const selectedBookId = params.row.book_id;


    if (selectedStatuss !== 'Decline' && selectedStatuss !== 'Approve') {
      //set pendging processing to 01
      setPendingProcessing(pendingProcessing + 1);
      change_book_status(selectedBookId, selectedStatuss);
    } else {
      setAuthorInfo(null);
      setScoreData([]);
      setMost_similar_book([]);

      props.selectManuscript(true);
      setClickedManuscript(params.row);
      setCurrentManuscript({ bookId: params.row.book_id });
      get_book_scores(params.row.book_id);
      get_most_similar_books(params.row.book_id);
      get_autor_info(params.row.book_id);
      if (selectedStatuss === 'Decline') {
        setGoToReject(true);
      } else if (selectedStatuss === 'Approve') {
        setGoToApprove(true);
      }
    }

    // Call the API to update the status
  };

  function getInitials(name) {
    if (!name) {
      return 'NA';
    }
    const words = name.split(' ');
    if (words.length === 1) {
      const firstLetter = words[0].charAt(0).toUpperCase();
      // const secondLetter = words[0].charAt(1).toLowerCase();
      // return `${firstLetter}${secondLetter}`;
      return `${firstLetter}`;
    } else {
      const firstWordInitial = words[0].charAt(0).toUpperCase();
      const secondWordInitial = words[1].charAt(0).toUpperCase();
      return `${firstWordInitial}${secondWordInitial}`;
    }
  }

  function generateColorCode(name) {
    // Simple hash calculation based on the name
    let hash = 0;
    for (let i = 0; i < name.length; i++) {
      hash += name.charCodeAt(i);
    }

    // Generate a color code using the hash value
    const hue = hash % 360; // Use the hash value modulo 360 to determine the hue
    const saturation = 70; // Set a fixed saturation value
    const lightness = 60; // Set a fixed lightness value

    // Convert HSL values to a CSS color code
    const colorCode = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    return colorCode;
  }

  const handleRowClick = (params, event) => {
    const isActionsColumn =
      event.target.classList.contains(styles.dotsButton) ||
      event.target.classList.contains(styles.dots);
    const isSelectElement = event.target.tagName === 'SELECT';
    const isButtonElement =
      event.target.classList.contains(styles.containereye) ||
      event.target.classList.contains(styles.containereyeimg);
    if (!isActionsColumn && !isSelectElement && !isButtonElement) {
      if (
        params.row.status === 'Upload Failed' ||
        params.row.processing_status === 'pending' ||
        params.row.processing_status === 'processing'
      ) {
        return;
      }
      setAuthorInfo(null);
      setScoreData([]);
      setMost_similar_book([]);

      props.selectManuscript(true);
      setClickedManuscript(params.row);
      setCurrentManuscript({ bookId: params.row.book_id });
      get_book_scores(params.row.book_id);
      get_most_similar_books(params.row.book_id);
      get_autor_info(params.row.book_id);
      // initialgetBookChapter(params.row.book_id);
      // getbookgenrekeywords(params.row.book_id);
      // get_conversation_history(params.row.book_id);
    } else {
      setClickedManuscript(params.row);
      setCurrentManuscript({ bookId: params.row.book_id });
    }
  };

  ////////////about book init///////////////

  React.useEffect(() => {
    setRowCount(manuscriptInfoList?.length ?? 0);
  }, [manuscriptInfoList]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer style={{ justifyContent: 'flex-end' }}>
        <GridToolbarColumnsButton style={{ color: '#3D4E53' }} />
        <GridToolbarFilterButton style={{ color: '#3D4E53' }} />
        <GridToolbarDensitySelector style={{ color: '#3D4E53' }} />
        {/* <GridToolbarExport style={{ color: '#3D4E53' }}/> */}
        {/* <Button onClick={() => handleSelectAllRows(true)}>Select All Rows</Button>
        <Button onClick={() => handleSelectAllRows(false)}>Unselect All Rows</Button> */}
      </GridToolbarContainer>
    );
  }

  React.useEffect(() => {
    generateColumns();
  }, [headerColumnsTranslation]);

  const {
    getManuscriptsInfo,
    initialgetBookChapter,
    get_book_scores,
    getbookgenrekeywords,
    get_most_similar_books,
    AssignUser,
    get_autor_info,
    get_conversation_history,
    change_book_status,
    get_manuscript_ingestion_details,
    // Add other functions from the hook if needed
  } = useApiUtils();

  React.useEffect(() => {
    if (dwonload.length === 0) {
      handleCancelSelection();
    }
  }, [dwonload]);

  const handleRowSelectionModelChange = (newSelection) => {
    setRowSelectionModel(newSelection);
    const selectedBookIds = newSelection.map((selectedIndex) => {
      const selectedRow = manuscriptInfoList[selectedIndex];
      return selectedRow.book_id;
    });
    setDwonload(selectedBookIds);
    // Perform additional actions with the selected rows if needed
  };

  const [selectedUserId, setSelectedUserId] = useState(null);
  const [paramBookId, setParamBookId] = useState(null);
  const [selectOwnerEvent, setSelectOwnerEvent] = useState(false);

  // Function to handle owner selection and update manuscriptInfoList
  const handleOwnerSelect = (params, event) => {
    const selectedUserIdd =
      event.target.selectedOptions[0].getAttribute('data-user-id');
    setSelectedUserId(selectedUserIdd);
    setParamBookId(params.row.book_id);
    setSelectOwnerEvent(true);
  };

  useEffect(() => {
    const assignUsersAndFetchInfo = async () => {
      if (dwonload.length === 0) {
        // Assign user directly when only one book is selected
        if (paramBookId && selectedUserId) {
          // only if the book in manuscriptInfoList has no  status is not "Upload Failed"
          if (
            manuscriptInfoList.find(
              (manuscriptInfo) => manuscriptInfo.book_id === paramBookId
            ).status === 'Upload Failed'
          ) {
            const manuscript = manuscriptInfoList.find(
              (manuscriptInfo) => manuscriptInfo.book_id === paramBookId
            );
            renderCenteredAlert(
              "Assignment not possible: The selected book '" +
                manuscript.manuscript_name +
                "' has encountered an upload failure and cannot be processed further at this moment. Please check the submission or contact support for assistance."
            );
          } else {
            await AssignUser(paramBookId, selectedUserId);
          }
        }
      } else {
        // Assign users sequentially when multiple books are selected
        for (const bookId of dwonload) {
          // only if the book in manuscriptInfoList has no  status is not "Upload Failed"
          if (
            manuscriptInfoList.find(
              (manuscriptInfo) => manuscriptInfo.book_id === bookId
            ).status === 'Upload Failed'
          ) {
            const manuscript = manuscriptInfoList.find(
              (manuscriptInfo) => manuscriptInfo.book_id === bookId
            );
            renderCenteredAlert(
              "Assignment not possible: The selected book '" +
                manuscript.manuscript_name +
                "' has encountered an upload failure and cannot be processed further at this moment. Please check the submission or contact support for assistance."
            );
            return;
          } else {
            await AssignUser(bookId, selectedUserId);
          }
        }
      }

      // After all assignments are done, fetch manuscript info
      getManuscriptsInfo(false);
      setDwonload([]);
      handleCancelSelection();
    };

    if (selectOwnerEvent) {
      assignUsersAndFetchInfo();
      setSelectOwnerEvent(false);
    }
  }, [selectOwnerEvent]);

  const handleCancelSelection = () => {
    setRowSelectionModel([]);
  };

  const [sortModel, setSortModel] = useState([
    { field: 'date_uploaded', sort: 'desc' },
  ]);

  useEffect(() => {
    if (
      columns.length > 0 &&
      columns.some((column) => column.field === 'date_uploaded')
    ) {
      setSortModel([{ field: 'date_uploaded', sort: 'desc' }]);
    }
  }, [columns]);

  const getRowClassName = (params) => {
    return params.row.status === 'Upload Failed' ||
      params.row.processing_status === 'pending' ||
      params.row.processing_status === 'processing'
      ? 'Mui-disabled-row'
      : '';
    //console.log(params);
  };

  // Function to determine if a row is selectable
  const isRowSelectable = (params) => {
    if (
      params.row.processing_status === 'pending' ||
      params.row.processing_status === 'processing'
    )
      return false;
    else return true;
  };

  return (
    <div style={{ height: '100%', width: '100%', minHeight: '60vh' }}>
      {/* <Button onClick={handleCancelSelection}>Cancel Selection</Button> */}
      <ThemeProvider theme={theme}>
        <DataGrid
          rows={manuscriptInfoList}
          columns={columns}
          sortModel={sortModel}
          onSortModelChange={setSortModel}
          initialState={{
            // sorting: {
            //   sortModel: [{ field: 'date_uploaded', sort: 'desc' }],
            // },
            pagination: {
              paginationModel: { page: 0, pageSize: 50 },
            },
          }}
          pageSizeOptions={[10, 25, 50, 100]}
          checkboxSelection
          isRowSelectable={isRowSelectable}
          disableRowSelectionOnClick
          onRowClick={(params, event) => handleRowClick(params, event)}
          slots={{
            toolbar: CustomToolbar,
            noRowsOverlay: CustomNoRowsOverlay,
          }}
          // onRowSelectionModelChange={handleSelectionChange}
          onRowSelectionModelChange={handleRowSelectionModelChange}
          rowSelectionModel={rowSelectionModel}
          getRowClassName={getRowClassName}
          sx={{
            '.MuiDataGrid-columnHeaderTitle': {
              fontWeight: 'bold !important',
            },

            '.MuiDataGrid-cell': {
              cursor: 'pointer',
            },
          }}
        />
      </ThemeProvider>
    </div>
  );
};

export default TableManuscripts;

function BasicMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    props.handleEdit(true);
    handleClose();
  };

  const handleDelete = () => {
    props.handleDelete(true);
    handleClose();
  };

  return (
    <div style={{ height: '100%' }}>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        className={styles.dotsButton}
        style={{ height: '100%' }}
      >
        <img
          className={styles.dots}
          style={{ backgroundColor: props.params.row.background }}
          src={dots}
          alt="dots"
        />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        PaperProps={{
          style: {
            boxShadow: 'none',
            background: '#FFFFFF',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.18)',
            borderRadius: '8px',
            border: '1px solid #E0E0E0',
            backgroundColor: '#f1f2f5',
          },
        }}
      >
        {/* the edit will be only if the props.params.status is not Upload Failed  */}
        {props.params.row.status !== 'Upload Failed' && (
          <MenuItem onClick={handleEdit}>Edit</MenuItem>
        )}
        {props.params.row.status !== 'Upload Failed' && <hr />}
        <MenuItem onClick={handleDelete}> Delete</MenuItem>
      </Menu>
    </div>
  );
}

const StyledGridOverlay = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  '& .ant-empty-img-1': {
    fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
  },
  '& .ant-empty-img-2': {
    fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
  },
  '& .ant-empty-img-3': {
    fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
  },
  '& .ant-empty-img-4': {
    fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
  },
  '& .ant-empty-img-5': {
    fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
    fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
  },
}));

function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>Nothing to review</Box>
    </StyledGridOverlay>
  );
}
