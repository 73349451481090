import styles from './ApproveAD.module.css';
import { useState, useEffect, useRef } from 'react';


const ApproveAD = (props) => {

    const approveboxRef = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (approveboxRef.current && !approveboxRef.current.contains(event.target)) {
                props.return(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [approveboxRef]);


    const handelbutton = () => {
        props.return(false);
    }

    const handelbuttonApproved = () => {
        props.handleApprove(true);
        props.return(false);
    }

    return (
        <div className={styles.backdrop}>
            <div className={styles.container} ref={approveboxRef}>
                <div className={styles.TopSection}>
                    <div className={styles.titleTEXT}>
                    Send auto decline emails?                    </div>
                    <div className={styles.buttonOut}>
                        <button className={styles.xbutton} onClick={handelbutton} >X</button>
                    </div>
                </div>
                <div className={styles.MiddleSectionall}>
                {/* Would you like us to send decline mails to auto-declined submissions that have not yet been notified? */}
                Are you sure you want to send decline mails to auto-declined submissions? Note this does not affect submissions that have already been auto-declined.
                </div>
                <div className={styles.ButtonSection} >
                    <div className={styles.YesButtonContainer} onClick={handelbutton}>
                        <div className={styles.noButton}>
                            No                    </div>
                    </div>
                    <div className={styles.NoButtonContainer} onClick={handelbuttonApproved}>
                        <div className={styles.yesButton}>
                            Yes
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default ApproveAD;