import styles from './Status.module.css';
import { FaStar } from 'react-icons/fa';
const Status = (props) => {
  const getStatusCellClassName = () => {
    switch (props.value) {
      case 'Notified':
        return styles.notified;
      case 'First Approval':
        return styles.forwarded;
      case 'Under Review':
        return styles.send;
      case 'Action Required':
        return styles.actionRequired;
      case 'Declined':
        return styles.rejected;
      case 'Auto-declined':
        return styles.rejected;
      case 'Approved':
        return styles.approved;
      case 'Approved for Editorial':
        return styles.approvedEditorial;
      case 'Approved for Acquisition':
        return styles.approvedAcquisition;
      case 'Acquired':
        return styles.acquired;
      case 'Upload Failed':
        return styles.uploadFailed;
      default:
        return '';
    }
  };

  const getStatusText = () => {
    switch (props.value) {
      case 'Notified':
        return styles.notifiedText;
      case 'First Approval':
        return styles.forwardedText;
      case 'Under Review':
        return styles.sendText;
      case 'Action Required':
        return styles.actionRequiredText;
      case 'Declined':
        return styles.rejectedText;
      case 'Auto-declined':
        return styles.rejectedText;
      case 'Approved':
        return styles.approvedText;
      case 'Approved for Editorial':
        return styles.approvedEditorialTextText;
      case 'Approved for Acquisition':
        return styles.approvedAcquisitionText;
      case 'Acquired':
        return styles.acquiredText;
      case 'Upload Failed':
        return styles.uploadFailedText;
      default:
        return '';
    }
  };

  const statuText = getStatusText();
  const statusCellClassName = getStatusCellClassName();

  // const displayValue = props.value === 'rejected' ? 'Rejected' : props.value;

  return (
    <div className={statusCellClassName}>
      <span className={statuText}>
        {/* {displayValue} */}
        {props.value}
      </span>
      {props.tc_star && (
        <FaStar style={{ color: 'gray', fontSize: '1em' }} id="star" />
      )}
    </div>
  );
};

export default Status;
