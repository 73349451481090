import React, { useState } from 'react';
// import { useHistory } from 'react-router-dom';
import styles from './SetPreferences.module.css';
import UncoverLeft from './UncoverLeft';
import Line from '../..//icons/AUTH/signin/Line 2.svg';
import Line2 from '../..//icons/AUTH/signin/Line 3.svg';

import men from '../..//icons/AUTH/signin/men.svg';
import step2 from '../..//icons/AUTH/signin/curentstep2.svg';
import { Link } from 'react-router-dom';

import step3 from '../..//icons/AUTH/signin/step3.svg';
import back from '../..//icons/back.svg';
import GenreCard from './GenreCard';
import CategoriesCard from './CategoriesCard';
import tooltipIcon from '../../icons/icons8-info (1).svg';

import Thriller from '../../icons/genreImage/MysteryThriller.svg';
import Mystery from '../../icons/genreImage/MysteryThriller.svg';
import HistoricalFiction from '../../icons/genreImage/HistoricalFiction.svg';
import Romance from '../../icons/genreImage/Romance.svg';
import Horror from '../../icons/genreImage/Horror.svg';
import Fantasy from '../../icons/genreImage/ScifiFantasy.svg';
import YA from '../../icons/genreImage/genre/ya.svg';
import Memoir from '../../icons/genreImage/Memoir.svg';
import SciFi from '../../icons/genreImage/genre/genre/architype/Rebirth.svg';
import LiteraryFiction from '../../icons/genreImage/LiteraryFiction.svg';
import defaultbook from '../../icons/genreImage/defaultbook.svg';
import useApiUtils from '../../useApiUtils';

import { useRecoilState } from 'recoil';
import { userInfoState } from '../../state';
import CircularProgress from '@mui/material/CircularProgress';

import { useNavigate } from 'react-router-dom';

import { pink } from '@mui/material/colors';
import Checkbox from '@mui/material/Checkbox';
import expend_score from '../../icons/expend_score copy 2.svg';
import despend from '../../icons/despend copy 2.svg';

import { renderCenteredAlert } from '../CenteredAlert';

import Business from '../../icons/genreImage/genre/Business.svg';
import Educational from '../../icons/genreImage/genre/Educational.svg';
import fantasy from '../../icons/genreImage/genre/fantasy.svg';
import GamesGaming from '../../icons/genreImage/genre/image 15.svg';
import historic from '../../icons/genreImage/genre/historic.svg';
import History from '../../icons/genreImage/genre/architype/Rags to Riches.svg';
import HomeLifestyle from '../../icons/genreImage/genre/Home & Lifestyle.svg';
import horror from '../../icons/genreImage/genre/horror.svg';
import Memoir1 from '../../icons/genreImage/genre/genre/genre/genre/fiction.svg';
import NatureClimate from '../../icons/genreImage/genre/Nature & Climate.svg';
import Paranormalromance from '../../icons/genreImage/genre/genre/adult.svg';
import Parenting from '../../icons/genreImage/genre/Parenting.svg';
import PeriodRomance from '../../icons/genreImage/genre/Period Romance.svg';
import PopularScience from '../../icons/genreImage/genre/Popular Science.svg';
import Religion from '../../icons/genreImage/genre/Religion.svg';
import romance from '../../icons/genreImage/genre/romance.svg';
import Selfhelp from '../../icons/genreImage/genre/Self help.svg';
import SportsHobbies from '../../icons/genreImage/genre/Sport & Hobbies.svg';
import Travel from '../../icons/genreImage/genre/Travel.svg';
import mysteryCrime from '../../icons/genreImage/Mystery:Crime.svg';
import ContemporaryRomance from '../../icons/genreImage/genre/genre/romance.svg';
import Academic from '../../icons/genreImage/genre/Academic.svg';
import generalfiction from '../../icons/genreImage/genre/genre/architype/Comedy.svg';
import generalnonfiction from '../../icons/genreImage/genre/genre/architype/Tragedy.svg';
import Collectibles from '../../icons/genreImage/genre/genre/Collectibles.svg';
//create  a list of genre, n genre name and image
const fictionGenreList = [
  {
    genre: 'Mystery/Crime',
    genre_image: mysteryCrime,
    selected: false,
  },
  {
    genre: 'Thriller',
    genre_image: Thriller,
    selected: false,
  },
  {
    genre: 'Historical Fiction',
    genre_image: HistoricalFiction,
    selected: false,
  },
  {
    genre: 'Contemporary Romance',
    genre_image: ContemporaryRomance,
    selected: false,
  },
  {
    genre: 'Paranormal Romance',
    genre_image: Paranormalromance,
    selected: false,
  },
  {
    genre: 'Period Romance',
    genre_image: PeriodRomance,
    selected: false,
  },
  {
    genre: 'Horror',
    genre_image: Horror,
    selected: false,
  },
  {
    genre: 'Sci-Fi',
    genre_image: SciFi,
    selected: false,
  },
  {
    genre: 'Fantasy',
    genre_image: Fantasy,
    selected: false,
  },
  {
    genre: 'YA',
    genre_image: YA,
    selected: false,
  },
  {
    genre: 'General Fiction',
    genre_image: generalfiction,
    selected: false,
  },
];

// create a list of non-fiction genres
const nonFictionGenreList = [
  {
    genre: 'Memoir',
    selected: false,
    genre_image: Memoir1,
  },
  {
    genre: 'Academic',
    selected: false,
    genre_image: Academic,
  },
  {
    genre: 'Collectables',
    selected: false,
    genre_image: Collectibles,
  },
  {
    genre: 'Sports & hobbies',
    selected: false,
    genre_image: SportsHobbies,
  },
  {
    genre: 'Self Help',
    selected: false,
    genre_image: Selfhelp,
  },
  {
    genre: 'Games',
    selected: false,
    genre_image: GamesGaming,
  },
  {
    genre: 'Religion',
    selected: false,
    genre_image: Religion,
  },
  {
    genre: 'Home & Lifestyle',
    selected: false,
    genre_image: HomeLifestyle,
  },
  {
    genre: 'Travel',
    selected: false,
    genre_image: Travel,
  },
  {
    genre: 'Popular Science',
    selected: false,
    genre_image: PopularScience,
  },
  {
    genre: 'Parenting',
    selected: false,
    genre_image: Parenting,
  },
  {
    genre: 'Educational',
    selected: false,
    genre_image: Educational,
  },
  {
    genre: 'History',
    selected: false,
    genre_image: History,
  },
  {
    genre: 'Business',
    selected: false,
    genre_image: Business,
  },
  {
    genre: 'Nature & Climate',
    selected: false,
    genre_image: NatureClimate,
  },
  {
    genre: 'General non-fiction',
    selected: false,
    genre_image: generalnonfiction,
  },
];

const SetPreferences = () => {
  const [saveButtonState, setSaveButtonState] = useState('normal'); // 'normal', 'saving', 'success', 'failure'

  // const history = useHistory();
  const [selectedFictionGenres, setSelectedFictionGenres] =
    useState(fictionGenreList);
  const [selectedNonFictionGenres, setSelectedNonFictionGenres] =
    useState(nonFictionGenreList);

  const [relevantPreferences, setRelevantPreferences] = useState(true);
  const [promisingPreferences, setPromisingPreferences] = useState(true);
  const [eligiblePreferences, setEligiblePreferences] = useState(true);

  const [expandState, setExpandState] = useState({
    Eligible: true,
    Relevant: true,
    Promising: true,
  });

  // Toggle expand/collapse
  const toggleExpand = (category) => {
    setExpandState((prevState) => ({
      ...prevState,
      [category]: !prevState[category],
    }));
  };

  const [selectedEligible, setSelectedEligible] = useState(true);
  const [selectedEligibleList, setSelectedEligibleList] = useState([
    { value: 'Genre', selected: true },
    { value: 'Word Count', selected: true },
  ]);
  const [selectedRelevant, setSelectedRelevant] = useState(true);
  const [selectedRelevantList, setSelectedRelevantList] = useState([
    { value: 'Spelling', selected: true },
    { value: 'Cliches', selected: true },
    { value: 'Passive Voice', selected: true },
    // the new list
    { value: 'Authors Voice', selected: true },
    { value: 'Writing Style', selected: true },
    { value: 'Pacing', selected: true },
    { value: 'Dialogue Quality', selected: true },
    { value: 'Grammar and Mechanics', selected: true },
  ]);
  const [selectedPromising, setSelectedPromising] = useState(true);
  const [selectedPromisingList, setSelectedPromisingList] = useState([
    { value: 'Plot Structure', selected: true },
    { value: 'Plot Beats', selected: true },
    // the new list
    { value: 'Compelling topic', selected: true },
    // minimal_paraphrasing , minimal_permissions
    { value: 'Minimal Paraphrasing', selected: true },
    { value: 'Minimal Permissions', selected: true },
    { value: 'Setting and World Building', selected: true },
    { value: 'World Building', selected: true },
    { value: 'Character Development', selected: true },
    { value: 'Marketability', selected: true },
    { value: 'Concept Originality', selected: true },
  ]);
  const [wordCountMin, setWordCountMin] = useState(0);
  const [wordCountMax, setWordCountMax] = useState(10000000);

  const [manuscriptReviewContents, setManuscriptReviewContents] = useState([
    { value: 'Synopsis', selected: true },
    { value: 'Author Bio', selected: true },
    { value: 'Author Analysis', selected: true },
    { value: 'Comparatives', selected: true },
    { value: 'Manual Review', selected: true },
    { value: 'Editing', selected: true },
  ]);

  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useRecoilState(userInfoState); // Update the path

  const [keywordsList, setKeywordsList] = useState([]);
  const handlesetKeywordsList = (keyword, addOrRemove) => {
    if (!addOrRemove) {
      if (keywordsList.length >= 5) {
        // Corrected line
        renderCenteredAlert('You can select a maximum of 5 keywords');
        return;
      }
      const newKeywords = [...keywordsList, keyword];
      setKeywordsList(newKeywords);
    } else {
      const newKeywords = keywordsList.filter((c) => c !== keyword); // Corrected line
      setKeywordsList(newKeywords); // Corrected line
    }
  };

  const {
    setPreferanses,
    // Add other functions from the hook if needed
  } = useApiUtils();

  const handleBackClick = () => {
    navigate('/signup');
  };

  const handleNextClick = async () => {
    if (saveButtonState === 'loading') {
      return;
    }
    //if non of the genre is selected, show error message
    if (
      selectedFictionGenres.filter((genre) => genre.selected).length === 0 &&
      selectedNonFictionGenres.filter((genre) => genre.selected).length === 0
    ) {
      renderCenteredAlert('Please select at least one genre');
      return;
    }

    if (wordCountMin === '') {
      renderCenteredAlert('Please enter minimum word count ');
      return;
    }
    if (wordCountMax === '') {
      renderCenteredAlert('Please enter maximum word count ');
      return;
    }
    if (!isValidWordCount()) {
      renderCenteredAlert(
        'Invalid word count range. Please check the minimum and maximum values. The minimum value should be greater than 0 and the maximum value should be less than 10000000 and greater than the minimum value.'
      );
      return;
    }
    setSaveButtonState('loading');

    // Construct the data to be sent to the backend API
    const dataToSend = {
      user_id: userInfo.user_id,
      genres: selectedFictionGenres
        .filter((genre) => genre.selected)
        .map((genre) => genre.genre),
      non_fiction_genres: selectedNonFictionGenres
        .filter((genre) => genre.selected)
        .map((genre) => genre.genre),
      genre_score: selectedEligibleList.find(
        (category) => category.value === 'Genre'
      ).selected,
      word_count_score: selectedEligibleList.find(
        (category) => category.value === 'Word Count'
      ).selected,
      spelling_score: selectedRelevantList.find(
        (category) => category.value === 'Spelling'
      ).selected,
      cliches_score: selectedRelevantList.find(
        (category) => category.value === 'Cliches'
      ).selected,
      passive_voice: selectedRelevantList.find(
        (category) => category.value === 'Passive Voice'
      ).selected,
      authors_voice: selectedRelevantList.find(
        (category) => category.value === 'Authors Voice'
      ).selected,
      writing_style_score: selectedRelevantList.find(
        (category) => category.value === 'Writing Style'
      ).selected,
      pacing_score: selectedRelevantList.find(
        (category) => category.value === 'Pacing'
      ).selected,
      dialogue_quality: selectedRelevantList.find(
        (category) => category.value === 'Dialogue Quality'
      ).selected,
      grammar_and_mechanics: selectedRelevantList.find(
        (category) => category.value === 'Grammar and Mechanics'
      ).selected,
      ps_score: selectedPromisingList.find(
        (category) => category.value === 'Plot Structure'
      ).selected,
      pb_score: selectedPromisingList.find(
        (category) => category.value === 'Plot Beats'
      ).selected,
      setting_and_world_building: selectedPromisingList.find(
        (category) => category.value === 'Setting and World Building'
      ).selected,
      compelling_topic: selectedPromisingList.find(
        (category) => category.value === 'Compelling topic'
      ).selected,
      minimal_paraphrasing: selectedPromisingList.find(
        (category) => category.value === 'Minimal Paraphrasing'
      ).selected,
      minimal_permissions: selectedPromisingList.find(
        (category) => category.value === 'Minimal Permissions'
      ).selected,
      world_building: selectedPromisingList.find(
        (category) => category.value === 'World Building'
      ).selected,
      character_development: selectedPromisingList.find(
        (category) => category.value === 'Character Development'
      ).selected,
      marketability: selectedPromisingList.find(
        (category) => category.value === 'Marketability'
      ).selected,
      concept_originality: selectedPromisingList.find(
        (category) => category.value === 'Concept Originality'
      ).selected,
      wordcount_min: wordCountMin,
      wordcount_max: wordCountMax,
      themes_campaign: keywordsList,
    };

    // Call the API
    try {
      await setPreferanses(dataToSend, false);
      navigate('/add-user'); // Change this to your desired redirect path
      setSaveButtonState('normal');
    } catch (error) {
      setSaveButtonState('normal');
      renderCenteredAlert('Failed to save preferences');
    }
  };

  const handleNonFictionGenreFictionSelection = (genre) => {
    const newGenres = selectedNonFictionGenres.map((g) => {
      if (g.genre === genre) {
        return {
          ...g,
          selected: !g.selected,
        };
      }
      return g;
    });
    setSelectedNonFictionGenres(newGenres);
  };

  const handleGenreFictionSelection = (genre) => {
    const newGenres = selectedFictionGenres.map((g) => {
      if (g.genre === genre) {
        return {
          ...g,
          selected: !g.selected,
        };
      }
      return g;
    });
    setSelectedFictionGenres(newGenres);
  };

  // Function to handle selection changes

  const isValidWordCount = () => {
    const min = parseInt(wordCountMin, 10);
    const max = parseInt(wordCountMax, 10);
    return min >= 0 && max <= 10000000 && max >= min;
  };

  const handleRelevantSelectioncategoty = (category) => {};

  const handlePromisingSelectioncategoty = (category) => {};

  const getButtonText = () => {
    switch (saveButtonState) {
      case 'saving':
        return 'Saving...';
      case 'success':
        return 'Saved Successfully';
      case 'failure':
        return 'Failed to Save';
      case 'loading':
        return <CircularProgress size={15} color="inherit" />;
      default:
        return 'Next';
    }
  };

  const handleEligiblePreferences = () => {
    // if this is a check so update allselectedEligibleList to true
    //  alert that this category cannot be unchecked
    renderCenteredAlert(
      'The Eligibility  is essential and cannot be deselected'
    );
    return;
    if (!eligiblePreferences) {
      const newSelectedEligibleList = [
        { value: 'Genre', selected: true },
        { value: 'Word Count', selected: true },
      ];
      setSelectedEligibleList(newSelectedEligibleList);
    } else {
      const newSelectedEligibleList = [
        { value: 'Genre', selected: false },
        { value: 'Word Count', selected: false },
      ];
      setSelectedEligibleList(newSelectedEligibleList);
    }
    setEligiblePreferences(!eligiblePreferences);
  };

  const handleRelevantPreferences = () => {
    if (!relevantPreferences) {
      const newSelectedRelevantList = [
        { value: 'Spelling', selected: true },
        { value: 'Cliches', selected: true },
        { value: 'Passive Voice', selected: true },
        // the new list
        { value: 'Authors Voice', selected: true },
        { value: 'Writing Style', selected: true },
        { value: 'Pacing', selected: true },
        { value: 'Dialogue Quality', selected: true },
        { value: 'Grammar and Mechanics', selected: true },
      ];
      setSelectedRelevantList(newSelectedRelevantList);
    } else {
      const newSelectedRelevantList = [
        { value: 'Spelling', selected: false },
        { value: 'Cliches', selected: false },
        { value: 'Passive Voice', selected: false },
        // the new list
        { value: 'Authors Voice', selected: false },
        { value: 'Writing Style', selected: false },
        { value: 'Pacing', selected: false },
        { value: 'Dialogue Quality', selected: false },
        { value: 'Grammar and Mechanics', selected: false },
      ];
      setSelectedRelevantList(newSelectedRelevantList);
    }
    setRelevantPreferences(!relevantPreferences);
  };

  const handlePromisingPreferences = () => {
    if (!promisingPreferences) {
      const newSelectedPromisingList = [
        { value: 'Plot Structure', selected: true },
        { value: 'Plot Beats', selected: true },
        // the new list
        { value: 'Setting and World Building', selected: true },
        { value: 'World Building', selected: true },
        { value: 'Character Development', selected: true },
        { value: 'Marketability', selected: true },
        { value: 'Concept Originality', selected: true },
        { value: 'Minimal Paraphrasing', selected: true },
        { value: 'Minimal Permissions', selected: true },
        { value: 'Compelling topic', selected: true },
      ];
      setSelectedPromisingList(newSelectedPromisingList);
    } else {
      const newSelectedPromisingList = [
        { value: 'Plot Structure', selected: false },
        { value: 'Plot Beats', selected: false },
        // the new list
        { value: 'Setting and World Building', selected: false },
        { value: 'World Building', selected: false },
        { value: 'Character Development', selected: false },
        { value: 'Marketability', selected: false },
        { value: 'Concept Originality', selected: false },
        { value: 'Minimal Paraphrasing', selected: false },
        { value: 'Minimal Permissions', selected: false },
        { value: 'Compelling topic', selected: false },
      ];
      setSelectedPromisingList(newSelectedPromisingList);
    }
    setPromisingPreferences(!promisingPreferences);
  };

  return (
    <div className={styles.container}>
      <UncoverLeft />
      <div className={styles.logosignincontainer}>
        <div className={styles.signcontainer}>
          <div className={styles.hedder}>
            <div className={styles.steps}>
              <div className={styles.step1}>
                <div className={styles.step1circle}>
                  <img src={men} className={styles.men} />
                </div>
              </div>
              <div className={styles.line1}>
                <div className={styles.line1img}>
                  <img src={Line2} alt="line" />
                </div>
              </div>
              <div className={styles.step1}>
                <div className={styles.step2circle}>
                  <img src={step2} className={styles.menstep2} />
                </div>
                <div className={styles.step1text}>
                  step 2/3
                  <div className={styles.step1text1}>Set Your Preferences</div>
                </div>
              </div>
              <div className={styles.line1}>
                <div className={styles.line1img}>
                  <img src={Line} alt="line" />
                </div>
              </div>
              <div className={styles.step3}>
                <div className={styles.step3circle}>
                  <img src={step3} className={styles.menstep3} />
                </div>
              </div>
            </div>

            <div className={styles.signup}>
              <div className={styles.backtologin}>Already have an account?</div>
              <Link to="/" className={styles.linkto}>
                Log in
              </Link>
            </div>
          </div>
          <div className={styles.body}>
            <div className={styles.titlelogo}>
              <div className={styles.loginparttitle}>
                Automatic Matching Guidelines{' '}
              </div>
            </div>
            <div className={styles.loginpartsubtitle}>
              <strong>Need help? </strong> You can reach us anytime via{' '}
              <a className={styles.linktomail} href="mailto:info@storywise.ai">
                info@storywise.ai
              </a>
            </div>
            <div className={styles.maindescription}>
              Prioritize your submissions. Manuscripts that do not meet your
              requirements will be downgraded. You can still review them later.
            </div>
            <div className={styles.cat_title}>
              Auto Decline if Manuscripts are not:
            </div>
            <div className={styles.Qualification_Categories}>
              <div className={styles.categoriesCard1}>
                <Checkbox
                  checked={eligiblePreferences}
                  id="Eligible"
                  onChange={handleEligiblePreferences}
                  sx={{
                    color: '#1ceaa0',
                    '&.Mui-checked': {
                      color: '#1ceaa0',
                    },
                  }}
                />

                <div className={styles.cat_title}>Eligible</div>
                <div onClick={() => toggleExpand('Eligible')} id="expandE">
                  <img
                    src={expandState.Eligible ? despend : expend_score}
                    alt="expand"
                    className={styles.expand}
                  />
                </div>
              </div>
              {expandState.Eligible && (
                <div className={styles.category_content}>
                  <div className={styles.titlesword}>Word count range</div>
                  <div className={styles.description}>
                    Manuscripts with a word count not in this range will
                    automatically be declined.
                  </div>

                  <div className={styles['word-count-container']}>
                    <input
                      type="number"
                      value={wordCountMin}
                      onChange={(e) => setWordCountMin(e.target.value)}
                      placeholder="Min"
                      className={styles.input}
                    />
                    <div className={styles.rangeIndicator}>-</div>
                    <input
                      type="number"
                      value={wordCountMax}
                      onChange={(e) => setWordCountMax(e.target.value)}
                      placeholder="Max"
                      className={styles.input}
                    />
                  </div>

                  <div className={styles.titles}>Select Your Wishlist</div>
                  <div className={styles.description}>
                    Choose the book genres that you are interested in
                    publishing:{' '}
                  </div>
                  <div className={styles.titles}>Fiction</div>
                  <div className={styles.genres_container}>
                    {selectedFictionGenres.map((genre, index) => (
                      <GenreCard
                        key={index}
                        genre={genre.genre}
                        genre_image={genre.genre_image}
                        selected={genre.selected}
                        onClick={handleGenreFictionSelection}
                      />
                    ))}
                  </div>
                  <div className={styles.titles}>Non-Fiction</div>
                  <div className={styles.genres_container}>
                    {selectedNonFictionGenres.map((genre, index) => (
                      <GenreCard
                        key={index}
                        genre={genre.genre}
                        genre_image={genre.genre_image}
                        selected={genre.selected}
                        onClick={handleNonFictionGenreFictionSelection}
                      />
                    ))}
                  </div>
                  <div className={styles.titles}>Campaign Themes</div>
                  <div className={styles.description}>
                    For submissions not aligning with the preferred genres, do
                    not automatically decline. Instead, Instead, if they include
                    any of the keywords listed below, highlight them with a star
                    for further review.
                  </div>
                  <div className={styles.listofcard}>
                    {keywordsList?.map((category, index) => (
                      <CategoriesCard
                        key={index}
                        categoty={category}
                        selected={true}
                        handleKeywordsList={handlesetKeywordsList}
                        thisKeywordsRemovable={true}
                        greenButtons={true}
                      />
                    ))}
                    <CategoriesCard
                      category={'Add+'}
                      selected={false}
                      handleKeywordsList={handlesetKeywordsList}
                      addCard={true}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className={styles.Qualification_Categories}>
              <div className={styles.categoriesCard1}>
                <Checkbox
                  id="Relevant"
                  checked={relevantPreferences}
                  onChange={handleRelevantPreferences}
                  inputProps={{ 'aria-label': 'controlled' }}
                  sx={{
                    color: '#1ceaa0',
                    '&.Mui-checked': {
                      color: '#1ceaa0',
                    },
                  }}
                />

                <div className={styles.cat_title}>Relevant</div>
                <div onClick={() => toggleExpand('Relevant')} id="expandR">
                  <img
                    src={expandState.Relevant ? despend : expend_score}
                    alt="expand"
                    className={styles.expand}
                  />
                </div>
              </div>
              {expandState.Relevant && (
                <div className={styles.category_content}>
                  <div className={styles.description}>
                    If checked, books that do not sufficiently meet the
                    following criteria will be auto-declined:{' '}
                  </div>
                  {/* list of relevant  */}
                  <div className={styles.listofcard}>
                    {selectedRelevantList.map((category, index) => (
                      <CategoriesCard
                        key={index}
                        categoty={category.value}
                        selected={category.selected}
                        onClick={handleRelevantSelectioncategoty}
                        greenButtons={true}
                      />
                    ))}
                  </div>
                </div>
              )}
            </div>
            <div className={styles.Qualification_Categories}>
              <div className={styles.categoriesCard1}>
                <Checkbox
                  id="Promising"
                  checked={promisingPreferences}
                  onChange={handlePromisingPreferences}
                  sx={{
                    color: '#1ceaa0',
                    '&.Mui-checked': {
                      color: '#1ceaa0',
                    },
                  }}
                />

                <div className={styles.cat_title}>Promising</div>
                <div onClick={() => toggleExpand('Promising')} id="expandP">
                  <img
                    src={expandState.Promising ? despend : expend_score}
                    alt="expand"
                    className={styles.expand}
                  />{' '}
                </div>
              </div>
              {expandState.Promising && (
                <div className={styles.category_content}>
                  <div className={styles.description}>
                    If checked, books that do not sufficiently meet the
                    following criteria will be auto-declined:{' '}
                  </div>
                  {/* list of promising  */}
                  <div className={styles.listofcard}>
                    {selectedPromisingList.map((category, index) => (
                      <CategoriesCard
                        key={index}
                        categoty={category.value}
                        selected={category.selected}
                        onClick={handlePromisingSelectioncategoty}
                        greenButtons={true}
                      />
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className={styles.fotter}>
            <div className={styles.pagenumber}>
              <div className={styles.pagenumberfirst}>2</div>
              /3
            </div>
            <div className={styles.buttoncontainer}>
              <div className={styles.buttoncontainerback}>
                <img src={back} className={styles.back} />
                <div
                  type="submit"
                  className={styles.buttonBack}
                  onClick={handleBackClick}
                >
                  <div className={styles.textbuttonBack}>Back</div>
                </div>
              </div>
              <div
                type="submit"
                className={`${styles.button}
                         ${
                           saveButtonState === 'success'
                             ? styles.successButton
                             : saveButtonState === 'failure'
                             ? styles.failureButton
                             : ''
                         }`}
                onClick={handleNextClick}
              >
                <div className={styles.textbutton}>{getButtonText()}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetPreferences;
