import styles from './SubmissionNotes.module.css';
import Note from './Note';
import { useRecoilState } from 'recoil';
import {
  userRejected_ApprovedNoteState,
  notes_highlightsState,
  notesAtom,
  chapterNamesAtom,
  selectedChapterAtom,
} from '../../../state';
import React, { useState, useEffect } from 'react';
import useApiUtils from '../../../useApiUtils';

const SubmissionNotes = (props) => {
  const [notes_highlights, setNotes_highlights] = useRecoilState(
    notes_highlightsState
  );
  const [totalNotes, setTotalNotes] = useState(0); // Initialize totalNotes with 0
  const [noteApproveReject, setNoteApproveReject] = useRecoilState(
    userRejected_ApprovedNoteState
  );

  const {
    getManuscriptsInfo,
    initialgetBookChapter,
    get_book_scores,
    getbookgenrekeywords,
    sendRejection,
    sendApprove,
    save_user_book_notes_highlights_delete_notes,

    // Add other functions from the hook if needed
  } = useApiUtils();

  const handleDelete = (id_pos, chapter_name, type, note_id, bookid) => {
    const deleteArgument = {
      type: type, // You can adjust this based on your data structure
      note_id: note_id, // You may need to adjust this based on your data structure
    };

    if (type === 'reject' || type === 'approved') {
      setNoteApproveReject((prevNotes) => {
        const updatedNotes = prevNotes.filter((note) => {
          return note.id_pos !== id_pos;
        });
        save_user_book_notes_highlights_delete_notes(deleteArgument, bookid);
        return updatedNotes;
      });
    } else {
      setNotes_highlights((prevNotes) => {
        const updatedNotesHighlights = [...prevNotes];

        for (let i = 0; i < updatedNotesHighlights.length; i++) {
          if (updatedNotesHighlights[i].chapter_name === chapter_name) {
            if (Array.isArray(updatedNotesHighlights[i].notesAndHighlights)) {
              // Filter out the note with the given id_pos
              const filteredNotesAndHighlights = updatedNotesHighlights[
                i
              ].notesAndHighlights.filter((item) => {
                return item.id_pos !== id_pos;
              });
              // Create a new object with the updated property
              updatedNotesHighlights[i] = {
                ...updatedNotesHighlights[i],
                notesAndHighlights: filteredNotesAndHighlights,
              };
            }

            break;
          }
        }
        save_user_book_notes_highlights_delete_notes(deleteArgument, bookid);
        return updatedNotesHighlights;
      });
    }
  };

  useEffect(() => {
    // Calculate the sum of elements in all notesAndHighlights
    const newTotalNotes = notes_highlights.reduce(
      (total, chapter) => total + chapter.notesAndHighlights.length,
      0
    );
    setTotalNotes(newTotalNotes);
  }, [notes_highlights]);

  const scrollToElement = (id) => {
    // Find the corresponding element in the manuscript by its id
    const element = document.getElementById(id);

    if (element) {
      // Scroll to the element smoothly
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className={styles.FrameRootRoot}>
      <div className={styles.SubmissionNotes}>
        Submission Notes And Highlights ({totalNotes})
      </div>
      <div className={styles.Frame1}>
        <div className={styles.Notes}>
          {noteApproveReject.map((note, index) => (
            <div className={styles.note} key={index}>
              <Note
                handleDelete={handleDelete}
                id={note.id_pos}
                name={note.owner_name}
                date={note.date}
                textNote={note.text}
                chapter={note.chapter}
                type={note.type}
                note_id={note.note_id}
                // type={"reject"}
              />
            </div>
          ))}
          {/* {notes_highlights.map((chapter, chapterIndex) => (
          <div key={chapterIndex} className={styles.chapternote}>
            {[...chapter.notesAndHighlights].reverse().map((note, index) => (
              <div className={styles.note} key={index}  onClick={() => scrollToElement(note.id_pos)}>
                <Note
                  handleDelete={handleDelete}
                  id={note.id_pos}
                  name={note.user}
                  date={note.date}
                  textNote={note.text}
                  chapter={note.chapter}
                  type={note.type}
                  note_id={note.note_id}

                />
              </div>
            ))}
          </div>
        ))} */}
          {notes_highlights.map((chapter, chapterIndex) =>
            chapter.notesAndHighlights.length > 0 ? (
              <div key={chapterIndex} className={styles.chapternote}>
                {[...chapter.notesAndHighlights]
                  .reverse()
                  .map((note, index) => (
                    <div
                      className={styles.note}
                      key={index}
                      onClick={() => scrollToElement(note.id_pos)}
                    >
                      <Note
                        handleDelete={handleDelete}
                        id={note.id_pos}
                        name={note.owner_name}
                        date={note.date}
                        textNote={note.text}
                        chapter={note.chapter}
                        type={note.type}
                        note_id={note.note_id}
                        image={note.owner_img}
                      />
                    </div>
                  ))}
              </div>
            ) : null
          )}
        </div>
      </div>
    </div>
  );
};
export default SubmissionNotes;
