

const EditPage = () => {


    return (
        <div>
            <h1>Edit Page</h1>
        </div>
    )
}   

export default EditPage;
