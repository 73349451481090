import React from 'react';
import ReactDOM from 'react-dom';
import { Box, Alert, Snackbar } from '@mui/material';

// Function to render the custom centered alert using Snackbar
export const renderCenteredAlert = (message) => {
  // Create a container element for the Snackbar
  const snackbarContainer = document.createElement('div');
  document.body.appendChild(snackbarContainer);

  // Function to close the Snackbar
  const handleClose = () => {
    ReactDOM.unmountComponentAtNode(snackbarContainer);
    snackbarContainer.remove();
  };

  // Render the Snackbar
  ReactDOM.render(
    <Snackbar
      open={true}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert severity="error" onClose={handleClose}>
        {message}
      </Alert>
    </Snackbar>,
    snackbarContainer
  );
};

// Function to render the custom centered alert using Snackbar
export const renderCenteredInfo = (message) => {
  // Create a container element for the Snackbar
  const snackbarContainer = document.createElement('div');
  document.body.appendChild(snackbarContainer);

  // Function to close the Snackbar
  const handleClose = () => {
    ReactDOM.unmountComponentAtNode(snackbarContainer);
    snackbarContainer.remove();
  };

  // Render the Snackbar
  ReactDOM.render(
    <Snackbar
      open={true}
      autoHideDuration={3000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert severity="success" onClose={handleClose}>
        {message}
      </Alert>
    </Snackbar>,
    snackbarContainer
  );
};
