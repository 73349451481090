import { useState } from 'react';
import styles from './CategoriesCard.module.css';
import Checkbox from '@mui/material/Checkbox';

const CategoriesCard = (props) => {
  const [input, setInput] = useState('');

  const handleClick = () => {
    props.onClick(props.categoty);
  };

  const removeKeywords = () => {
    props.handleKeywordsList(props.categoty, true);
  };

  const handleChange = (e) => {
    setInput(e.target.value);
  };

  const handleEnterPress = (e) => {
    if (e.key === 'Enter' && input.trim() !== '') {
      props.handleKeywordsList(input, false); // Call the function and pass the input value
      setInput(''); // Clear the input after adding
    }
  };

  const [isHovered, setIsHovered] = useState(false); // Step 1: Add state to track hover

  // Hover handlers
  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  if (props.addCard) {
    return (
      <div
        className={`${styles['categoty-card-input']} ${
          props.selected ? styles['selected'] : ''
        }`}
        onMouseEnter={handleMouseEnter} // Add onMouseEnter
        onMouseLeave={handleMouseLeave} // Add onMouseLeave
      >
        <input
          type="text"
          placeholder="Add+"
          value={input}
          onChange={handleChange}
          onKeyPress={handleEnterPress} // Handle Enter key press
          className={styles.input}
          style={{ backgroundColor: 'inherit' }}
        />
        {isHovered && props.title && (
          <div className={styles.tooltip1} style={{ display: 'block' }}>
            <div className={styles.tooltipText}>{props.title}</div>
          </div>
        )}
      </div>
    );
  } else if (props.thisKeywordsRemovable) {
    return (
      <div
        className={`${styles['categoty-card']} ${
          props.selected ? styles['selected'] : ''
        } ${props.showhand ? styles['show-hand'] : ''}
        ${props.greenButtons ? styles['greenButtons'] : ''}
        `}
        onMouseEnter={handleMouseEnter} // Add onMouseEnter
        onMouseLeave={handleMouseLeave} // Add onMouseLeave
      >
        <div className={styles['categoty-name']}>{props.categoty}</div>
        {props.thisKeywordsRemovable && (
          <button className={styles.deleteButton} onClick={removeKeywords}>
            <div className={styles.deleteMark}>&#88;</div>
          </button>
        )}

        {isHovered && props.title && (
          <div className={styles.tooltip1} style={{ display: 'block' }}>
            <div className={styles.tooltipText}>{props.title}</div>
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div
        className={`${styles['categoty-card']} ${
          props.selected ? styles['selected'] : ''
        } ${props.clickable ? styles['clickable'] : ''}
        ${props.selected && props.greenButtons ? styles['greenButtons'] : ''}
        ${props.showhand ? styles['show-hand'] : ''}`}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter} // Add onMouseEnter
        onMouseLeave={handleMouseLeave} // Add onMouseLeave
      >
        {props.hasCheckbox ? (
          <div
            className={`${styles['checkbox-container']} ${styles['categoty-name']}`}
          >
            <label className={styles.customCheckbox}>
              <Checkbox
                checked={props.selected}
                id={props.categoty}
                sx={{
                  padding: '0px',
                  paddingRight: '5px',
                }}
                disabled={true}
              />
              <span className={styles.checkmark}></span>
              {props.categoty === 'Authors Voice'
                ? "Author's Voice"
                : props.categoty}
            </label>
          </div>
        ) : (
          <div className={styles['categoty-name']}>
            {props.categoty === 'Authors Voice'
              ? "Author's Voice"
              : props.categoty}
          </div>
        )}
        {isHovered && props.title && (
          <div className={styles.tooltip1} style={{ display: 'block' }}>
            <div className={styles.tooltipText}>{props.title}</div>
          </div>
        )}
      </div>
    );
  }
};

export default CategoriesCard;
