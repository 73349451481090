
import Avatar from '@mui/material/Avatar';

const AvatarCostom = (props) => {
    function generateColorCode(name) {
      // Simple hash calculation based on the name
      let hash = 0;
      for (let i = 0; i < name.length; i++) {
        hash += name.charCodeAt(i);
      }
  
      // Generate a color code using the hash value
      const hue = hash % 360; // Use the hash value modulo 360 to determine the hue
      const saturation = 70; // Set a fixed saturation value
      const lightness = 60; // Set a fixed lightness value
  
      // Convert HSL values to a CSS color code
      const colorCode = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
      return colorCode;
    }
  
    function getInitials(name) {
        if (!name){
            return 'NA';
        }
      const words = name.split(' ');
      if (words.length === 1) {
        const firstLetter = words[0].charAt(0).toUpperCase();
        // const secondLetter = words[0].charAt(1).toLowerCase();
        // return `${firstLetter}${secondLetter}`;
        return `${firstLetter}`;
      } else  {
        const firstWordInitial = words[0].charAt(0).toUpperCase();
        const secondWordInitial = words[1].charAt(0).toUpperCase();
        return `${firstWordInitial}${secondWordInitial}`;
      } 
    }
  
    const initials = getInitials(props.name ?? 'Unassigned');
  
    const colorCode = generateColorCode(initials);
  
    const avatarStyle = {
      backgroundColor:props.color || colorCode,
      width: props.size ||'30px',
      height: props.size ||'30px',
      fontSize: props.font ||'14px',
    };
  
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Avatar style={avatarStyle}>{initials}</Avatar>
      </div>
    );
  };
  
  export default AvatarCostom;
  