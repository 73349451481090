import React, { useState } from 'react';
import { CognitoUserPool, CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import { Link, useLocation } from 'react-router-dom';
import styles from './ResetPassword.module.css';
import UncoverLeft from './UncoverLeft';
import { useNavigate } from 'react-router-dom';

import CircularProgress from '@mui/material/CircularProgress';



const REACT_APP_BUILD_ENV = process.env.REACT_APP_BUILD_ENV || "REACT_APP_BUILD_ENV-build_env";
const REACT_APP_API_ID = process.env.REACT_APP_API_ID || "REACT_APP_BUILD_ENV2-build_env";

const poolData  = {
  UserPoolId: process.env.REACT_APP_USER_POOL_ID || "USER_POOL_ID-build_env" ,
  ClientId: process.env.REACT_APP_CLIENT_ID_USER_POOL || "CLIENT_ID_USER_POOL-build_env",
};

const userPool = new CognitoUserPool(poolData);

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [resetCode, setResetCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isValidPassword, setIsValidPassword] = useState(true); // Initialize as true since there is no invalid password initially
  const [saveButtonState, setSaveButtonState] = useState('normal'); // 'normal', 'saving', 'success', 'failure'

  const location = useLocation();
  const navigate = useNavigate();

  const handleResetPassword = (event) => {
    event.preventDefault();
    if(saveButtonState === 'loading'){
      return;
  }

    if (!confirmPassword || !newPassword || !resetCode) {
      setErrorMessage('Please fill all the fields');
      return;
    }
    if (!isValidPassword) {
      setErrorMessage('Please enter a valid password');
      return;
    }
    if (newPassword !== confirmPassword) {
      setErrorMessage('Passwords do not match');

      return;
    }

    setSaveButtonState('loading');

    const queryParams = new URLSearchParams(location.search);
    const email = queryParams.get('email');
    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: userPool,
    });


    cognitoUser.confirmPassword(resetCode, newPassword, {
      onSuccess: () => {
        setSaveButtonState('normal');

        //navigate to login page after password reset add email query parameter
        navigate(`/?email=${encodeURIComponent(email)}`);
        // navigate('/login');
      },
      onFailure: (error) => {
        setSaveButtonState('normal');

        console.error('Error resetting password', error);
        setErrorMessage(error.message);
      },
    });


  };


  const handlePasswordChange = (event) => {
    const value = event.target.value;
    // Check the password validity and set the isValidPassword state accordingly
    const isValid = value.length >= 8 && /\d/.test(value) && /[a-z]/.test(value) && /[A-Z]/.test(value) && /[^A-Za-z0-9]/.test(value);
    setIsValidPassword(isValid);
    setNewPassword(value); // Update the password state as well
  };





  const handleResendCode = () => {
    const queryParams = new URLSearchParams(location.search);
    const email = queryParams.get('email');
    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: userPool,
    });

    cognitoUser.resendConfirmationCode((error, result) => {
      if (error) {
        // Handle the error, e.g., display an error message
        console.error('Error resending confirmation code:', error);
      } else {
        // Confirmation code resent successfully
        // You can provide feedback to the user here if needed
      }
    });
  };


  const getButtonText = () => {
    switch (saveButtonState) {
        case 'saving':
            return 'Saving...';
        case 'success':
            return 'Saved Successfully';
        case 'failure':
            return 'Failed to Save';
        case 'loading':
            return <CircularProgress size={15} color="inherit" />;    
        default:
            return ' Reset Password';
    }
};

  return (
    <div className={styles.container}>
      <UncoverLeft />
      <div className={styles.logocontainer}>
        <div className={styles.logincontainer}>
          <div className={styles.linktosignup}>
            <div className={styles.beforelink}>
              Back to
            </div>
            <Link to="/" className={styles.linkto}> Login</Link>
          </div>
          <div className={styles.loginpart}>
            <div className={styles.loginparttitle}>Reset Password</div>
            <div className={styles.loginpartsubtitle}> Use more than 8 characters with letters (both uppercase and lowercase), numbers, and symbols, and includes no obvious personal information or common words.
              {/* <div className={styles.resendCodeContainer}>
                <p>Didn't receive the code?</p>
                <button onClick={handleResendCode} className={styles.resendButton}>
                  Resend Confirmation Code
                </button>
              </div> */}
            </div>
            <div className={styles.formpart} onSubmit={handleResetPassword}>
              <label className={styles.label}>Reset Code</label>
              <input
                type="text"
                value={resetCode}
                onChange={(e) => setResetCode(e.target.value)}
                required
                className={styles.input}
                placeholder='Enter Reset Code received in email'
                autoComplete="off"
              />
              <label className={styles.label}>New Password</label>
              <input
                type="password"
                value={newPassword}
                onChange={handlePasswordChange}
                required
                className={`${styles.input} ${isValidPassword ? styles.valid : styles.invalid}`}
                placeholder='New Password'
                autoComplete="new-password" // Add this line to disable autofill for the new password field

              />
              <label className={styles.label}>Confirm New Password</label>
              <input
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                className={styles.input}
                placeholder='Confirm New Password'
                autoComplete="new-password" // Add this line to disable autofill for the confirm new password field

              />
              {errorMessage && <p className={styles['error-message']}>{errorMessage}</p>}

              <div className={styles.lastrow}>
                <div type="submit"
                   className={`${styles.loginbutton}
                   ${saveButtonState === 'success' ? styles.successButton : saveButtonState === 'failure' ? styles.failureButton : ''}`}
                 
                onClick={handleResetPassword}>
                  <div className={styles.loginbuttontext}>
                  {getButtonText()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
