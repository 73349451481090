import styles from './TextEditor.module.css';
import React, { useRef, useEffect, useState } from 'react';
import AddNote from '../../allNote/AddNote';
import {
  userInfoState,
  userRejected_ApprovedNoteState,
  notes_highlightsState,
  clickedManuscriptAtom,
} from '../../../../state';
import { useRecoilState } from 'recoil';
import HtmlReactParser from 'html-react-parser';
import useApiUtils from '../../../../useApiUtils';
import { click } from '@testing-library/user-event/dist/click';
import { renderCenteredAlert } from '../../../CenteredAlert';

const TextEditor = (props) => {
  const editorRef = useRef();
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [text, setText] = useState(props.initialState || 'Initial Text');
  const [contextMenuVisible, setContextMenuVisible] = useState(false);
  const [contextAddNoteVisible, setContextAddNoteVisible] = useState(false);
  const [userInfo, setUserInfo] = useRecoilState(userInfoState); // Update the path

  const [contextMenuPosition, setContextMenuPosition] = useState({
    left: 0,
    top: 0,
  });
  const [addNotePosition, setAddNotePosition] = useState({ left: 0, top: 0 });

  const [selectedIndices, setSelectedIndices] = useState({ start: 0, end: 0 }); // Added state to store selected indices

  const [isInitialRender, setInitialRender] = useState(false);
  const [highlights, setHighlights] = useState([]);

  const [notes_highlights, setNotes_highlights] = useRecoilState(
    notes_highlightsState
  );
  const [noteApproveReject, setNoteApproveReject] = useRecoilState(
    userRejected_ApprovedNoteState
  );

  const [clickedManuscript, setClickedManuscript] = useRecoilState(
    clickedManuscriptAtom
  );

  const {
    getManuscriptsInfo,
    initialgetBookChapter,
    get_book_scores,
    getbookgenrekeywords,
    sendRejection,
    sendApprove,
    save_user_book_notes_highlights_add_notes,
    save_user_book_notes_highlights_delete_notes,

    // Add other functions from the hook if needed
  } = useApiUtils();

  useEffect(() => {
    // if (isInitialRender) {
    setText(props.initialState);
    applyHighlightsAndNotes(props.notesAndHighlights);
    // } else {
    //   setInitialRender(true);

    // }
  }, [props.initialState, props.notesAndHighlights, notes_highlights]);

  const handleAddNote = () => {
    const selection = window.getSelection();
    let start;
    let end;
    if (selection) {
      const range = selection.getRangeAt(0);

      // Get the container element (e.g., the contentEditable div)
      const containerElement = editorRef.current;

      // Create a Range that covers the entire container element
      const containerRange = document.createRange();
      containerRange.selectNodeContents(containerElement);

      // Set the end of the containerRange to the start of the selection
      containerRange.setEnd(range.startContainer, range.startOffset);

      // Calculate the start offset as the length of the containerRange text
      start = containerRange.toString().length;

      // Calculate the end offset by adding the length of the selected text
      end = start + range.toString().length;

      // Now, start and end have been adjusted based on the matching item in notesAndHighlights

      //if start===end then there is no text selected
      if (start === end) {
        renderCenteredAlert(
          'Please select text and than right click on the selected text to add note.'
        );
        return;
      }
      setSelectedIndices({ start, end });

      setContextMenuVisible(false); // Hide the context menu
      setContextAddNoteVisible(true); // Show the AddNote component
      setAddNotePosition(contextMenuPosition); // Set the position of AddNote
    }
  };

  // Function to find the last <span> element within a range
  function getLastSpanBeforeRange(range) {
    const container = range.cloneContents();
    const spans = container.querySelectorAll('span');

    // If there are spans, return the last one
    if (spans.length > 0) {
      return spans[spans.length - 1];
    }

    // If there are no spans, or if the container is empty, return null
    return null;
  }

  const handleHideNote = () => {
    setContextAddNoteVisible(false);
  };

  const handleCut = () => {
    const selection = window.getSelection();
    const selectedText = selection.toString();

    if (selectedText) {
      const range = selection.getRangeAt(0);
      const clonedContents = range.cloneContents();
      const fragment = document.createElement('div');
      fragment.appendChild(clonedContents);

      // Copy the selected text to the clipboard
      navigator.clipboard.writeText(selectedText);

      // Delete the selected text
      range.deleteContents();
      handleHideContextMenu();
    }
  };

  const handlePaste = () => {
    navigator.clipboard.readText().then((pastedText) => {
      const selection = window.getSelection();
      const range = selection.getRangeAt(0);

      // Delete the existing selected content
      range.deleteContents();

      // Insert the pasted text
      range.insertNode(document.createTextNode(pastedText));
      handleHideContextMenu();
    });
  };
  const handleCopy = () => {
    const selection = window.getSelection();
    const selectedText = selection.toString();

    if (selectedText) {
      navigator.clipboard.writeText(selectedText);
      handleHideContextMenu();
    }
  };
  // const selection = window.getSelection();
  // const range = selection.getRangeAt(0);
  // const highlightedSpans = document.querySelectorAll('.highlighted');

  // // Check if any existing highlights intersect with the selected range
  // let hasExistingHighlights = false;
  // highlightedSpans.forEach(span => {
  //   if (range.intersectsNode(span)) {
  //     hasExistingHighlights = true;

  //     // Remove existing highlight
  //     span.outerHTML = span.innerHTML;
  //   }
  // });

  // if (!hasExistingHighlights) {
  //   const newNode = document.createElement('span');
  //   newNode.classList.add('highlighted');
  //   newNode.style.backgroundColor = 'rgba(99, 217, 255, 0.4)';
  //   // newNode.style.backgroundColor = 'rgba(90, 204, 242, 0.296)';

  //   // Extract the selected range into a separate text node
  //   const extractedNode = range.extractContents();

  //   // Wrap the extracted node with the new span node
  //   newNode.appendChild(extractedNode);

  //   // Insert the new node at the start position of the original range
  //   range.insertNode(newNode);

  //   // Reset the selection
  //   selection.removeAllRanges();
  // }

  // handleHideContextMenu();
  // saveh();
  //this is

  const handleHighlight = () => {
    const selection = window.getSelection();
    let start;
    let end;
    if (selection) {
      const range = selection.getRangeAt(0);

      // Get the container element (e.g., the contentEditable div)
      const containerElement = editorRef.current;

      // Create a Range that covers the entire container element
      const containerRange = document.createRange();
      containerRange.selectNodeContents(containerElement);

      // Set the end of the containerRange to the start of the selection
      containerRange.setEnd(range.startContainer, range.startOffset);

      // Calculate the start offset as the length of the containerRange text
      start = containerRange.toString().length;

      // Calculate the end offset by adding the length of the selected text
      end = start + range.toString().length;

      // Now, start and end have been adjusted based on the matching item in notesAndHighlights

      //if start===end then there is no text selected
      if (start === end) {
        renderCenteredAlert(
          'Please select text and than right click on the selected text to add highlight.'
        );
        return;
      }
      const currentDate = new Date();
      const currentTime = currentDate.toLocaleTimeString('en-US', {
        hour12: false,
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      });
      const formattedDateTime =
        currentDate.toISOString().split('T')[0] + ' ' + currentTime;

      const newNote = {
        //the text will ne the substring in the rang [start,end] in the text
        text: props.initialState.substring(start, end),
        date: formattedDateTime,
        owner_name: userInfo.name,
        user: userInfo.user_id,
        index: [start, end],
        id: `${props.title} ${start} ${end}`,
        chapter: props.title,
        type: 'highlight',
        id_pos: `${props.title} ${start} ${end}`,
      };

      setNotes_highlights((prevNotes) => {
        const updatedNotesHighlights = [...prevNotes];

        for (let i = 0; i < updatedNotesHighlights.length; i++) {
          if (updatedNotesHighlights[i].chapter_name === props.title) {
            if (!Array.isArray(updatedNotesHighlights[i].notesAndHighlights)) {
              updatedNotesHighlights[i].notesAndHighlights = [];
            }
            ////
            const filteredNotesAndHighlights = updatedNotesHighlights[
              i
            ].notesAndHighlights.filter((item) => {
              const [itemStart, itemEnd] = item.index;
              // Check if the item's range does not overlap with the current range
              if (itemEnd <= start || itemStart >= end) {
                return true; // Keep items that do not overlap
              } else {
                // Send the item to delete function
                save_user_book_notes_highlights_delete_notes(
                  item,
                  clickedManuscript.book_id
                );
                return false; // Do not keep items that overlap
              }
            });
            ////
            const newnotesAndHighlights = [
              ...filteredNotesAndHighlights,
              newNote,
            ];

            newnotesAndHighlights.sort((a, b) => b.index[1] - a.index[1]);

            // Create a new object with the updated property
            updatedNotesHighlights[i] = {
              ...updatedNotesHighlights[i],
              notesAndHighlights: newnotesAndHighlights,
            };

            break;
          }
        }
        save_user_book_notes_highlights_add_notes(
          newNote,
          clickedManuscript.book_id
        );

        return updatedNotesHighlights;
      });

      handleHideContextMenu();
    }
  };

  const handleContextMenu = (event) => {
    event.preventDefault();

    const selection = window.getSelection();
    if (selection) {
      const start = selection.getRangeAt(0).startOffset;
      const end = selection.getRangeAt(0).endOffset;

      // Now you have the start and end indices of the selected text

      // You can pass these indices to the AddNote component or perform any other action
    }

    setContextMenuPosition({ left: event.clientX, top: event.clientY });
    setContextMenuVisible(true);
  };

  const handleHideContextMenu = () => {
    setContextMenuVisible(false);
  };

  const handleInput = (event) => {
    // const newText = editorRef.current.innerText;
    // setText(newText);
    event.preventDefault();
  };

  const applyHighlightsAndNotes = (notesAndHighlights) => {
    if (!notesAndHighlights) return;
    //check if length is 0
    if (notesAndHighlights.length === 0) return;

    const newText = applyHighlightsToText(
      props.initialState,
      notesAndHighlights
    );
    setText(newText);
  };

  const applyHighlightsToText = (thtext, notesAndHighlights) => {
    let newText = props.initialState;

    notesAndHighlights.forEach((item) => {
      const [start, end] = item.index;

      const backgroundColorStyle =
        item.type === 'note'
          ? 'background-color: rgb(224, 220, 220);'
          : 'background-color: rgba(99, 217, 255, 0.4);';

      //fore note rgb(248, 248, 248)
      const highlightedText = `<span id="${
        item.id_pos
      }" style="${backgroundColorStyle}">${props.initialState.substring(
        start,
        end
      )}</span>`;
      newText =
        newText.substring(0, start) + highlightedText + newText.substring(end);
    });

    return newText;
  };

  const scrollToElement = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const contextMenu = document.getElementById('context-menu');
      const contextNote = document.getElementById('context-note');

      if (contextMenu && !contextMenu.contains(event.target)) {
        handleHideContextMenu();
      }
    };

    window.addEventListener('click', handleClickOutside);

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleKeyDown = (e) => {
    const isShortcut = (e.ctrlKey || e.metaKey) && e.key === 'c';

    if (isShortcut) {
      e.preventDefault();
      if (e.key === 'c') {
        handleCopy();
      } else if (e.key === 'x') {
        handleCut();
      } else if (e.key === 'v') {
        handlePaste();
      }
    } else if (!isShortcut) {
      e.preventDefault();
    }
  };

  const handleUnicodeEscapes = (text) => {
    return text.replace(/\\u([\dA-Fa-f]{4})/g, (match, grp) => {
      return String.fromCharCode(parseInt(grp, 16));
    });
  };

  const handleDragStart = (e) => {
    e.preventDefault();
    return false;
  };

  return (
    <div className={styles.FrameRootRoot}>
      <div className={styles.chapterTitle}>{props.title}</div>
      {/* <button onClick={saveh} > save highlight</button>
      <div > 
        {highlights.map((highlight, index) => (
          <div key={index}>
            <h3>{props.title}</h3>
            <ul>
              
              <li key={index}>{`Start: ${highlight.start}, End: ${highlight.end}`}</li>
            </ul>
            </div>
        ))}

      </div> */}
      {/* <div >
        {highlightedText.map((text, index) => (
          <div key={index}>
            <h3>{text.chapter}</h3>
            <ul>
              {text.highlights.map((highlight, index) => (
                <li key={index}>{`Start: ${highlight.start}, End: ${highlight.end}`}</li>
              ))}
            </ul>
          </div>
        ))}
      </div> */}
      <br />
      {/* <div
        ref={editorRef}
        contentEditable={true}
        onInput={handleInput}
        onContextMenu={handleContextMenu}
        onKeyDown={handleKeyDown}
        className={styles.ChapterText}
      >
        {text.split('\n').map((line, index) => (
          <React.Fragment key={index}>
            {line}
            {index !== text.split('\n').length - 1 && <br />} 
          </React.Fragment>
        ))}      </div> */}
      <div
        ref={editorRef}
        contentEditable={true}
        onInput={handleInput}
        onContextMenu={handleContextMenu}
        onKeyDown={handleKeyDown}
        onDragStart={handleDragStart}
        className={styles.ChapterText}
      >
        {/* {HtmlReactParser(text)} */}
        {HtmlReactParser(handleUnicodeEscapes(text).replace(/\n/g, '<br />\n'))}
      </div>
      {contextMenuVisible && (
        <div
          id="context-menu"
          style={{
            position: 'fixed',
            left: contextMenuPosition.left,
            top: contextMenuPosition.top,
            boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.2)',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            overflowX: 'visible',
            overflowX: 'visible',
            flexWrap: 'wrap', // Allow wrapping menu items to next row
            maxWidth: '100%',
          }}
        >
          <button className={styles.buttonManu1} onClick={handleHighlight}>
            <div className={styles.buttonManutext}>Highlight</div>
          </button>
          <button className={styles.buttonManu2} onClick={handleAddNote}>
            <div className={styles.buttonManutext}>Add Note</div>
          </button>
          {/* <button className={styles.buttonManu}  onClick={handleCut}>Cut</button> */}
          <button className={styles.buttonManu3} onClick={handleCopy}>
            <div className={styles.buttonManutext}>Copy</div>
          </button>
          {/* <button className={styles.buttonManu}  onClick={handlePaste}>Paste</button> */}
        </div>
      )}
      {contextAddNoteVisible && (
        <div
          id="context-note"
          style={{
            position: 'fixed',
            left: addNotePosition.left,
            top: addNotePosition.top,
            zIndex: 9999,
          }}
        >
          <AddNote
            HideNote={handleHideNote}
            start={selectedIndices.start}
            end={selectedIndices.end}
            chapter={props.title}
          />
        </div>
      )}
    </div>
  );
};

export default TextEditor;
