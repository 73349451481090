import React, { useEffect, useRef } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';
import styles from './DiscoverPage.module.css';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../header/header';
import { userInfoState } from '../../state';
import useApiUtils from '../../useApiUtils';
import CircularProgress from '@mui/material/CircularProgress';
import Markdown from 'markdown-to-jsx';
import { FaPaperPlane } from 'react-icons/fa';
import LoadingDots from './LoadingDots';
import { useRecoilValue } from 'recoil';
import {
  copilotStateAtom,
  manuscriptInfoListAtom,
  clickedManuscriptAtom,
} from '../../state';
import { Card, CardContent, Typography, Grid } from '@mui/material';
import BookCard from './BookCard';
import AboutBook from '../submission/popupBook/AboutBook';

const SearchPage = () => {
  const copilotState = useRecoilValue(copilotStateAtom);
  const [manuscriptInfoList, setManuscriptInfoList] = useRecoilState(
    manuscriptInfoListAtom
  );
  const [clickedManuscript, setClickedManuscript] = useRecoilState(
    clickedManuscriptAtom
  );

  // Select the first thread from the state
  const thread = copilotState.threads[0];

  const [query, setQuery] = useState('');

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [userInfo, setUserInfo] = useRecoilState(userInfoState);

  const [selectedValue, setSelectedValue] = useState('');

  const [showBookinfo, setShowBookInfo] = useState(false);

  const [showSuccess, setShowSuccess] = useState(false);

  const quickSearches = [
    'Find romance books with a tragic end',
    'What are the main themes in my slushpile',
    'Historical fiction with a young adult theme',
    'Novel books with a humble hero',
  ];

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  useEffect(() => {
    console.log('copilotState:', copilotState);
  }, [copilotState]);

  const {
    chatManuscripts,
    clearChats,
    get_book_scores,
    get_most_similar_books,
    get_autor_info,
  } = useApiUtils();

  const navigate = useNavigate();

  const handleSearch = async (qs_query) => {
    setError('');

    // sometimes called with quicksearch in which case the query is passed as an argument
    const searchQuery = qs_query || query;

    if (!searchQuery) {
      setError('Please enter a search query');
      return;
    }

    setQuery('');
    setLoading(true);
    const apiResults = await chatManuscripts(
      searchQuery,
      userInfo.company_id,
      thread ? thread.id : null
    );
    setLoading(false);

    if (apiResults.error) {
      setLoading(false);
      setError(apiResults.error);
      return;
    } else {
      setLoading(false);
    }
  };

  const handleQuickSearch = async (qs_query) => {
    setQuery(qs_query);
    handleSearch(qs_query);
  };

  const handleNewThread = () => {
    clearChats();
  };

  const findBookById = (bookId) => {
    // console.log('bookId:', bookId);
    // console.log('manuscriptInfoList:', manuscriptInfoList);
    return manuscriptInfoList.find((book) => book.book_id === bookId);
  };

  const bookCardClick = (book) => {
    console.log('book:', book);
    setClickedManuscript(book);
    setShowBookInfo(true);

    // we kick off the api calls to get the book info for the about book popup
    get_book_scores(book.book_id);
    get_most_similar_books(book.book_id);
    get_autor_info(book.book_id);
  };

  // used for showing the success message after downloading
  const showSuccessMessage = (message) => {
    setShowSuccess(true);
    setTimeout(() => {
      setShowSuccess(false);
    }, 5000);
  };

  useEffect(() => {
    if (!localStorage.getItem('id_token')) {
      navigate('/');
    }
  }, []);

  return (
    <div className={styles.submissionsPageContainer}>
      <Header issearch={true} issettings={false} />

      <div className={styles.submissionsPageC}>
        <div className={styles.submissionsPage}>
          <div className={styles.submissionsPageTitle}>Discover</div>
          <div className={styles.allManuscripyPart2}></div>
        </div>
        {/* <div className={styles.progress}> */}
        <div className={styles.pageContainer}>
          {showBookinfo && (
            <AboutBook
              key={`discover-book-info-${clickedManuscript.book_id}`}
              id_manuscript={1}
              return={setShowBookInfo}
              showSuccessMessage={showSuccessMessage}
            />
          )}
          <div className={styles.leftSidebar}>
            {' '}
            <div
              className={styles.buttonNewThread}
              onClick={() => handleNewThread()}
            >
              New Thread
            </div>
          </div>
          {thread && thread.questions.length > 0 ? (
            // Already inside a chat
            <div className={styles.tableManuscripts}>
              <div className={styles.container}>
                {thread.questions.map((question, index) => (
                  <div key={index} className={styles.qaItem}>
                    <h1 className={styles.heading}>{question}</h1>
                    <div className={styles.answerContainer}>
                      {thread.answers[index] ? (
                        <React.Fragment>
                          {thread.bookIds && thread.bookIds[index] && (
                            <Grid
                              container
                              spacing={2}
                              gap={2}
                              direction="row"
                              wrap="nowrap"
                              sx={{ overflowX: 'auto', marginTop: '10px' }}
                            >
                              {/* using a set to remove duplicates */}
                              {[...new Set(thread.bookIds[index])].map(
                                (bookId, index) => {
                                  const book = findBookById(bookId);
                                  if (book) {
                                    return (
                                      <div
                                        onClick={() => {
                                          bookCardClick(book);
                                        }}
                                      >
                                        <BookCard
                                          key={index}
                                          title={book.manuscript_name}
                                          time={book.date_uploaded}
                                          image={book.image}
                                        />
                                      </div>
                                      // <Grid item key={index}>
                                      //   <Card>
                                      //     <CardContent>
                                      //       <Typography
                                      //         variant="h6"
                                      //         component="div"
                                      //       >
                                      //         {book.manuscript_name || 'Untitled'}
                                      //       </Typography>
                                      //       {/* <Typography color="text.secondary">
                                      //         Author:{' '}
                                      //         {book.author_name || 'Unknown'}
                                      //       </Typography>
                                      //       <Typography color="text.secondary">
                                      //         Status: {book.status}
                                      //       </Typography> */}
                                      //       <Typography color="text.secondary">
                                      //         Date Uploaded:{' '}
                                      //         {new Date(
                                      //           book.date_uploaded
                                      //         ).toLocaleDateString()}
                                      //       </Typography>
                                      //     </CardContent>
                                      //   </Card>
                                      // </Grid>
                                    );
                                  }
                                }
                              )}
                            </Grid>
                          )}

                          <Markdown>
                            {thread.answers[index].replace(
                              /【\d+:\d+†[^】]+】/g,
                              ''
                            )}
                          </Markdown>
                        </React.Fragment>
                      ) : (
                        <LoadingDots />
                      )}
                    </div>
                  </div>
                ))}

                {!loading && (
                  <div className={styles.searchBoxContainer}>
                    <input
                      type="text"
                      placeholder="Ask a followup question"
                      className={styles.searchBox}
                      onChange={(e) => setQuery(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          handleSearch();
                        }
                      }}
                      value={query}
                    />
                    <div
                      className={styles.buttonSearch}
                      onClick={() => handleSearch()}
                      data-testid="search-button"
                      disabled={loading}
                    >
                      {loading ? (
                        <CircularProgress size={15} color="inherit" />
                      ) : (
                        <FaPaperPlane />
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            // Start a new chat
            <div className={styles.tableManuscripts}>
              <div className={styles.container}>
                <h1 className={styles.heading}>What would you like to know?</h1>

                <div className={styles.searchBoxContainer}>
                  {/* <FormControl fullWidth>
                  <InputLabel id="custom-select-label">Context</InputLabel>
                  <Select
                    labelId="custom-select-label"
                    value={selectedValue}
                    onChange={handleChange}
                    label="Select an Option"
                  >
                    
                    <MenuItem value="main">Entire Slushpile</MenuItem>

                    <Divider />

                    <ListSubheader>Specific Book</ListSubheader>
                    <MenuItem value="option1">Harry Potter</MenuItem>
                    <MenuItem value="option2">Hunger Games</MenuItem>
                  </Select>
                </FormControl> */}

                  <input
                    type="text"
                    placeholder="Ask a question or search for a book/s"
                    className={styles.searchBox}
                    onChange={(e) => setQuery(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleSearch();
                      }
                    }}
                    value={query}
                  />
                  <div
                    className={styles.buttonSearch}
                    onClick={() => handleSearch()}
                    data-testid="search-button"
                    disabled={loading}
                  >
                    {loading ? (
                      <CircularProgress size={15} color="inherit" />
                    ) : (
                      <FaPaperPlane />
                    )}
                  </div>
                </div>

                <div className={styles['suggestions-grid']}>
                  {quickSearches.map((text, index) => (
                    <div
                      key={index}
                      className={styles['suggestion-button']}
                      onClick={() => handleQuickSearch(text)}
                    >
                      {text}
                    </div>
                  ))}
                </div>

                {/* Display error message */}
                {error && <p style={{ color: 'red' }}>Error: {error}</p>}
              </div>
            </div>
          )}
        </div>{' '}
        {/* end pageContainer */}
      </div>
    </div>
  );
};

export default SearchPage;
