import * as React from 'react';
import {
  DataGrid,
  GridToolbar,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useRecoilState } from 'recoil';
import {
  userInfoState,
  numberOfUploadedFilesState,
  headerColumnsTranslationState,
  loadingmanuscriptState,
  pendingProcessingState,
  initialHighlightState,
  loadingStateCompare,
  currentManuscriptAtom,
  afterGetBookChapter,
  clickedManuscriptAtom,
  genresState,
  keywordsState,
  genresDataState,
  scoreDataAtom,
  chapterNamesAtom,
  contentAtom,
  notesAtom,
  manuscriptInfoListAtom,
  fileState,
  fileInfoState,
  clickedUploadFileState,
} from '../../state';
import Status from '../submission/Status';
import dots from '../../icons/vuesax/outline/UniononlyDots.svg';
import styles from './TableUpload.module.css';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import useApiUtils from '../../useApiUtils';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import { styled } from '@mui/material/styles';
import icontrash from '../../icons/icontrash.svg';
import { renderCenteredAlert } from '../CenteredAlert';

import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1ceaa0',
    },
  },
});

const TableUpload = (props) => {
  const [files, setFiles] = useRecoilState(fileState);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();
  const [fileInfo, setFileInfo] = useRecoilState(fileInfoState);
  const [clickedUploadFile, setClickedUploadFile] = useRecoilState(
    clickedUploadFileState
  );
  const [userInfo, setUserInfo] = useRecoilState(userInfoState); // Update the path

  const [numberOfUploadedFiles, setNumberOfUploadedFiles] = useRecoilState(
    numberOfUploadedFilesState
  );

  const [pendingProcessing, setPendingProcessing] = useRecoilState(
    pendingProcessingState
  );
  const [loadingmanuscript, setLoadingmanuscript] = useRecoilState(
    loadingmanuscriptState
  );

  ////////////////Submition Page Init/////////////////////
  const [inithighlight, setInithighlight] = useRecoilState(
    initialHighlightState
  );

  const [manuscriptInfoList, setManuscriptInfoList] = useRecoilState(
    manuscriptInfoListAtom
  );
  const [clickedManuscript, setClickedManuscript] = useRecoilState(
    clickedManuscriptAtom
  );
  const [currentManuscript, setCurrentManuscript] = useRecoilState(
    currentManuscriptAtom
  );
  const [loading, setLoading] = useRecoilState(loadingStateCompare); //for the books

  const [headerColumnsTranslation, setHeaderColumnsTranslation] =
    useRecoilState(headerColumnsTranslationState);

  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  const {
    getManuscriptsInfo,
    initialgetBookChapter,
    get_book_scores,
    getbookgenrekeywords,
    uploadmanuscript,
    // Add other functions from the hook if needed
  } = useApiUtils();

  const columns = [
    {
      field: 'manuscript_name',
      headerName: 'Manuscript Name',
      flex: 2,
      renderCell: (params) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img
            src={params.row.image}
            alt="Manuscript"
            style={{ marginRight: 5, width: '50px', height: '50px' }} // Adjust the width and height as per your preference
          />
          <span>{params.value}</span>
        </div>
      ),
    },
    {
      field: 'author_name',
      headerName: 'Author',
      flex: 1,
      align: 'left',
    },
    {
      field: 'author_email',
      headerName: 'Email',
      headerAlign: 'center',
      flex: 1,
    },

    {
      field: 'actions',
      headerName: '',
      width: 10,
      align: 'center',
      sortable: false,
      filterable: false,
      hideable: false,
      renderCell: (params) => {
        const handleClick = (event) => {
          setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
          setAnchorEl(null);
        };

        const handleEdit = () => {
          props.handleEdit(true);
          handleClose();
        };

        const handleDelete = () => {
          props.handleDelete(true);
          handleClose();

          // const updatedList = rows.filter(item => item.id !== params.row.id);
          // setRows(updatedList);
        };

        return (
          <div>
            <Button
              id={`basic-button-${params.id}`}
              aria-controls={`basic-menu-${params.id}`}
              aria-haspopup="true"
              aria-expanded={anchorEl ? 'true' : undefined}
              onClick={handleClick}
              className={styles.dotsButton}
            >
              <img
                className={styles.dots}
                style={{ backgroundColor: params.row.background }}
                src={dots}
                alt="dots"
              />
            </Button>
            <Menu
              id={`basic-menu-${params.id}`}
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': `basic-button-${params.id}`,
              }}
              PaperProps={{
                style: {
                  boxShadow: 'none',
                  background: '#FFFFFF',
                  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.18)',
                  borderRadius: '8px',
                },
              }}
            >
              <MenuItem className={styles.edit} onClick={handleEdit}>
                Edit
              </MenuItem>
              <MenuItem className={styles.delete} onClick={handleDelete}>
                Delete
              </MenuItem>
            </Menu>
          </div>
        );
      },
    },
  ];

  const handleDiscard = () => {
    setFileInfo([]);
    setFiles([]);
    navigate('/submissions');
  };

  const handleAddFile = () => {
    props.wasUploaded();
  };

  const handleSave = async () => {
    navigate('/submissions');
    if (fileInfo.length > 0) {
      setPendingProcessing(fileInfo.length);
      setNumberOfUploadedFiles(fileInfo.length);
      const uploadList = fileInfo.map((file) => ({
        original_filename: file.original_filename,
        manuscript_name: file.manuscript_name,
        author_name: file.author_name,
        author_email: file.author_email,
        status: file.status,
        encoded_upload_url: file.encoded_upload_url,
        encoded_img_upload_url: file.img,
      }));
      const requestPayload = {
        command: 'upload',
        user_id: userInfo.user_id,
        channel: 'website',
        upload_list: uploadList,
      };
      uploadmanuscript(requestPayload);
    } else {
      // clear the file info
      setFileInfo([]);
      setFiles([]);
      // navigate("/");
    }
  };

  const handleRowClick = (params, event) => {
    setClickedUploadFile(params.row);
  };

  const handleRowSelectionModelChange = (newSelection) => {
    setRowSelectionModel(newSelection);
  };

  const handleDeletefewfiles = () => {
    if (rowSelectionModel.length === 0) {
      renderCenteredAlert('Please select files to delete');
      return;
    }
    //create new array withou the selected files , the selected files are in rowSelectionModel are array of indexs
    let newfileInfo = [];
    for (let i = 0; i < fileInfo.length; i++) {
      if (!rowSelectionModel.includes(fileInfo[i].id)) {
        newfileInfo.push(fileInfo[i]);
      }
    }

    // Creating new objects with updated IDs
    newfileInfo = newfileInfo.map((file, index) => ({
      ...file,
      id: index,
    }));
    setFileInfo(newfileInfo);
    setRowSelectionModel([]);
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton style={{ color: '#3D4E53' }} />
        <GridToolbarFilterButton style={{ color: '#3D4E53' }} />
        <GridToolbarDensitySelector style={{ color: '#3D4E53' }} />
        {/* <GridToolbarExport style={{ color: '#3D4E53' }}/> */}
        {/* <Button onClick={() => handleSelectAllRows(true)}>Select All Rows</Button>
              <Button onClick={() => handleSelectAllRows(false)}>Unselect All Rows</Button> */}
      </GridToolbarContainer>
    );
  }

  const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& .ant-empty-img-1': {
      fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
    },
    '& .ant-empty-img-2': {
      fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
    },
    '& .ant-empty-img-3': {
      fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
    },
    '& .ant-empty-img-4': {
      fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
    },
    '& .ant-empty-img-5': {
      fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
      fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
    },
  }));

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <svg
          width="120"
          height="100"
          viewBox="0 0 184 152"
          aria-hidden
          focusable="false"
        >
          <g fill="none" fillRule="evenodd">
            <g transform="translate(24 31.67)">
              <ellipse
                className="ant-empty-img-5"
                cx="67.797"
                cy="106.89"
                rx="67.797"
                ry="12.668"
              />
              <path
                className="ant-empty-img-1"
                d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
              />
              <path
                className="ant-empty-img-2"
                d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
              />
              <path
                className="ant-empty-img-3"
                d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
              />
            </g>
            <path
              className="ant-empty-img-3"
              d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
            />
            <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
              <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
              <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
            </g>
          </g>
        </svg>
        <Box sx={{ mt: 1 }}>Nothing to review</Box>
      </StyledGridOverlay>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.tableU}>
        <div style={{ height: '70vh', width: '100%' }}>
          <ThemeProvider theme={theme}>
            <DataGrid
              rows={fileInfo}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 10 },
                },
              }}
              pageSizeOptions={[5, 10, 15]}
              checkboxSelection
              disableRowSelectionOnClick
              onRowClick={(params, event) => handleRowClick(params, event)}
              slots={{
                //toolbar: CustomToolbar,
                noRowsOverlay: CustomNoRowsOverlay,
              }}
              onRowSelectionModelChange={handleRowSelectionModelChange}
              rowSelectionModel={rowSelectionModel}
              sx={{
                '.MuiDataGrid-columnHeaderTitle': {
                  fontWeight: 'bold !important',
                },
              }}
            />
          </ThemeProvider>
        </div>
      </div>
      <div className={styles.buttonContainer}>
        {/* {rowSelectionModel.length > 0 && */}
        <div className={styles.buttonContainerend}>
          <button
            className={styles.buttonDiscard}
            onClick={handleDeletefewfiles}
          >
            <img className={styles.downimg} src={icontrash} />
            <div className={styles.buttonDiscardText}>Delete </div>
          </button>
          {/* } */}
        </div>
        <div className={styles.buttonContainerend2}>
          <div className={styles.buttonDiscard} onClick={handleDiscard}>
            <div className={styles.buttonDiscardText}>Discard</div>
          </div>
          <div className={styles.buttonSave} onClick={handleSave}>
            <div className={styles.buttonSaveText}>Save</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableUpload;
