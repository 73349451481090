import './global.css';

import { useRecoilState } from 'recoil';
import {
  startPollingState,
  ingestDetailsState,
  selectedChapterAtom,
  showChatState,
  books_id_dwonloadState,
  chatHistoryMessagesAtom,
  chatHistoryAtom,
  embeddingsDataAtom,
  authorInfoState,
  most_similar_bookAtom,
  fileState,
  fileInfoState,
  idTokeneState,
  userRejected_ApprovedNoteState,
  accessTokenState,
  notes_highlightsState,
  companyInfoState,
  numberOfUploadedFilesState,
  userInfoState,
  headerColumnsTranslationState,
  loadingmanuscriptState,
  pendingProcessingState,
  initialHighlightState,
  loadingStateCompare,
  currentManuscriptAtom,
  afterGetBookChapter,
  clickedManuscriptAtom,
  genresState,
  keywordsState,
  isNewVersionSimilarBooksState,
  genresDataState,
  scoreDataAtom,
  chapterNamesAtom,
  contentAtom,
  notesAtom,
  manuscriptInfoListAtom,
  copilotStateAtom,
} from './state';
import React from 'react';
import Thriller from './icons/genreImage/MysteryThriller.svg';
import Mystery from './icons/genreImage/MysteryThriller.svg';
import HistoricalFiction from './icons/genreImage/HistoricalFiction.svg';
import Romance from './icons/genreImage/Romance.svg';
import Horror from './icons/genreImage/Horror.svg';
import Fantasy from './icons/genreImage/ScifiFantasy.svg';
import SciFi from './icons/genreImage/ScifiFantasy.svg';
import Memoir from './icons/genreImage/Memoir.svg';
import YA from './icons/genreImage/YA.svg';
import LiteraryFiction from './icons/genreImage/LiteraryFiction.svg';
import defaultbook from './icons/genreImage/defaultbook.svg';
import bookd from './icons/bookd.svg';

import { useEffect, useRef } from 'react';
import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
} from 'amazon-cognito-identity-js';

import { renderCenteredAlert } from './components/CenteredAlert';

import { v4 as uuidv4 } from 'uuid';
import { add } from 'date-fns';

export const generateCorrelationId = () => uuidv4();

const useApiUtils = () => {
  const [fileInfo, setFileInfo] = useRecoilState(fileInfoState);
  const [files, setFiles] = useRecoilState(fileState);
  const [most_similar_book, setMost_similar_book] = useRecoilState(
    most_similar_bookAtom
  );
  const [authorInfo, setAuthorInfo] = useRecoilState(authorInfoState);
  const [embeddingsData, setEmbeddingsData] =
    useRecoilState(embeddingsDataAtom);
  const [chatHistory, setChatHistory] = useRecoilState(chatHistoryAtom);
  const [chatHistoryMessages, setChatHistoryMessages] = useRecoilState(
    chatHistoryMessagesAtom
  );
  const [numberOfUploadedFiles, setNumberOfUploadedFiles] = useRecoilState(
    numberOfUploadedFilesState
  );
  const [accessToken, setAccessToken] = useRecoilState(accessTokenState);
  const [showChat, setShowChat] = useRecoilState(showChatState);
  const [idToken, setIdToken] = useRecoilState(idTokeneState);
  const [userInfo, setUserInfo] = useRecoilState(userInfoState); // Update the path

  const [pendingProcessing, setPendingProcessing] = useRecoilState(
    pendingProcessingState
  );
  const [loadingmanuscript, setLoadingmanuscript] = useRecoilState(
    loadingmanuscriptState
  );

  const [inithighlight, setInithighlight] = useRecoilState(
    initialHighlightState
  );

  const [manuscriptInfoList, setManuscriptInfoList] = useRecoilState(
    manuscriptInfoListAtom
  );
  const [clickedManuscript, setClickedManuscript] = useRecoilState(
    clickedManuscriptAtom
  );
  const [currentManuscript, setCurrentManuscript] = useRecoilState(
    currentManuscriptAtom
  );
  const [loading, setLoading] = useRecoilState(loadingStateCompare); //for the books
  const [headerColumnsTranslation, setHeaderColumnsTranslation] =
    useRecoilState(headerColumnsTranslationState);

  const [copilotState, setCopilotState] = useRecoilState(copilotStateAtom);

  const REACT_APP_BUILD_ENV =
    process.env.REACT_APP_BUILD_ENV || 'REACT_APP_BUILD_ENV-build_env';
  const REACT_APP_API_ID =
    process.env.REACT_APP_API_ID || 'REACT_APP_BUILD_ENV2-build_env';
  const REACT_APP_S3_BUCKETNAME =
    process.env.REACT_APP_S3_BUCKETNAME || 'REACT_APP_S3_BUCKETNAME-build_env';

  const baseURL = process.env.REACT_APP_BASE_URL || 'http://127.0.0.1:8000';
  const poolData = {
    UserPoolId: process.env.REACT_APP_USER_POOL_ID || 'USER_POOL_ID-build_env',
    ClientId:
      process.env.REACT_APP_CLIENT_ID_USER_POOL ||
      'CLIENT_ID_USER_POOL-build_env',
  };

  const userPool = new CognitoUserPool(poolData);

  //////////////relative to spesific user/////////////////////
  const get_user_info = async (emailtemplat, extractOnlyId = false) => {
    const correlationId = generateCorrelationId(); // Generate Correlation ID once and store it

    const userInfoApiUrl = `${baseURL}/api/user/get_user_info`;

    const user_Id_cognito = localStorage.getItem('userId');
    if (!user_Id_cognito) {
      console.error('No user ID found in local storage');
      return;
    }

    const userInfoApiBody = {
      cognito_id: localStorage.getItem('userId'),
    };

    try {
      const response = await fetch(userInfoApiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${localStorage.getItem('id_token')}`,
          'Correlation-ID': correlationId, // Attach Correlation ID here
        },
        body: JSON.stringify(userInfoApiBody),
      });

      if (response.ok) {
        if (extractOnlyId) {
          const responseData = await response.json();
          return responseData.user_id;
        } else {
          const responseData = await response.json();
          setUserInfo(responseData);

          window.Intercom('boot', {
            api_base: 'https://api-iam.intercom.io',
            app_id: 'l2515b7r',
            name: responseData.name,
            email: responseData.email,
          });

          //hotjar
          const env =
            process.env.REACT_APP_BUILD_ENV || 'REACT_APP_BUILD_ENV-build_env';
          if (env === 'live') {
            var userId = responseData.name || null;
            window.hj('identify', userId, {});
          }

          if (emailtemplat) {
            // getManuscriptsInfo(false, responseData);
          } else {
            getManuscriptsInfo(false, responseData);
          }
          getCompanyInformation(responseData);
        }
      }
    } catch (error) {
      console.error(
        `Error: ${error.message}. Correlation ID: ${correlationId}`
      );
      const alertmessage =
        'An unexpected error occurred. Please try again later or contact our support team with the following correlation ID for assistance: ' +
        correlationId;

      throw new Error(alertmessage);
      // Handle fetch error
    }
  };

  const editusersconfig = async (requestPayload) => {
    const correlationId = generateCorrelationId(); // Generate Correlation ID once and store it

    try {
      const response = await fetch(`${baseURL}/api/user/edit_user_config`, {
        method: 'POST',
        body: JSON.stringify(requestPayload),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${localStorage.getItem('id_token')}`,
          'Correlation-ID': correlationId, // Attach Correlation ID here
        },
      });
      if (!response.ok) {
        throw new Error('Something went wrong! SEND TO COMPARE ITEMS');
      }
      const data = await response.json();
    } catch (error) {
      console.error(
        `Error: ${error.message}. Correlation ID: ${correlationId}`
      );
    }
  };

  const AssignUser = async (bookd, new_owner) => {
    const correlationId = generateCorrelationId(); // Generate Correlation ID once and store it

    try {
      setPendingProcessing(pendingProcessing + 1);
      const requestPayload = {
        user_id: userInfo.user_id,
        book_id: bookd, // Replace with the appropriate book_id from the clickedManuscript object
        assigned_user_id: new_owner, // Replace with the appropriate next_user_id
      };

      const response = await fetch(
        `${baseURL}/api/manuscript/assign_owner_to_manuscript`,
        {
          method: 'POST',
          body: JSON.stringify(requestPayload),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${localStorage.getItem('id_token')}`,
            'Correlation-ID': correlationId, // Attach Correlation ID here
          },
        }
      );

      if (!response.ok) {
        throw new Error('Something went wrong! SEND TO COMPARE ITEMS');
      }

      // Do something with the response data if needed
    } catch (error) {
      console.error(
        `Error: ${error.message}. Correlation ID: ${correlationId}`
      );

      if (pendingProcessing > 0) {
        setPendingProcessing(pendingProcessing - 1);
      } else {
        setPendingProcessing(0);
      }
      // getManuscriptsInfo(false);
    }
  };

  const uploadMethod = async (payload) => {
    const correlationId = generateCorrelationId(); // Generate Correlation ID once and store it

    try {
      const response = await fetch(
        `${baseURL}/api/company/edit_upload_preferences`,
        {
          method: 'POST',
          body: JSON.stringify(payload),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${localStorage.getItem('id_token')}`,
            'Correlation-ID': correlationId, // Attach Correlation ID here
          },
        }
      );

      if (!response.ok) {
        // dispaly en error message if 400 the response look like : {
        // "error_msg": "API Client Bad Request: An existing email mailbox is already integrated. Please clear the integration first before adding a new one.."
        if (response.status === 400) {
          const data = await response.json();
          renderCenteredAlert(data.error_msg);
          return false;
        }

        // throw new Error('Something went wrong! ');
      }

      // const data = await response.json();
      if (localStorage.getItem('currentState')) {
        localStorage.removeItem('currentState');
      }
      //after each save should be new get all the information from the database
      getCompanyInformation();

      return true;

      // Do something with the response data if needed
    } catch (error) {
      console.error(
        `Error: ${error.message}. Correlation ID: ${correlationId}`
      );

      return false;
    }
  };

  const emailTemplate = async (requestBody) => {
    const correlationId = generateCorrelationId(); // Generate Correlation ID once and store it

    const apiUrl = `${baseURL}/api/company/edit_rejection_template`;

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${localStorage.getItem('id_token')}`,
          'Correlation-ID': correlationId, // Attach Correlation ID here
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        // Handle success, you might show a success message to the user
        // getCompanyInformation();
        get_user_info(true);
      } else {
        // Handle errors, you might show an error message to the user
        console.error('Failed to save changes');
      }
    } catch (error) {
      console.error(
        `Error: ${error.message}. Correlation ID: ${correlationId}`
      );
    }
  };

  const sendinvitationemail = async (invitationData) => {
    const correlationId = generateCorrelationId(); // Generate Correlation ID once and store it

    const requestData = {
      user_id: userInfo.user_id,
      invitation_email: invitationData,
    };

    try {
      const response = await fetch(`${baseURL}/api/tasks/send_invite_email`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${localStorage.getItem('id_token')}`,
          'Correlation-ID': correlationId, // Attach Correlation ID here
        },
        body: JSON.stringify(requestData),
      });

      if (response.ok) {
        getCompanyInformation();
      } else {
        // Handle error (e.g., show an error message)
        console.error('Failed to send invitations.');
      }
    } catch (error) {
      // Handle network error or other exceptions
      console.error(
        `Error: ${error.message}. Correlation ID: ${correlationId}`
      );
    }
  };

  const handleRegisterStorywise = async (dataToSend, userId) => {
    const correlationId = generateCorrelationId(); // Generate Correlation ID once and store it

    try {
      const response = await fetch(`${baseURL}/api/user/register_user`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${localStorage.getItem('id_token')}`,
          'Correlation-ID': correlationId, // Attach Correlation ID here
        },
        body: JSON.stringify(dataToSend),
      });

      if (response.ok) {
        get_user_info();
      } else {
        // Handle API request error
        get_user_info();
        console.error('API request error:', response.statusText);
        // Handle any error message or state update you need
      }
    } catch (error) {
      console.error(
        `Error: ${error.message}. Correlation ID: ${correlationId}`
      );
    }
  };

  const handleNewUserValidation = async (dataToSend) => {
    const correlationId = generateCorrelationId(); // Generate Correlation ID once and store it

    try {
      const response = await fetch(`${baseURL}/api/user/validate_user`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Correlation-ID': correlationId, // Attach Correlation ID here
          // 'Authorization': `${localStorage.getItem('id_token')}`,
        },
        body: JSON.stringify(dataToSend),
      });

      if (response.ok) {
        const data = await response.json();
        if (data.validation === 'success') {
          return true;
        } else {
          renderCenteredAlert(data.error);
          return false;
        }
      } else {
        console.error('API request error:', response.statusText);

        return false;
        // Handle any error message or state update you need
      }
    } catch (error) {
      // Handle fetch error
      console.error(
        `Error: ${error.message}. Correlation ID: ${correlationId}`
      );
    }
  };

  const editColomnTable = async (requestPayload) => {
    const correlationId = generateCorrelationId(); // Generate Correlation ID once and store it

    try {
      const response = await fetch(
        `${baseURL}/api/manuscript/manuscript_info`,
        {
          method: 'POST',
          body: JSON.stringify(requestPayload),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${localStorage.getItem('id_token')}`,
            'Correlation-ID': correlationId, // Attach Correlation ID here
          },
        }
      );

      if (!response.ok) {
        throw new Error('Something went wrong! SEND TO COMPARE ITEMS');
      }
      // const data = await response.json();

      //empty headerColumnsTranslation and question and deletedfield
      setHeaderColumnsTranslation({});
      getManuscriptsInfo(false);
    } catch (error) {
      console.error(
        `Error: ${error.message}. Correlation ID: ${correlationId}`
      );
    }
  };

  const downloadManuscripts = async (dwonloadM) => {
    const correlationId = generateCorrelationId(); // Generate Correlation ID once and store it

    try {
      const requestBody = {
        user_id: userInfo.user_id,
        book_id_list: dwonloadM,
      };

      const response = await fetch(
        `${baseURL}/api/manuscript/download_manuscript`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${localStorage.getItem('id_token')}`,
            'Correlation-ID': correlationId, // Attach Correlation ID here
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (!response.ok) {
        throw new Error('Error sending selected book IDs');
      }
      // Parse the response JSON to get the download URL
      const responseData = await response.json();

      // Get the download URL from the response
      const downloadUrl = responseData.download_url;

      // Create an anchor element to trigger the download
      const anchor = document.createElement('a');
      anchor.href = downloadUrl;
      // anchor.target = '_blank'; // Open the link in a new tab
      anchor.download = 'Manuscripts.zip'; // You can specify the filename here

      // Programmatically trigger a click event on the anchor element
      anchor.click();
      // Handle successful response if needed
    } catch (error) {
      console.error(
        `Error: ${error.message}. Correlation ID: ${correlationId}`
      );
    }
  };

  const downloadEmail = async (fileid) => {
    const correlationId = generateCorrelationId(); // Generate Correlation ID once and store it

    try {
      const requestBody = {
        user_id: userInfo.user_id,
        file_id: fileid,
      };

      const response = await fetch(
        `${baseURL}/api/tasks/download_ingested_file`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${localStorage.getItem('id_token')}`,
            'Correlation-ID': correlationId, // Attach Correlation ID here
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (!response.ok) {
        throw new Error('Error sending selected book IDs');
      }
      // Parse the response JSON to get the download URL
      const responseData = await response.json();

      // Get the download URL from the response
      const downloadUrl = responseData.file_download_url;

      // Create an anchor element to trigger the download
      const anchor = document.createElement('a');
      anchor.href = downloadUrl;
      // anchor.target = '_blank'; // Open the link in a new tab
      anchor.download = 'Manuscripts.zip'; // You can specify the filename here

      // Programmatically trigger a click event on the anchor element
      anchor.click();
      // Handle successful response if needed
    } catch (error) {
      console.error(
        `Error: ${error.message}. Correlation ID: ${correlationId}`
      );
    }
  };
  //////////////enddddd relative to spesific user/////////////////////

  ////////////related to   company info/////////////////////
  const setPreferanses = async (dataToSend, oldUser) => {
    const correlationId = generateCorrelationId(); // Generate Correlation ID once and store it

    try {
      const response = await fetch(
        `${baseURL}/api/company/edit_company_preferences`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${localStorage.getItem('id_token')}`,
            'Correlation-ID': correlationId, // Attach Correlation ID here
          },
          body: JSON.stringify(dataToSend),
        }
      );

      if (response.ok) {
        // const responseData = await response.json();
        if (oldUser) {
          getCompanyInformation();
        }
        // Redirect the user or handle other logic as needed
      } else {
        // Handle API request error
        // console.error('API request error:', response.statusText);
        // Handle any error message or state update you need
      }
    } catch (error) {
      console.error(
        `Error: ${error.message}. Correlation ID: ${correlationId}`
      );
    }
  };

  const [companyInfo, setCompanyInfo] = useRecoilState(companyInfoState);

  const getCompanyInformation = async (responseData) => {
    const correlationId = generateCorrelationId(); // Generate Correlation ID once and store it

    try {
      const userid = responseData ? responseData.user_id : userInfo.user_id;

      const requestPayload = {
        user_id: userid, // Replace with the appropriate user_id
      };

      const response = await fetch(`${baseURL}/api/company/get_company_info`, {
        method: 'POST',
        body: JSON.stringify(requestPayload),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${localStorage.getItem('id_token')}`,
          'Correlation-ID': correlationId, // Attach Correlation ID here
        },
      });

      if (!response.ok) {
        throw new Error('Something went wrong! SEND TO COMPARE ITEMS');
      }

      const data = await response.json();
      setCompanyInfo(data);
      return data;

      // Do something with the response data if needed
    } catch (error) {
      console.error(error);
    }
  };

  const getManuscriptsInfo = async (asignClick, responseData) => {
    const correlationId = generateCorrelationId(); // Generate Correlation ID once and store it

    // console clickedmanuscript
    if (responseData === null && userInfo === null) {
      return;
    }
    const userid = responseData ? responseData.user_id : userInfo.user_id;
    const requestPayload = {
      //  user_ids: users,
      user_ids: [userid], // Set user_ids based on responseData or userInfo
    };
    // const token = idToken || storedIdToken;
    try {
      const response = await fetch(
        `${baseURL}/api/manuscript/get_manuscript_info`,
        {
          method: 'POST',
          body: JSON.stringify(requestPayload),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${localStorage.getItem('id_token')}`,
            'Correlation-ID': correlationId, // Attach Correlation ID here
          },
        }
      );

      if (!response.ok) {
        throw new Error('Something went wrong! SEND TO COMPARE ITEMS');
      }
      const data = await response.json();
      setHeaderColumnsTranslation(data.header_columns_translation);
      setPendingProcessing(data.pending_processing);
      if (data.pending_processing === 0) {
        setNumberOfUploadedFiles(0);
      }
      const manuscriptList = data.manuscript_info_list?.map(
        (manuscript, index) => {
          const { genres, owner_user_id, status, ...rest } = manuscript;
          let updatedImage;

          // Define the default status options
          const statusOptions = [];

          if (status === 'Auto-declined') {
            // add Action Action Required
            statusOptions.push('Action Required');
          }
          // Check if status is not 'Declined' or 'Auto-declined' to include default options
          if (
            [
              'Approved for Editorial',
              'Approved for Acquisition',
              'Acquired',
              'Approved',
            ].includes(status)
          ) {
            statusOptions.push(
              'Approved for Editorial',
              'Approved for Acquisition',
              'Acquired'
            );
          }

          let role;
          if (responseData) {
            role = responseData.role;
          } else {
            role = userInfo.role;
          }

          let id_user;
          if (responseData) {
            id_user = responseData.user_id;
          } else {
            id_user = userInfo.user_id;
          }

          const isManager = role === 'lvl2';
          const isEditor = role === 'lvl1';
          const isOwner = id_user === owner_user_id;
          if (
            isManager &&
            ['First Approval', 'Under Review', 'Action Required'].includes(
              status
            )
          ) {
            statusOptions.push('Approve');
          } else if (
            isEditor &&
            isOwner &&
            ['Under Review', 'Action Required'].includes(status)
          ) {
            statusOptions.push('Approve');
          }
          //can decline
          if (
            isManager &&
            !['Declined', 'Auto-declined', 'Upload Failed'].includes(status)
          ) {
            statusOptions.push('Decline');
          } else if (
            isEditor &&
            isOwner &&
            ![
              'Declined',
              'Auto-declined',
              'Upload Failed',
              'First Approval',
            ].includes(status)
          ) {
            statusOptions.push('Decline');
          }

          if (genres === null || genres === 'none') {
            updatedImage = defaultbook;
          } else {
            const firstGenre = genres[0]; // Assuming genre is an array of strings
            switch (firstGenre) {
              case 'Thriller':
                updatedImage = Thriller;
                break;
              case 'Mystery':
                updatedImage = Mystery;
                break;
              case 'Historical Fiction':
                updatedImage = HistoricalFiction;
                break;
              case 'Romance':
                updatedImage = Romance;
                break;
              case 'Horror':
                updatedImage = Horror;
                break;
              case 'Fantasy':
                updatedImage = Fantasy;
                break;
              case 'Sci-fi':
                updatedImage = SciFi;
                break;
              case 'Memoir':
                updatedImage = Memoir;
                break;
              case 'YA':
                updatedImage = YA;
                break;
              case 'Literary Fiction':
                updatedImage = LiteraryFiction;
                break;
              default:
                updatedImage = defaultbook;
                break;
            }
          }

          return {
            ...rest,
            genres: genres,
            id: index,
            image: updatedImage,
            status_option: statusOptions,
            owner_user_id: owner_user_id,
            status: status,
          };
        }
      );
      setManuscriptInfoList(manuscriptList);
      if (asignClick) {
        if (responseData) {
          if (manuscriptList && manuscriptList?.length > 0) {
            // select the first index of a book that is not have status Upload Failed
            let index = 0;
            while (manuscriptList[index].status === 'Upload Failed') {
              index++;
            }
            setClickedManuscript(manuscriptList[index]);
            setCurrentManuscript({ bookId: manuscriptList[index].book_id });
            initialgetBookChapter(manuscriptList[index].book_id, responseData);
            get_book_scores(
              manuscriptList[index].book_id,
              responseData.user_id
            );
            getbookgenrekeywords(manuscriptList[index].book_id, responseData);
            handleCompareApp(null, null, manuscriptList[index].book_id, null);
            get_conversation_history(
              manuscriptList[index].book_id,
              responseData.user_id
            );
          }
        } else {
          if (manuscriptList && manuscriptList?.length > 0) {
            let index = 0;
            while (manuscriptList[index].status === 'Upload Failed') {
              index++;
            }
            setClickedManuscript(manuscriptList[index]);
            setCurrentManuscript({ bookId: manuscriptList[index].book_id });
            initialgetBookChapter(manuscriptList[index].book_id);
            get_book_scores(manuscriptList[index].book_id);
            getbookgenrekeywords(manuscriptList[index].book_id);
            handleCompareApp(null, null, manuscriptList[index].book_id, null);
          }
        }
      }

      return manuscriptList;
    } catch (error) {
      console.error(
        `Error: ${error.message}. Correlation ID: ${correlationId}`
      );
    }
  };

  const uploadmanuscript = async (requestPayload) => {
    const correlationId = generateCorrelationId(); // Generate Correlation ID once and store it

    try {
      setFileInfo([]);
      setFiles([]);
      const response = await fetch(
        `${baseURL}/api/manuscript/process_manuscript`,
        {
          method: 'POST',
          body: JSON.stringify(requestPayload),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${localStorage.getItem('id_token')}`,
            'Correlation-ID': correlationId, // Attach Correlation ID here
          },
        }
      );

      if (!response.ok) {
        throw new Error('Something went wrong! SEND TO COMPARE ITEMS');
      }
      // const data = await response.json();
      getManuscriptsInfo();
      // clear the file info
      // setFileInfo([]);

      // setFiles([]);
      // navigate("/");
    } catch (error) {
      // clear the file info
      // setFileInfo([]);
      // setFiles([]);
      // navigate("/");
      // alert error message with the name of the file
      renderCenteredAlert(`Error uploading files `);
      // setPendingProcessing(0);
    }
  };

  /////////////////end of al books company info/////////////////////

  ////////////////action on a specific book/////////////////////

  const get_most_similar_books = async (bookIdd) => {
    const correlationId = generateCorrelationId(); // Generate Correlation ID once and store it

    try {
      const bookId = bookIdd;
      const user_id = userInfo.user_id;
      const url = `${baseURL}/api/manuscript/get_most_similar_books?user_id=${user_id}&book_id=${bookId}`;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${localStorage.getItem('id_token')}`,
          'Correlation-ID': correlationId, // Attach Correlation ID here
        },
        'Correlation-ID': correlationId, // Attach Correlation ID here
      });
      if (!response.ok) {
        throw new Error('Something went wrong! SEND TO COMPARE ITEMS');
      }
      const data = await response.json();

      // Convert the object into an array of book objects
      const booksArray = Object.values(data);

      setMost_similar_book(booksArray);
    } catch (error) {
      console.error(
        `Error: ${error.message}. Correlation ID: ${correlationId}`
      );
    }
  };

  const [chapterNames, setChapterNames] = useRecoilState(chapterNamesAtom);
  const [content, setContent] = useRecoilState(contentAtom);
  const [notes, setNotes] = useRecoilState(notesAtom);
  const [scoreData, setScoreData] = useRecoilState(scoreDataAtom);
  const [afterGetBookChapte, setAfterGetBookChapte] =
    useRecoilState(afterGetBookChapter);

  const initialgetBookChapter = async (bookd, responseData) => {
    const correlationId = generateCorrelationId(); // Generate Correlation ID once and store it

    //clear the content
    setChapterNames([]);
    setContent([]);
    setNotes([]);
    setNotes_highlights([]);
    setNoteApproveReject([]);
    setAfterGetBookChapte(true);
    const userid = responseData ? responseData?.user_id : userInfo?.user_id;

    const requestPayload = {
      user_id: userid,
      book_id: bookd,
    };
    try {
      const response = await fetch(
        `${baseURL}/api/manuscript/get_manuscript_chapter`,
        {
          method: 'POST',
          body: JSON.stringify(requestPayload),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${localStorage.getItem('id_token')}`,
            'Correlation-ID': correlationId, // Attach Correlation ID here
          },
        }
      );

      if (!response.ok) {
        throw new Error('Something went wrong! SEND TO COMPARE ITEMS');
      }
      const data = await response.json();
      setChapterNames(data.chapter_names);
      setContent(data.content);
      processResponse(data);
    } catch (error) {
      console.error(
        `Error: ${error.message}. Correlation ID: ${correlationId}`
      );
    }
  };

  const get_book_scores = async (bookd) => {
    const correlationId = generateCorrelationId(); // Generate Correlation ID once and store it

    //clear the content
    setScoreData([]);
    try {
      const bookId = bookd;
      const url = `${baseURL}/api/manuscript/get_manuscript_score?book_id=${bookId}`;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${localStorage.getItem('id_token')}`,
          'Correlation-ID': correlationId, // Attach Correlation ID here
        },
      });
      if (!response.ok) {
        throw new Error('Something went wrong! SEND TO COMPARE ITEMS');
      }
      const data = await response.json();

      const desiredOrder = [
        'Eligibility',
        'Writing Level',
        'Editing Level',
        'Story',
        'Characters',
        'World',
        'Concept',
        'Publishing Complexity',
      ];

      const sortedItems = {};
      desiredOrder.forEach((key) => {
        if (data.parameters_items[key]) {
          sortedItems[key] = data.parameters_items[key];
        }
      });

      if (data && data.parameters_items) {
        data.parameters_items = sortedItems;
      }
      setScoreData(data);
    } catch (error) {
      console.error(
        `Error: ${error.message}. Correlation ID: ${correlationId}`
      );
    }
  };

  ///////compqre page init/////////
  const [genres, setGenres] = useRecoilState(genresState);
  const [keywords, setKeywords] = useRecoilState(keywordsState);
  const [nelsonSimilaBooks, setNelsonSimilaBooks] = useRecoilState(
    isNewVersionSimilarBooksState
  );

  const [genresData, setGenresData] = useRecoilState(genresDataState); //for the books

  const getbookgenrekeywords = async (bookd, responseData) => {
    const correlationId = generateCorrelationId(); // Generate Correlation ID once and store it

    try {
      //empty the genres and keywords and the genresData
      setGenres([]);
      setKeywords([]);
      setGenresData([]);
      //set the loading to true
      const userid = responseData ? responseData?.user_id : userInfo?.user_id;

      setLoading(true);
      const bookId = bookd;
      // const user_id = userInfo.user_id;
      const type = 'saved'; // "saved" or "submitted"
      const url = `${baseURL}/api/manuscript/get_book_genre_keywords?user_id=${userid}&book_id=${bookId}&type=${type}`;

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${localStorage.getItem('id_token')}`,
          'Correlation-ID': correlationId, // Attach Correlation ID here
        },
      });
      if (!response.ok) {
        throw new Error('Something went wrong! SEND TO COMPARE ITEMS');
      }
      const data = await response.json();

      setGenres(JSON.parse(data.genres.replace(/'/g, '"')));
      setKeywords(JSON.parse(data.keywords.replace(/'/g, '"')));
    } catch (error) {
      console.error(
        `Error: ${error.message}. Correlation ID: ${correlationId}`
      );
    }
  };
  const handleCompareApp = async (datagenres, datakeywords, bookd, userid) => {
    const correlationId = generateCorrelationId(); // Generate Correlation ID once and store it

    if (datagenres === 0 || datakeywords === 0) {
      return;
    }
    let name;
    let author;
    manuscriptInfoList.map((manuscript, index) => {
      if (manuscript.book_id === bookd) {
        name = manuscript.manuscript_name;
        author = manuscript.author_name;
      }
    });
    const userIdd = userid ? userid : userInfo.user_id;
    const requestPayload = {
      user_id: userIdd,
      book_id: bookd,
      manuscript_name: name,
      author_name: author,
    };
    try {
      const response = await fetch(`${baseURL}/api/manuscript/compare_book`, {
        method: 'POST',
        body: JSON.stringify(requestPayload),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${localStorage.getItem('id_token')}`,
          'Correlation-ID': correlationId, // Attach Correlation ID here
        },
      });
      if (!response.ok) {
        setLoading(false);
        setGenresData([]);
        throw new Error('Something went wrong! SEND TO COMPARE ITEMS');
      }
      const data = await response.json();

      // Check if data is in the new format
      const isNew = !Array.isArray(data.books) ? true : false;
      console.log('isNew', isNew);

      setNelsonSimilaBooks(isNew);

      // sort the books by tradional publisher score and publication date
      try {
        if (isNew) {
          // convert to an array
          const booksArray = Object.values(data);

          // sort by final_score
          booksArray.sort((a, b) => b.final_score - a.final_score);
          setGenresData(booksArray);
        } else {
          setGenresData(data);
        }
      } catch (error) {
        console.error('Error fetching or sorting books:', error);
        setGenresData(data);
      }

      setLoading(false);
    } catch (error) {
      console.error(
        `Error: ${error.message}. Correlation ID: ${correlationId}`
      );
    }
  };

  const sendRejection = async (bookd, iscontentChange, rejectText) => {
    const correlationId = generateCorrelationId(); // Generate Correlation ID once and store it

    try {
      let _sender_email = 'do-not-reply@storywise.ai'; // default value

      // Check if `setCompanyInfo` and `setCompanyInfo.rejection_template` are not undefined
      if (setCompanyInfo && setCompanyInfo.rejection_template) {
        _sender_email =
          setCompanyInfo.rejection_template.decline_sender_email ||
          _sender_email;
      }

      const requestPayload = {
        user_id: userInfo.user_id, // Replace with the appropriate user_id
        book_id: bookd, // Replace with the appropriate book_id from the clickedManuscript object
        template_update: iscontentChange || false,
        temp_template: rejectText,
        decline_sender_email: _sender_email,
      };

      const response = await fetch(
        `${baseURL}/api/manuscript/reject_manuscript`,
        {
          method: 'POST',
          body: JSON.stringify(requestPayload),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${localStorage.getItem('id_token')}`,
            'Correlation-ID': correlationId, // Attach Correlation ID here
          },
        }
      );

      if (!response.ok) {
        throw new Error('Something went wrong! SEND TO COMPARE ITEMS');
      }

      // const data = await response.json();
      addApproveRejectNotes(userInfo.name, 'reject', bookd);

      const mauscriptlist = await getManuscriptsInfo(false);
      if (clickedManuscript.book_id === bookd) {
        // update clickedManuscript to the new element in the list with the same book_id
        const newClickedManuscript = mauscriptlist.find(
          (manuscript) => manuscript.book_id === bookd
        );
        setClickedManuscript(newClickedManuscript);
        setCurrentManuscript({ bookId: newClickedManuscript.book_id });
      }

      // Do something with the response data if needed
    } catch (error) {
      console.error(
        `Error: ${error.message}. Correlation ID: ${correlationId}`
      );
    }
  };

  //        setEmailToApprove({email:emailToSend,method:emailToSendMethod});

  const sendApprove = async (bookd, nextUser) => {
    const correlationId = generateCorrelationId(); // Generate Correlation ID once and store it

    try {
      let requestPayload;
      if (userInfo.role === 'lvl1') {
        if (nextUser.method === 'user') {
          requestPayload = {
            user_id: userInfo.user_id, // Replace with the appropriate user_id
            book_id: bookd, // Replace with the appropriate book_id from the clickedManuscript object
            next_user_id: nextUser.email,
            debug: false,
          };
        } else if (nextUser.method === 'email') {
          requestPayload = {
            user_id: userInfo.user_id, // Replace with the appropriate user_id
            book_id: bookd, // Replace with the appropriate book_id from the clickedManuscript object
            added_email: nextUser.email,
            debug: false,
          };
        }
      } else if (userInfo.role === 'lvl2') {
        requestPayload = {
          user_id: userInfo.user_id, // Replace with the appropriate user_id
          book_id: bookd, // Replace with the appropriate book_id from the clickedManuscript object
          final_approval: true,
          debug: false,
        };
      }

      const response = await fetch(
        `${baseURL}/api/manuscript/proceed_manuscript`,
        {
          method: 'POST',
          body: JSON.stringify(requestPayload),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${localStorage.getItem('id_token')}`,
            'Correlation-ID': correlationId, // Attach Correlation ID here
          },
        }
      );

      if (!response.ok) {
        throw new Error('Something went wrong! SEND TO COMPARE ITEMS');
      }

      // const data = await response.json();
      addApproveRejectNotes(userInfo.name, 'approved', bookd);
      const mauscriptlist = await getManuscriptsInfo(false);
      if (clickedManuscript.book_id === bookd) {
        // update clickedManuscript to the new element in the list with the same book_id
        const newClickedManuscript = mauscriptlist.find(
          (manuscript) => manuscript.book_id === bookd
        );
        setClickedManuscript(newClickedManuscript);
        setCurrentManuscript({ bookId: newClickedManuscript.book_id });
      }
      // Do something with the response data if needed
    } catch (error) {
      console.error(
        `Error: ${error.message}. Correlation ID: ${correlationId}`
      );
    }
  };

  const [noteApproveReject, setNoteApproveReject] = useRecoilState(
    userRejected_ApprovedNoteState
  );

  const addApproveRejectNotes = async (user, typee, bookid) => {
    const correlationId = generateCorrelationId(); // Generate Correlation ID once and store it

    const currentDate = new Date();
    const currentTime = currentDate.toLocaleTimeString('en-US', {
      hour12: false,
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });
    const formattedDateTime =
      currentDate.toISOString().split('T')[0] + ' ' + currentTime;
    let noteText = '';

    if (typee === 'approved') {
      noteText = `Approved by ${user}`;
    } else if (typee === 'reject') {
      noteText = `Rejected by ${user}`;
    } else if (typee === 'Action Required') {
      noteText = `The book's status was changed from Auto-declined to Action required by ${user}`;
    }
    let newType = typee;
    if (typee === 'Action Required') {
      newType = 'reject';
    }
    const newNote = {
      text: noteText,
      date: formattedDateTime,
      user: '1',
      index: [-1, -1],
      id: `${newType} ${user} ${formattedDateTime}`,
      chapter: null,
      type: newType,
      id_pos: `${newType} ${user} ${formattedDateTime}`,
    };
    const updatedNotes = [newNote, ...noteApproveReject];

    // Update the state with the new array
    setNoteApproveReject(updatedNotes);
    const notesAndHighlights = notes_highlights;
    save_user_book_notes_highlights_add_notes(newNote, bookid);
  };

  /////processResponse is create  the notes_highlights and noteApproveReject
  const [notes_highlights, setNotes_highlights] = useRecoilState(
    notes_highlightsState
  );
  const processResponse = (response) => {
    const correlationId = generateCorrelationId(); // Generate Correlation ID once and store it

    const processedArray = [];

    response.content.forEach((chapter, index) => {
      const { chapter_name } = chapter;
      const notesAndHighlights = [];

      const chapterNotes = response.notes_highlight.filter(
        (note) => note.chapter === chapter_name
      );
      chapterNotes.forEach((note) => {
        if (note.type === 'highlight') {
          // Extract the highlighted text from chapter.text using note.index range
          const [start, end] = note.index;
          const highlightedText = chapter.text.substring(start, end + 1);

          notesAndHighlights.push({
            type: note.type,
            note_id: note.note_id,
            text: highlightedText, // Use the extracted highlighted text
            date: note.date,
            user: note.user,
            chapter: note.chapter,
            index: note.index,
            owner_name: note.owner_name,
          });
        } else {
          // For non-highlight notes, use the original note text
          notesAndHighlights.push({
            type: note.type,
            note_id: note.note_id,
            text: note.text,
            date: note.date,
            user: note.user,
            chapter: note.chapter,
            index: note.index,
            owner_name: note.owner_name,
          });
        }
      });
      // Sort notesAndHighlights by the second element in the index in decreasing order
      notesAndHighlights.sort((a, b) => b.index[1] - a.index[1]);

      // Add unique IDs to each element
      notesAndHighlights.forEach((element, elementIndex) => {
        element.id_pos = `${chapter_name}_${index}_${elementIndex}`;
      });

      processedArray.push({
        chapter_name,
        chapter_index: index,
        notesAndHighlights,
      });
    });

    setNotes_highlights(processedArray);

    const processedArray2 = [];

    response.massage.forEach((note, index) => {
      processedArray2.push({
        type: note.type,
        note_id: note.note_id,
        text: note.text,
        date: note.date,
        user: note.user,
        chapter: note.chapter,
        index: note.index,
        owner_name: note.owner_name,
      });
    });
    // Add unique IDs to each element
    processedArray2.forEach((element, elementIndex) => {
      element.id_pos = `${'approve'}_${elementIndex}`;
    });

    setNoteApproveReject(processedArray2);
  };

  const save_user_book_notes_highlights_add_notes = async (element, bookid) => {
    const correlationId = generateCorrelationId(); // Generate Correlation ID once and store it

    const handle_key = 'add_notes';
    const notePayload = {
      type: element.type,
      note_text: element.text,
      date: element.date,
      chapter_name: element.chapter,
      indexes: element.index,
      user: userInfo.user_id,
    };

    // Create the request payload
    const requestPayload = {
      user_id: userInfo.user_id,
      book_id: bookid,
      [handle_key]: [notePayload],
    };

    try {
      const response = await fetch(
        `${baseURL}/api/manuscript/save_user_book_notes_highlights`,
        {
          method: 'POST',
          body: JSON.stringify(requestPayload),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${localStorage.getItem('id_token')}`,
            'Correlation-ID': correlationId, // Attach Correlation ID here
          },
        }
      );
      if (!response.ok) {
        // Handle the 400 error here
        if (response.status === 400) {
          // Handle the specific 400 error case
          initialgetBookChapter(bookid);
          //alert the user the massage : the element.type is overlaping someone  , we need to delete your element.type , we referesh the page and you can add it again (pay attention that overlaping will delte the older one)
          renderCenteredAlert(
            `The ${element.type} is overlapping someone else's element. We have removed your ${element.type} and refreshed the page. You can add it again.`
          );
        } else {
          throw new Error('Something went wrong! SEND TO add book ITEMS');
        }
      }
      let note_id;
      const responseData = await response.json();
      const addedNoteIdList = responseData.add_notes.added_note_id_list;
      if (addedNoteIdList.length > 0) {
        note_id = addedNoteIdList[0];
        // Now, you can use note_id as needed.
      }
      if (element.type === `approved` || element.type === `reject`) {
        const current_book_id = clickedManuscript.book_id;
        initialgetBookChapter(current_book_id);
      } else {
        let newupdatenotesAndHighlights;
        setNotes_highlights((prevNotes) => {
          const updatedNotesHighlights = [...prevNotes];

          for (let i = 0; i < updatedNotesHighlights.length; i++) {
            if (updatedNotesHighlights[i].chapter_name === element.chapter) {
              if (Array.isArray(updatedNotesHighlights[i].notesAndHighlights)) {
                newupdatenotesAndHighlights = updatedNotesHighlights[
                  i
                ].notesAndHighlights.map((note) => {
                  if (
                    note.type === element.type &&
                    note.text === element.text &&
                    note.date === element.date &&
                    JSON.stringify(note.index) ===
                      JSON.stringify(element.index) &&
                    note.user === element.user
                  ) {
                    return {
                      ...note,
                      note_id: note_id, // Update the note_id property
                    };
                  }
                  return note;
                });
              }
              updatedNotesHighlights[i] = {
                ...updatedNotesHighlights[i],
                notesAndHighlights: newupdatenotesAndHighlights,
              };

              break;
            }
          }
          return updatedNotesHighlights;
        });
      }
    } catch (error) {
      console.error(
        `Error: ${error.message}. Correlation ID: ${correlationId}`
      );
    }
  };

  const save_user_book_notes_highlights_edit_notes = async (
    element,
    bookid
  ) => {
    const correlationId = generateCorrelationId(); // Generate Correlation ID once and store it

    const handle_key = 'edit_notes';

    const notePayload = {
      // note_id: note.id,
      type: element.type,
      note_text: element.text,
      date: element.date,
      chapter_name: element.chapter,
      indexes: element.index,
      user: element.user,
      note_id: element.note_id,
    };

    // Create the request payload
    const requestPayload = {
      user_id: userInfo.user_id,
      book_id: bookid,
      [handle_key]: [notePayload],
    };

    try {
      const response = await fetch(
        `${baseURL}/api/manuscript/save_user_book_notes_highlights`,
        {
          method: 'POST',
          body: JSON.stringify(requestPayload),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${localStorage.getItem('id_token')}`,
            'Correlation-ID': correlationId, // Attach Correlation ID here
          },
        }
      );

      if (!response.ok) {
        throw new Error('Something went wrong! SEND TO COMPARE ITEMS');
      }

      // Handle success
    } catch (error) {
      console.error(
        `Error: ${error.message}. Correlation ID: ${correlationId}`
      );
    }
  };

  const save_user_book_notes_highlights_delete_notes = (element, bookid) => {
    const correlationId = generateCorrelationId(); // Generate Correlation ID once and store it

    const handle_key = 'delete_notes';
    const notePayload = {
      type: element.type,
      note_id: element.note_id,
    };

    // Create the request payload
    const requestPayload = {
      user_id: userInfo.user_id,
      book_id: bookid,
      [handle_key]: [notePayload],
    };

    console.log(requestPayload);

    const xhr = new XMLHttpRequest();

    xhr.open(
      'POST',
      `${baseURL}/api/manuscript/save_user_book_notes_highlights`,
      false
    );
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('Authorization', localStorage.getItem('id_token'));
    xhr.setRequestHeader('Correlation-ID', correlationId); // Attach Correlation ID here

    try {
      xhr.send(JSON.stringify(requestPayload));

      if (xhr.status !== 200) {
        throw new Error('Something went wrong! SEND TO COMPARE ITEMS');
      }

      // Handle success
    } catch (error) {
      console.error(
        `Error: ${error.message}. Correlation ID: ${correlationId}`
      );
    }
  };

  const Deletemanuscript = async (bookd, delfiles) => {
    const correlationId = generateCorrelationId(); // Generate Correlation ID once and store it

    // const refreshManuscriptList = manuscriptInfoList;
    // setManuscriptInfoList([]);
    // setManuscriptInfoList(refreshManuscriptList)
    setPendingProcessing(1);
    let books;
    if (delfiles) {
      books = bookd;
    } else {
      books = [bookd]; // Replace [clickedmanuscript.book_id] with the actual book_id value
    }
    const requestPayload = {
      user_id: userInfo.user_id,
      delete_manuscript_info: [
        {
          book_ids: books, // Replace [clickedmanuscript.book_id] with the actual book_id value
        },
      ],
    };
    try {
      const response = await fetch(
        `${baseURL}/api/manuscript/manuscript_info`,
        {
          method: 'POST',
          body: JSON.stringify(requestPayload),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${localStorage.getItem('id_token')}`,
            'Correlation-ID': correlationId, // Attach Correlation ID here
          },
        }
      );

      if (!response.ok) {
        throw new Error('Something went wrong! SEND TO COMPARE ITEMS');
      }
      //add get manuscript info here
      getManuscriptsInfo(true);
    } catch (error) {
      console.error(
        `Error: ${error.message}. Correlation ID: ${correlationId}`
      );

      setPendingProcessing(0);
    }
  };

  const EditmanuscriptApi = async (requestPayload) => {
    const correlationId = generateCorrelationId(); // Generate Correlation ID once and store it

    try {
      const response = await fetch(
        `${baseURL}/api/manuscript/manuscript_info`,
        {
          method: 'POST',
          body: JSON.stringify(requestPayload),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${localStorage.getItem('id_token')}`,
            'Correlation-ID': correlationId, // Attach Correlation ID here
          },
        }
      );

      if (!response.ok) {
        throw new Error('Something went wrong! SEND TO COMPARE ITEMS');
      }
      //add get manuscript info here
      getManuscriptsInfo(false);
    } catch (error) {
      console.error(error);
      setPendingProcessing(0);
    }
  };

  ////////////////done action on a specific book/////////////////////

  ////////////////////REQUEST////////////////////////
  const handleUploadFile = async (file, index) => {
    const correlationId = generateCorrelationId(); // Generate Correlation ID once and store it

    if (!file) {
      return;
    }
    try {
      const userId = userInfo.user_id;
      const fileName = file.name.split('.')[0];
      const fileExtension = file.name.split('.').pop();
      const urlUpload2s3 = `manuscripts/${userId}_${fileName}_${Date.now()}.${fileExtension}`;
      const encodedUrl = encodeURIComponent(urlUpload2s3);

      const url = `https://${REACT_APP_API_ID}.execute-api.us-east-1.amazonaws.com/${REACT_APP_BUILD_ENV}/${REACT_APP_S3_BUCKETNAME}/${encodedUrl}`;

      // Determine MIME type based on the file extension
      const mimeTypes = {
        txt: 'text/plain',
        pdf: 'application/pdf',
        epub: 'application/epub+zip',
        rtf: 'application/rtf',
        doc: 'application/msword',
        docx: 'application/binary',
      };
      const mimeType = mimeTypes[fileExtension] || 'application/octet-stream'; // Default MIME type if unknown

      const newFileInfo = {
        id: index,
        manuscript_name: fileName,
        original_filename: fileName,
        author_name: '',
        author_email: '',
        status: 'Action Required',
        encoded_upload_url: url,
        image: bookd,
        img: null,
      };

      const fileData = await file.arrayBuffer();

      // Form the request payload
      const formedFile = new Blob([fileData], { type: mimeType });

      const response = await fetch(url, {
        method: 'PUT',
        // body: file uless docx fileExtension
        body:
          fileExtension === 'docx' || fileExtension === 'doc'
            ? formedFile
            : file,
        headers: {
          'Content-Type': mimeType,
          Authorization: `${localStorage.getItem('id_token')}`,
          // 'Correlation-ID': correlationId,  // Attach Correlation ID here
        },
      });
      if (!response.ok) {
        throw new Error('Something went wrong! SEND TO COMPARE ITEMS');
      }
      // Update the state with the new file object
      setFileInfo((prevState) => [...prevState, newFileInfo]);
    } catch (error) {
      // alert error message with the name of the file
      renderCenteredAlert(
        `Error uploading file ${file.name}. ${error.message} correlationId: ${correlationId}`
      );
    }
  };

  const handleUploadFileImage = async (file) => {
    const correlationId = generateCorrelationId(); // Generate Correlation ID once and store it

    if (!file) {
      return;
    }
    try {
      const userId = userInfo.user_id;
      const imageName = file.name.split('.')[0] || 'imag';
      const fileExtension = file.name.split('.').pop();
      const urlUpload2s3 = `imgs/manuscripts/${userId}_${imageName}_${Date.now()}.${fileExtension}`;
      const encodedUrl = encodeURIComponent(urlUpload2s3);

      const url = `https://${REACT_APP_API_ID}.execute-api.us-east-1.amazonaws.com/${REACT_APP_BUILD_ENV}/${REACT_APP_S3_BUCKETNAME}/${encodedUrl}`;

      const response = await fetch(url, {
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': 'application/binary',
          Authorization: `${localStorage.getItem('id_token')}`,
          // 'Correlation-ID': correlationId,  // Attach Correlation ID here
        },
      });
      if (!response.ok) {
        throw new Error('Something went wrong! SEND TO COMPARE ITEMS');
      }

      return url; // Return the uploaded image URL
    } catch (error) {
      // alert error message with the name of the file
      renderCenteredAlert(
        `Error uploading file ${file.name}. ${error.message} correlationId: ${correlationId}`
      );
      return null;
    }
  };

  const get_autor_info = async (bookd) => {
    const correlationId = generateCorrelationId(); // Generate Correlation ID once and store it

    try {
      const bookId = bookd;
      const requestPayload = {
        book_id: bookId,
      };
      const url = `${baseURL}/api/manuscript/get_author_info`;
      const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(requestPayload),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${localStorage.getItem('id_token')}`,
          'Correlation-ID': correlationId, // Attach Correlation ID here
        },
        'Correlation-ID': correlationId, // Attach Correlation ID here
      });
      if (!response.ok) {
        throw new Error(
          'Something went wrong! author information is not available'
        );
      }

      const data = await response.json();

      if (!data || Object.keys(data).length === 0) {
        console.log('Author details not found for book_id: ' + bookId);
        setAuthorInfo(null);
        return;
      }
      if (data.error) {
        console.log('Author details not found for book_id: ' + bookId);
        setAuthorInfo(null);
        return;
      }
      setAuthorInfo(data);
    } catch (error) {
      console.error(error);
    }
  };

  const [ingestionDetails, setIngestionDetails] =
    useRecoilState(ingestDetailsState);
  //  get get_manuscript_ingestion_details
  const get_manuscript_ingestion_details = async (bookd) => {
    const correlationId = generateCorrelationId(); // Generate Correlation ID once and store it

    try {
      const bookId = bookd;
      const requestPayload = {
        book_id: bookId,
      };
      const url = `${baseURL}/api/manuscript/get_manuscript_ingestion_details`;
      const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(requestPayload),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${localStorage.getItem('id_token')}`,
          'Correlation-ID': correlationId, // Attach Correlation ID here
        },
      });
      if (!response.ok) {
        throw new Error('Something went wrong! SEND TO COMPARE ITEMS');
      }
      const data = await response.json();
      setIngestionDetails(data);
    } catch (error) {
      console.error(
        `Error: ${error.message}. Correlation ID: ${correlationId}`
      );
    }
  };

  const get_book_embbedings = async (bookd) => {
    const correlationId = generateCorrelationId(); // Generate Correlation ID once and store it

    try {
      const bookId = bookd;
      const requestPayload = {
        // book_id: bookId,
        book_id: 927,
      };
      const url = `https://${REACT_APP_API_ID}.execute-api.us-east-1.amazonaws.com/${REACT_APP_BUILD_ENV}/getbookembeddings01`;
      const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(requestPayload),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${localStorage.getItem('id_token')}`,
          'Correlation-ID': correlationId, // Attach Correlation ID here
        },
      });
      if (!response.ok) {
        throw new Error('Something went wrong! SEND TO COMPARE ITEMS');
      }
      const data = await response.json();
      setEmbeddingsData(data.embeddings);
    } catch (error) {
      console.error(
        `Error: ${error.message}. Correlation ID: ${correlationId}`
      );
    }
  };

  const get_conversation_history = async (bookd, user) => {
    const correlationId = generateCorrelationId(); // Generate Correlation ID once and store it

    //clear the content
    setChatHistoryMessages([]);
    setShowChat(false);
    try {
      const bookId = bookd;
      const userId = user || userInfo.user_id;
      const requestPayload = {
        user_id: userId,
        book_id: bookd,
        // user_id: 1,
        // book_id: 927,
      };
      const url = `${baseURL}/api/manuscript/get_conversation_history`;
      const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(requestPayload),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${localStorage.getItem('id_token')}`,
          'Correlation-ID': correlationId, // Attach Correlation ID here
        },
      });
      if (!response.ok) {
        throw new Error('Something went wrong! SEND TO COMPARE ITEMS');
      }
      const data = await response.json();
      // Parse chat history into question and answer elements
      // const parsedChatHistory = data.chat_history.map((item) => {
      //   const [question, answer] = item.replace(/["\\]/g, '').split(',');
      //   return { question: question.trim().substring(1,), answer: answer.trim().substring(0, answer.length - 1) };
      // });
      // setChatHistoryMessages(parsedChatHistory);

      // Process the chat history data into the desired format

      // Process the chat history data into the desired format
      const processedChatHistory = data.chat_history.map(
        ([question, answer]) => ({
          question,
          answer,
        })
      );
      //  data.chat_history.map((item) => {
      //   const [question, answer] = item.map((str) => str.replace(/^\s+/, ''));
      //   return { question, answer };
      // });

      setChatHistoryMessages(processedChatHistory);
    } catch (error) {
      console.error(
        `Error: ${error.message}. Correlation ID: ${correlationId}`
      );
    }
  };

  const Book_Chat = async (bookd, question) => {
    const correlationId = generateCorrelationId(); // Generate Correlation ID once and store it

    let prompt = [];
    if (question !== '') {
      prompt = [
        ...chatHistoryMessages.map((item) => [item.question, item.answer]), // Convert chatHistory to the required format
        question, // Add the new question to the end
      ];
    }
    // const updatedChatHistory = [...chatHistoryMessages, { question: question, answer: "" }];
    // setChatHistoryMessages(updatedChatHistory);
    try {
      const bookId = bookd;
      const requestPayload = {
        user_id: userInfo.user_id,
        book_id: bookd,
        // user_id: 1,
        // book_id: 927,
        chat_history: prompt,
      };
      const url = `${baseURL}/api/manuscript/book_chat`;
      const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(requestPayload),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${localStorage.getItem('id_token')}`,
          'Correlation-ID': correlationId, // Attach Correlation ID here
        },
      });
      if (!response.ok) {
        throw new Error('Something went wrong! SEND TO COMPARE ITEMS');
      }
      const data = await response.json();

      if (question !== '') {
        const updatedChatHistory = [
          ...chatHistoryMessages,
          { question: question, answer: data.answer },
        ];
        setChatHistoryMessages(updatedChatHistory);
      }
    } catch (error) {
      console.error(
        `Error: ${error.message}. Correlation ID: ${correlationId}`
      );
    }
  };

  const change_book_status = async (bookd, status) => {
    const correlationId = generateCorrelationId(); // Generate Correlation ID once and store it

    try {
      const correlationId = generateCorrelationId(); // Generate Correlation ID once and store it

      const bookId = bookd;
      const requestPayload = {
        user_id: userInfo.user_id,
        book_id: bookd,
        new_book_status: status,
      };
      const url = `${baseURL}/api/manuscript/change_status`;
      const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(requestPayload),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${localStorage.getItem('id_token')}`,
          'Correlation-ID': correlationId, // Attach Correlation ID here
        },
      });
      if (!response.ok) {
        throw new Error('Something went wrong! SEND TO COMPARE ITEMS');
      }
      if (status === 'Action Required') {
        addApproveRejectNotes(userInfo.name, 'Action Required', bookd);
      }
      // set the new status to clickedManuscript

      getManuscriptsInfo(false);
    } catch (error) {
      console.error(
        `Error: ${error.message}. Correlation ID: ${correlationId}`
      );
    }
  };

  const searchManuscripts = async (query, company_id) => {
    const correlationId = generateCorrelationId(); // Generate Correlation ID once and store it

    try {
      const correlationId = generateCorrelationId(); // Generate Correlation ID once and store it

      const requestPayload = {
        q: query,
        company_id: company_id.toString(),
      };
      const url = `${baseURL}/api/manuscript/search_manuscripts`;
      const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(requestPayload),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${localStorage.getItem('id_token')}`,
          'Correlation-ID': correlationId, // Attach Correlation ID here
        },
      });
      if (!response.ok) {
        throw new Error('Something went wrong in search manuscripts request!');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error(
        `Error: ${error.message}. Correlation ID: ${correlationId}`
      );
      return { error: error.message };
    }
  };

  const chatManuscripts = async (query, company_id, thread_id) => {
    const correlationId = generateCorrelationId(); // Generate Correlation ID once and store it
    let newThread = false;

    try {
      // no thread yet
      if (thread_id === null || thread_id === '') {
        newThread = true;
        createNewThreadPlaceholder(query);
      } else {
        addQuestionToThread(thread_id, query);
      }

      const requestPayload = {
        q: query,
        company_id: company_id.toString(),
        thread_id: thread_id,
      };
      const url = `${baseURL}/api/manuscript/chat_manuscripts`;
      const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(requestPayload),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${localStorage.getItem('id_token')}`,
          'Correlation-ID': correlationId, // Attach Correlation ID here
        },
      });
      if (!response.ok) {
        throw new Error(
          'Something went wrong with the chat request.\n When contacting us please send this ID: ' +
            correlationId
        );
      }
      const data = await response.json();

      if (newThread) {
        removeNewThreadPlaceholder();
        addQuestionToThread(data.threadId, query);
      }
      addAnswerToThread(data.threadId, data.response, data.bookIds);

      return { success: true };
    } catch (error) {
      console.error(
        `Error: ${error.message}. Correlation ID: ${correlationId}`
      );
      return { error: error.message };
    }
  };

  const createNewThreadPlaceholder = (newQuestion) => {
    setCopilotState((prevState) => {
      const existingThreadIndex = prevState.threads.findIndex(
        (thread) => thread.id === 'new_thread'
      );

      const updatedThreads = [...prevState.threads];

      if (existingThreadIndex > -1) {
        // Update existing thread by appending new question
        updatedThreads[existingThreadIndex] = {
          ...updatedThreads[existingThreadIndex],
          questions: [newQuestion],
        };
      } else {
        // Create a new thread if it does not exist, and add it to the beginning
        updatedThreads.unshift({
          id: 'new_thread',
          questions: [newQuestion],
          answers: [],
          bookIds: [],
        });
      }

      return { ...prevState, threads: updatedThreads };
    });
  };

  const removeNewThreadPlaceholder = () => {
    setCopilotState((prevState) => {
      const updatedThreads = prevState.threads.filter(
        (thread) => thread.id !== 'new_thread'
      );

      return { ...prevState, threads: updatedThreads };
    });
  };

  const addQuestionToThread = (threadId, newQuestion) => {
    setCopilotState((prevState) => {
      const existingThreadIndex = prevState.threads.findIndex(
        (thread) => thread.id === threadId
      );

      const updatedThreads = [...prevState.threads];

      if (existingThreadIndex > -1) {
        // Update existing thread by appending new question
        updatedThreads[existingThreadIndex] = {
          ...updatedThreads[existingThreadIndex],
          questions: [
            ...updatedThreads[existingThreadIndex].questions,
            newQuestion,
          ],
        };
      } else {
        // Create a new thread if it does not exist, and add it to the beginning
        updatedThreads.unshift({
          id: threadId,
          questions: [newQuestion],
          answers: [],
          bookIds: [],
        });
      }

      return { ...prevState, threads: updatedThreads };
    });
  };

  const addAnswerToThread = (threadId, newAnswer, newBookIds) => {
    setCopilotState((prevState) => {
      const existingThreadIndex = prevState.threads.findIndex(
        (thread) => thread.id === threadId
      );

      const updatedThreads = [...prevState.threads];

      if (existingThreadIndex > -1) {
        // Update existing thread by appending new answer
        updatedThreads[existingThreadIndex] = {
          ...updatedThreads[existingThreadIndex],
          answers: [...updatedThreads[existingThreadIndex].answers, newAnswer],
          bookIds: [...updatedThreads[existingThreadIndex].bookIds, newBookIds],
        };
      } else {
        // Create a new thread if it does not exist
        updatedThreads.push({
          id: threadId,
          questions: [],
          answers: [newAnswer],
          bookIds: [newBookIds],
        });
      }

      return { ...prevState, threads: updatedThreads };
    });
  };

  const clearChats = () => {
    setCopilotState((prevState) => ({
      ...prevState,
      threads: [],
    }));
  };

  ////////////////////done  REQUEST////////////////////////

  const [books_id_dwonload, setBooks_id_dwonload] = useRecoilState(
    books_id_dwonloadState
  ); // Update the path
  const [userRejected_ApprovedNote, setUserRejected_ApprovedNote] =
    useRecoilState(userRejected_ApprovedNoteState);
  const [selectedChapter, setSelectedChapter] =
    useRecoilState(selectedChapterAtom);
  const [loadingState, setLoadingState] = useRecoilState(loadingStateCompare);
  const [file, setFile] = useRecoilState(fileState);
  const [startPolling, setStartPolling] = useRecoilState(startPollingState); // Update the path

  const emptyAllStates = () => {
    setUserInfo(null);
    setClickedManuscript([]);
    setCurrentManuscript([]);
    setHeaderColumnsTranslation([]);
    setPendingProcessing(0);
    setLoadingState(false);
    setLoadingmanuscript(false);
    setGenresData({});
    setKeywords([]);
    setGenres([]);
    setNotes([]);
    setManuscriptInfoList([]);
    setContent([]);
    setChapterNames([]);
    setScoreData({});
    setFile([]);
    setStartPolling(false);
    setSelectedChapter(0);
    setFileInfo([]);
    setIdToken(null);
    setAccessToken(null);
    setNotes_highlights([]);
    setNumberOfUploadedFiles(0);
    setMost_similar_book([]);
    setUserRejected_ApprovedNote([]);
    setBooks_id_dwonload([]);
    setCompanyInfo(null);
  };

  const handleLogoutClick = () => {
    emptyAllStates();

    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.signOut();
    }

    // Clear tokens and user-related data from localStorage
    localStorage.removeItem('access_token');
    localStorage.removeItem('access_token_expiration');
    localStorage.removeItem('id_token');
    localStorage.removeItem('id_token_expiration');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('userId');
    localStorage.removeItem('email');
    localStorage.clear();
  };

  return {
    getManuscriptsInfo,
    initialgetBookChapter,
    get_book_scores,
    getbookgenrekeywords,
    handleCompareApp,
    sendRejection,
    sendApprove,
    get_user_info,
    getCompanyInformation,
    processResponse,
    save_user_book_notes_highlights_add_notes,
    save_user_book_notes_highlights_edit_notes,
    save_user_book_notes_highlights_delete_notes,
    sendinvitationemail,
    handleRegisterStorywise,
    Deletemanuscript,
    setPreferanses,
    uploadmanuscript,
    handleUploadFile,
    handleUploadFileImage,
    EditmanuscriptApi,
    editColomnTable,
    downloadManuscripts,
    get_most_similar_books,
    uploadMethod,
    AssignUser,
    emailTemplate,
    get_autor_info,
    get_book_embbedings,
    get_conversation_history,
    Book_Chat,
    change_book_status,
    handleLogoutClick,
    downloadEmail,
    handleNewUserValidation,
    editusersettings: editusersconfig,
    get_manuscript_ingestion_details,
    searchManuscripts,
    chatManuscripts,
    clearChats,
  };
};

export default useApiUtils;
