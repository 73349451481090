import './App.css';
import './global.css';
import ComparePage from './components/Compare/ComparePage';
import ReviwePage from './components/Review/ReviwePage';
import SubmissionsPage from './components/submission/SubmissionsPage';
import EditPage from './components/editing/EditPage';
import DiscoverPage from './components/discover/DiscoverPage';

import { useRecoilState } from 'recoil';
import {
  idTokeneState,
  accessTokenState,
  userInfoState,
  pendingProcessingState,
  initialHighlightState,
  loadingStateCompare,
  currentManuscriptAtom,
  afterGetBookChapter,
  clickedManuscriptAtom,
  genresState,
  keywordsState,
  genresDataState,
  scoreDataAtom,
  chapterNamesAtom,
  contentAtom,
  notesAtom,
  manuscriptInfoListAtom,
} from './state';
import React from 'react';
import UploadFiles from './components/uploadManuscript/UploadFiles';
import NavigationBar from './NavigationBar';
// import { BrowserRouter,Route,Router,Routes, useNavigate } from "react-router-dom";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './components/home/Login';
import ForgotPassword from './components/home/ForgotPassword';
import ResetPassword from './components/home/ResetPassword';
import SignUp from './components/home/SignUp';
import SetPreferences from './components/home/SetPreferences';
import Adduser from './components/home/Adduser';
import PrivacyPolicy from './components/propileInfo/PrivacyPolicy';

import useApiUtils from './useApiUtils';
import UserMenuDropdown from './components/propileInfo/UserMenuDropdown';

import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
  CognitoRefreshToken,
} from 'amazon-cognito-identity-js';
import { useEffect, useState } from 'react';

const addHotjarScript = () => {
  const script = document.createElement('script');
  script.async = true;
  script.innerHTML = `
    (function (h, o, t, j, a, r) {
      h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments) };
      h._hjSettings = { hjid: 3923768, hjsv: 6 };
      a = o.getElementsByTagName('head')[0];
      r = o.createElement('script'); r.async = 1;
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      a.appendChild(r);
    })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
  `;
  document.head.appendChild(script);
};

const addGoogleTagScript = () => {
  // Create script to load gtag.js
  const script1 = document.createElement('script');
  script1.async = true;
  script1.src = `https://www.googletagmanager.com/gtag/js?id=G-PC1V3GMHBJ`;
  document.head.appendChild(script1);

  // Create script to initialize gtag
  const script2 = document.createElement('script');
  script2.innerHTML = `
    window.dataLayer = window.dataLayer || [];
    function gtag() { dataLayer.push(arguments); }
    gtag('js', new Date());

    gtag('config', 'G-PC1V3GMHBJ');
  `;
  document.head.appendChild(script2);
};

const REACT_APP_BUILD_ENV =
  process.env.REACT_APP_BUILD_ENV || 'REACT_APP_BUILD_ENV-build_env';
const REACT_APP_API_ID =
  process.env.REACT_APP_API_ID || 'REACT_APP_BUILD_ENV2-build_env';

const poolData = {
  UserPoolId: process.env.REACT_APP_USER_POOL_ID || 'USER_POOL_ID-build_env',
  ClientId:
    process.env.REACT_APP_CLIENT_ID_USER_POOL ||
    'CLIENT_ID_USER_POOL-build_env',
};

const userPool = new CognitoUserPool(poolData);

function App() {
  const [userInfo, setUserInfo] = useRecoilState(userInfoState); // Update the path

  const [isInitialRender, setInitialRender] = useState(true);

  const {
    getManuscriptsInfo,
    initialgetBookChapter,
    get_book_scores,
    getbookgenrekeywords,
    get_user_info,
    getCompanyInformation,
    processResponse,

    // Add other functions from the hook if needed
  } = useApiUtils();

  React.useEffect(() => {
    const REACT_APP_BUILD_ENV =
      process.env.REACT_APP_BUILD_ENV || 'REACT_APP_BUILD_ENV-build_env';

    const REACT_APP_API_ID =
      process.env.REACT_APP_API_ID || 'REACT_APP_BUILD_ENV2-build_env';

    if (isInitialRender) {
      if (localStorage.getItem('email')) {
        get_user_info();
        setInitialRender(false);
      }
    }
  }, []);

  // Define the refresh interval (1 hour in milliseconds)
  const refreshInterval = 60 * 60 * 1000; // 1 hour

  // Function to refresh the session
  const refreshSession = async () => {
    if (!localStorage.getItem('email')) {
      return;
    }
    const idTokenExpiration = localStorage.getItem('id_token_expiration');
    const currentTime = new Date().getTime() / 1000; // Convert to seconds

    // Check if the ID token is about to expire (e.g., 1 minute before)
    if (idTokenExpiration - currentTime < 60) {
      const cognitoUser = new CognitoUser({
        Username: localStorage.getItem('email'),
        Pool: userPool,
      });
      try {
        const token = new CognitoRefreshToken({
          RefreshToken: localStorage.getItem('refresh_token'),
        });
        cognitoUser.refreshSession(token, (err, session) => {
          if (err) {
            console.error('Error refreshing session:', err);
            // remove tokens from local storage
            localStorage.removeItem('access_token');
            localStorage.removeItem('access_token_expiration');
            localStorage.removeItem('id_token');
            localStorage.removeItem('id_token_expiration');
            localStorage.removeItem('refresh_token');
            localStorage.removeItem('userId');

            // Handle the error, e.g., show a message to the user or log them out
          } else {
            // Update tokens in local storage
            localStorage.setItem(
              'access_token',
              session.getAccessToken().getJwtToken()
            );
            localStorage.setItem(
              'access_token_expiration',
              session.getAccessToken().getExpiration()
            );
            localStorage.setItem(
              'id_token',
              session.getIdToken().getJwtToken()
            );
            localStorage.setItem(
              'id_token_expiration',
              session.getIdToken().getExpiration()
            );
            localStorage.setItem('userId', session.getIdToken().payload.sub);
            localStorage.setItem(
              'refresh_token',
              session.getRefreshToken().getToken()
            );
            if (localStorage.getItem('email')) {
              get_user_info();
            }
          }
        });
      } catch (e) {}
    }
  };

  useEffect(() => {
    const env =
      process.env.REACT_APP_BUILD_ENV || 'REACT_APP_BUILD_ENV-build_env';
    if (env === 'live') {
      addHotjarScript();
      addGoogleTagScript();
    }
  }, []);

  // Initialize session refresh on component mount
  useEffect(() => {
    // Refresh the session immediately (on component mount)
    refreshSession();

    // Set up an interval to refresh the session periodically
    const refreshIntervalId = setInterval(refreshSession, refreshInterval);

    // Clear the interval when the component unmounts (cleanup)
    return () => clearInterval(refreshIntervalId);
  }, []);

  return (
    <div>
      {/* <HomePage1/> */}
      {/* <UncoverLeft/> */}
      <Router>
        <div>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/set-preferences" element={<SetPreferences />} />
            <Route path="/add-user" element={<Adduser />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            {/* {authenticated ? ( */}
            {/* <> */}
            <Route path="/user-profile" element={<UserMenuDropdown />} />
            <Route path="/editing" element={<EditPage />} />
            <Route path="/review/:bookId" element={<ReviwePage />} />
            {/* <Route path="/compare" element={<ComparePage />} /> */}
            <Route path="/compare/:bookId" element={<ComparePage />} />
            <Route path="/upload" element={<UploadFiles />} />
            <Route path="/submissions" element={<SubmissionsPage />} />
            <Route path="/discover" element={<DiscoverPage />} />
            {/* </> */}
            {/* ) : null} */}
          </Routes>
        </div>
      </Router>
    </div>
  );
}

export default App;
