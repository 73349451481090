import styles from './UploadFiles.module.css';
import storyWiswLogo from '../../icons/storywise.svg';
import book from '../../icons/book.svg';
import deff from '../../icons/deff.svg';
import man_pancel from '../../icons/man_pancel.svg';
import bookd from '../../icons/bookd.svg';
import Upload from './Upload';
import { useState } from 'react';
import vvv from '../../icons/vuesax/outline/tick-square.svg';
import TableUpload from './TableUpload';
import Edit from './manuOption/Edit';
import EditChain from './EditChain';
import Delete from './manuOption/Delete';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { fileState, fileInfoState } from '../../state';
import useApiUtils from '../../useApiUtils';
import Header from '../header/header';

const UploadFiles = (props) => {
  const [wasUploaded, setWasUploaded] = useState(false);
  const [edit, setEdit] = useState(false);
  const [deleteManu, setDeleteManu] = useState(false);
  const [files, setFiles] = useRecoilState(fileState);
  const [fileInfo, setFileInfo] = useRecoilState(fileInfoState);
  const [editChain, setEditChain] = useState(false);

  const navigate = useNavigate();

  const {
    getManuscriptsInfo,
    initialgetBookChapter,
    get_book_scores,
    getbookgenrekeywords,
    get_user_info,
    getCompanyInformation,
    setPreferanses,
    handleUploadFile,

    // Add other functions from the hook if needed
  } = useApiUtils();

  const handleNavigation = () => {
    setFileInfo([]);
    setFiles([]);
    navigate('/submissions');
  };

  const expendmanu = () => {
    getCompanyInformation();
    navigate('/user-profile');
  };

  const handleEdit = (ans) => {
    setEdit(ans);
  };

  const handleDelete = (ans) => {
    setDeleteManu(ans);
  };

  const handleUploaded = () => {
    setWasUploaded(true);
  };

  const handlAddFileUpload = () => {
    setFiles([]);
    setWasUploaded(false);
  };

  // useEffect(() => {
  //     // Iterate through all files and send them to handleUploadFile
  //     const prevLength = fileInfo.length;
  //     files.forEach((file, index) => {
  //         handleUploadFile(file,prevLength+ index);
  //     });

  //     //clear the files array
  //     // setFiles([]);
  // }, [files]);

  return (
    <div className={styles.container}>
      <Header issubmission={false} issettings={false} />

      {edit && <Edit handleEdit={handleEdit} />}
      {deleteManu && <Delete handleDelete={handleDelete} />}
      {editChain && <EditChain handleEdit={setEditChain} />}
      <div className={styles.uploadContainer}>
        <div className={styles.AddLaftContainer}>
          <div className={styles.textPart}>
            <div className={styles.uploadAddText}>Add New Manuscript</div>
            <div className={styles.listPart}>
              <div className={styles.Step}>
                {!wasUploaded && (
                  <div className={styles.UploadSteps}>
                    <div className={styles.Step1}>1</div>
                  </div>
                )}
                {wasUploaded && (
                  <div className={styles.UploadStepswasUploaded}>
                    <img className={styles.Step1wasUploaded} src={vvv} />
                  </div>
                )}
                <div className={styles.UploadFilesSteps}>Upload Files</div>
              </div>
              <div className={styles.Step}>
                {!wasUploaded && (
                  <div className={styles.ReviewSteps}>
                    <div className={styles.Step2}>2</div>
                  </div>
                )}
                {wasUploaded && (
                  <div className={styles.ReviewStepswasUploaded}>
                    <div className={styles.Step2wasUploaded}>2</div>
                  </div>
                )}
                <div className={styles.UploadFilesSteps}>Review Files</div>
              </div>
            </div>
          </div>
          <div className={styles.image_man}>
            <img
              className={styles.image_man_src}
              style={{ width: '190px' }}
              src={man_pancel}
            />
          </div>
        </div>

        <div className={styles.uploadPart}>
          {!wasUploaded && (
            <Upload wasUploaded={handleUploaded} setEditChain={setEditChain} />
          )}
          {wasUploaded && (
            <TableUpload
              wasUploaded={handlAddFileUpload}
              handleDelete={handleDelete}
              handleEdit={handleEdit}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default UploadFiles;
