import React, { useState, useEffect } from 'react';
// import { useHistory } from 'react-router-dom';
import Line from '../..//icons/AUTH/signin/Line 2.svg';
import Line2 from '../..//icons/AUTH/signin/Line 3.svg';
import { useLocation, useNavigate } from 'react-router-dom';

import men from '../..//icons/AUTH/signin/men.svg';
import step2 from '../..//icons/AUTH/signin/curentstep2.svg';
import { Link, Navigate } from 'react-router-dom';

import MuiPhoneNumber from 'mui-phone-number';
import send_invite from '../../icons/AUTH/signin/button/text/primary/small/iconSend_invite.svg';
import Select from 'react-select'; // Import react-select
import useApiUtils from '../../useApiUtils';
import CircularProgress from '@mui/material/CircularProgress';
//    min-width: 10vw;

import { useRecoilState } from 'recoil';
import {
  companyInfoState,
  userInfoState,
  preferencesState,
  emailTemplateState,
  manuscriptsUploadsState,
  UserRightsandRolesState,
} from '../../state';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import dots from '../../icons/vuesax/outline/UniononlyDots.svg';

import styles from './ManegUsers.module.css';
import { renderCenteredAlert, renderCenteredInfo } from '../CenteredAlert';

const ManegUsers = (props) => {
  const REACT_APP_BUILD_ENV =
    process.env.REACT_APP_BUILD_ENV || 'REACT_APP_BUILD_ENV-build_env';

  const [usersList, setUsersList] = useState([]);
  const [phoneKey, setPhoneKey] = useState(0); // Add a key for MuiPhoneNumber
  const [userInfo, setUserInfo] = useRecoilState(userInfoState); // Update the path
  const [companyInfo, setCompanyInfo] = useRecoilState(companyInfoState);

  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [fullNameError, setFullNameError] = useState('');
  const [emailError, setEmailError] = useState('');

  const [phoneError, setPhoneError] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [roleError, setRoleError] = useState('');
  const navigate = useNavigate();
  const [saveButtonState, setSaveButtonState] = useState('normal'); // 'normal', 'saving', 'success', 'failure'

  const {
    getManuscriptsInfo,
    initialgetBookChapter,
    get_book_scores,
    getbookgenrekeywords,
    get_user_info,
    getCompanyInformation,
    sendinvitationemail,

    // Add other functions from the hook if needed
  } = useApiUtils();

  const roleOptions = [
    { value: 'editor', label: 'Editor' },
    { value: 'manager', label: 'Manager' },
  ];

  const handleNextClick = async () => {
    if (userInfo.role === 'lvl1') {
      props.showErrorMessage();
      renderCenteredAlert('You are not authorized to perform this action');
      return;
    }
    if (saveButtonState === 'loading') {
      return;
    }
    let newUser = null;
    let invaiteNweUser = null;
    if (fullName || email || phone || selectedRole) {
      setFullNameError('');
      setEmailError('');
      setPhoneError('');
      setRoleError('');

      if (!fullName) {
        setFullNameError(
          'Please enter  full name and complete the form or clear the form'
        );
        return;
      } else if (!email) {
        setEmailError(
          'Please enter  email and complete the form or clear the form'
        );
        return;
      } else if (!phone) {
        setPhoneError(
          'Please enter   number and complete the form or clear the form'
        );
        return;
      } else if (!selectedRole) {
        setRoleError(
          'Please select a role and complete the form or clear the form'
        );
        return;
      }
      newUser = {
        fullName,
        email,
        phone,
        role: selectedRole ? selectedRole.label : '',
      };
    }

    setSaveButtonState('loading');
    // Construct the invitation data
    const company_name = userInfo.company_name;
    const companyid = userInfo.company_id;
    let invitationData;
    if (REACT_APP_BUILD_ENV === 'live') {
      if (newUser) {
        invaiteNweUser = `http://publishers.storywise.ai/signup?fullName=${encodeURIComponent(
          newUser.fullName
        )}&email=${encodeURIComponent(
          newUser.email
        )}&phone=${encodeURIComponent(
          newUser.phone
        )}&companyName=${encodeURIComponent(
          userInfo.company_name
        )}&role=${encodeURIComponent(
          newUser.role
        )}&company=${encodeURIComponent(userInfo.company_id)}`;
      }
      invitationData = usersList
        .filter((user) => {
          const userWithEmail = companyInfo?.users_list?.find(
            (u) => u.email === user.email
          );
          return !userWithEmail;
        })
        .map((user) => ({
          first_name: user.fullName,
          email: user.email,
          invite_link: `http://publishers.storywise.ai/signup?fullName=${encodeURIComponent(
            user.fullName
          )}&email=${encodeURIComponent(user.email)}&phone=${encodeURIComponent(
            user.phone
          )}&companyName=${encodeURIComponent(
            userInfo.company_name
          )}&role=${encodeURIComponent(user.role)}&company=${encodeURIComponent(
            userInfo.company_id
          )}`,
        }));
    } else if (REACT_APP_BUILD_ENV === 'dev') {
      if (newUser) {
        invaiteNweUser = `http://dev-publishers.storywise.ai/signup?fullName=${encodeURIComponent(
          newUser.fullName
        )}&email=${encodeURIComponent(
          newUser.email
        )}&phone=${encodeURIComponent(
          newUser.phone
        )}&companyName=${encodeURIComponent(
          userInfo.company_name
        )}&role=${encodeURIComponent(
          newUser.role
        )}&company=${encodeURIComponent(userInfo.company_id)}`;
      }
      invitationData = usersList
        .filter((user) => {
          const userWithEmail = companyInfo?.users_list?.find(
            (u) => u.email === user.email
          );
          return !userWithEmail;
        })
        .map((user) => ({
          first_name: user.fullName,
          email: user.email,
          invite_link: `http://dev-publishers.storywise.ai/signup?fullName=${encodeURIComponent(
            user.fullName
          )}&email=${encodeURIComponent(user.email)}&phone=${encodeURIComponent(
            user.phone
          )}&companyName=${encodeURIComponent(
            userInfo.company_name
          )}&role=${encodeURIComponent(user.role)}&company=${encodeURIComponent(
            userInfo.company_id
          )}`,
        }));
    } else if (REACT_APP_BUILD_ENV === 'staging') {
      if (newUser) {
        invaiteNweUser = `http://staging-publishers.storywise.ai/signup?fullName=${encodeURIComponent(
          newUser.fullName
        )}&email=${encodeURIComponent(
          newUser.email
        )}&phone=${encodeURIComponent(
          newUser.phone
        )}&companyName=${encodeURIComponent(
          userInfo.company_name
        )}&role=${encodeURIComponent(
          newUser.role
        )}&company=${encodeURIComponent(userInfo.company_id)}`;
      }
      invitationData = usersList
        .filter((user) => {
          const userWithEmail = companyInfo?.users_list?.find(
            (u) => u.email === user.email
          );
          return !userWithEmail;
        })
        .map((user) => ({
          first_name: user.fullName,
          email: user.email,
          invite_link: `http://staging-publishers.storywise.ai/signup?fullName=${encodeURIComponent(
            user.fullName
          )}&email=${encodeURIComponent(user.email)}&phone=${encodeURIComponent(
            user.phone
          )}&companyName=${encodeURIComponent(
            userInfo.company_name
          )}&role=${encodeURIComponent(user.role)}&company=${encodeURIComponent(
            userInfo.company_id
          )}`,
        }));
    }

    if (newUser) {
      invitationData.push({
        first_name: newUser.fullName,
        email: newUser.email,
        invite_link: invaiteNweUser,
      });
    }

    if (invitationData.length === 0) {
      renderCenteredAlert('There are no new invitations to send');
      return;
    }
    try {
      await sendinvitationemail(invitationData);
      renderCenteredInfo('User Invitation Sent');
      setSaveButtonState('normal');
      //clear the form
      setSelectedRole('');
      setFullName('');
      setEmail('');
      setPhone('');

      setFullNameError('');
      setEmailError('');
      setPhoneError('');
      setRoleError('');
    } catch (error) {
      setSaveButtonState('normal');
    }

    // setSaveButtonState('failure');
    // setTimeout(() => {
    //     setSaveButtonState('normal');
    // }, 3000);
  };

  // Define the button text based on the button state
  const getButtonText = () => {
    switch (saveButtonState) {
      case 'saving':
        return 'Sending...';
      case 'success':
        return 'Sent Successfully';
      case 'failure':
        return 'Failed to Send';
      case 'loading':
        return <CircularProgress size={15} color="inherit" />;
      default:
        return (
          <>
            <img src={send_invite} className={styles.sendInvite} />
            Send Invitation
          </>
        );
    }
  };
  const handleOnPhoneChange = (value) => {
    setPhone(value);
  };

  const handleAddUser = () => {
    // Validate the input fields
    //empty the error messages
    setFullNameError('');
    setEmailError('');
    setPhoneError('');
    setRoleError('');

    if (!fullName) {
      setFullNameError('Please enter  full name');
      return;
    } else if (!email) {
      setEmailError('Please enter  email');
      return;
    } else if (!phone) {
      setPhoneError('Please enter  phone number');
      return;
    } else if (!selectedRole) {
      setRoleError('Please select a role');
      return;
    }
    const newUser = {
      fullName,
      email,
      phone,
      role: selectedRole ? selectedRole.label : '',
    };

    //setUsersList([newUser, ...usersList]);
    handleNextClick();

    // Clear input fields
    setSelectedRole('');
    setFullName('');
    setEmail('');
    setPhone('');
    // Increment the phoneKey to trigger re-render of MuiPhoneNumber
    setPhoneKey((prevKey) => prevKey + 1);
  };
  useEffect(() => {
    if (companyInfo && companyInfo?.users_list) {
      const updatedUsersList = companyInfo.users_list.map((user) => ({
        fullName: user.name,
        email: user.email,
        phone: user.phone || '',
        role: user.role === 'lvl1' ? 'Editor' : 'Manager',
      }));
      setUsersList(updatedUsersList);
    }
  }, [companyInfo]);

  const customStyles = {
    container: (provided) => ({
      ...provided,
      // padding: 0,
      // marginRight: 0,
      // marginButtom: "10px",
      // overflow: 'scroll',
      // overflow: 'visible',
    }),
    control: (provided) => ({
      ...provided,

      display: 'flex',
      padding: '0.5vh',
      justifyContent: 'space-between',
      alignItems: 'center',
      alignSelf: 'stretch',
      fontWeight: 500,
      fontSize: '14px',
      borderRadius: '16px',
      border: '1px solid var(--storywise-gainsboro, #DDDEE0)',
      background: 'var(--storywise-white, #FFF)',
      width: '35vw',
    }),
    option: (
      styles,
      { data, isDisabled, isFocused, isSelected, isHovered }
    ) => ({
      ...styles,
    }),
  };

  const handleRemoveUser = (index) => {
    const updatedList = [...usersList];
    updatedList.splice(index, 1);
    setUsersList(updatedList);
  };

  const handleEdit = (mail) => {
    const userWithEmail = companyInfo.users_list.find(
      (user) => user.email === mail
    );
    if (userWithEmail) {
      renderCenteredAlert(
        'This user already received an invitation. If you want to send a new invitation, please fill the form below. and click on send invite to add another.'
      );
    } else {
      //set field value as the selected user value
      const selectedUser = usersList.find((user) => user.email === mail);
      setFullName(selectedUser.fullName);
      setEmail(selectedUser.email);
      setPhone(selectedUser.phone);
      setSelectedRole(selectedUser.role);
      //remove the selected user from the list
      handleDelete(mail);
      // const updatedList = [...usersList];
      // const index = updatedList.findIndex((user) => user.email === mail);
      // updatedList.splice(index, 1);
      // setUsersList(updatedList);
    }
  };

  const handleDelete = (mail) => {
    const userWithEmail = companyInfo.users_list?.find(
      (user) => user.email === mail
    );
    if (userWithEmail) {
      renderCenteredAlert('This user already received an invitation.');
    } else {
      const updatedList = [...usersList];
      const index = updatedList.findIndex((user) => user.email === mail);
      updatedList.splice(index, 1);
      setUsersList(updatedList);
    }
  };

  const handleBack = () => {
    navigate('/set-preferences');
  };

  return (
    <div className={styles.logosignincontainer}>
      <div className={styles.signcontainer}>
        <div className={styles.body}>
          <div className={styles.titlelogo}>
            <div className={styles.loginparttitle}>Users</div>
          </div>

          {usersList.length === 0 && (
            <>
              {companyInfo === null ? (
                <div data-testid="loading">loading...</div>
              ) : (
                <div data-testid="no-users">
                  No users besides the administrator.
                </div>
              )}
            </>
          )}

          {usersList.length > 0 &&
            usersList.map((user, index) => (
              <div
                key={index}
                className={styles.userlistContainer}
                data-testid="user-list"
              >
                <div key={index} className={styles.userlist}>
                  <div className={styles.textpar}>
                    <div className={styles.firstrow}>
                      <div className={styles.element}>
                        <div className={styles.title}>Full Name</div>
                        <div className={styles.value} data-testid="full-name">
                          {user.fullName}
                        </div>
                      </div>
                      <div className={styles.element}>
                        <div className={styles.title}>Email</div>
                        <div className={styles.value} data-testid="email">
                          {user.email}
                        </div>
                      </div>
                    </div>
                    <div className={styles.firstrow}>
                      <div className={styles.element}>
                        <div className={styles.title}>Phone</div>
                        <div className={styles.value} data-testid="phone">
                          {user.phone}
                        </div>
                      </div>
                      <div className={styles.element}>
                        <div className={styles.title}>Role</div>
                        <div className={styles.value} data-testid="role">
                          {user.role}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className={styles.dots}>
                    <BasicMenu
                      handleDelete={handleDelete}
                      handleEdit={handleEdit}
                      mail={user.email}
                    />
                  </div> */}
                </div>
              </div>
            ))}

          <div className={styles.titlelogo}>
            <div className={styles.loginparttitle2}>Add a User</div>
          </div>

          <div className={styles.formpart}>
            <div className={styles.label_tooltip}>
              <label className={styles.label}>Full Name </label>
              {fullNameError && (
                <p
                  className={styles['error-message']}
                  data-testid="full-name-error"
                >
                  {fullNameError}
                </p>
              )}
            </div>
            <input
              type="text"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              required
              className={styles.input}
              placeholder="Enter Full Name"
              data-testid="add-full-name"
            />

            <div className={styles.label_error}>
              <label className={styles.label}>Email </label>
              {emailError && (
                <p
                  className={styles['error-message']}
                  data-testid="email-error"
                >
                  {emailError}
                </p>
              )}
            </div>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className={styles.input}
              placeholder="Enter Email"
              data-testid="add-email"
            />

            <div className={styles.label_error}>
              <label className={styles.label}>Phone {'    '}</label>
              {phoneError && (
                <p
                  className={styles['error-message']}
                  data-testid="phone-error"
                >
                  {phoneError}
                </p>
              )}
            </div>

            <MuiPhoneNumber
              key={phoneKey} // Use the key to reset the component
              defaultCountry={'us'}
              disableAreaCodes={true}
              autoFormat={false}
              onChange={handleOnPhoneChange}
              value={phone}
              InputProps={{
                disableUnderline: true,
              }}
              autoFormat={false}
              style={{
                padding: '0.8vh',
                alignSelf: 'stretch',
                borderRadius: '16px',
                fontWeight: 500,
                fontSize: '2vh',
                border: '1px solid var(--storywise-gainsboro, #DDDEE0)',
                '&:focus': {
                  border: '1px solid var(--storywise-gainsboro, #DDDEE0)',
                },
              }}
              sx={{ '& svg': { height: '1em' } }}
              data-testid="add-phone"
            />

            <div className={styles.label_tooltip}>
              <label className={styles.label}>Role </label>
              {roleError && (
                <p className={styles['error-message']} data-testid="role-error">
                  {roleError}
                </p>
              )}
            </div>
            <div className={styles.selectrow}>
              <Select
                className={`${styles.Select} dropdownMenu`}
                options={roleOptions}
                value={selectedRole}
                onChange={setSelectedRole}
                placeholder="Select Role"
                styles={customStyles}
                data-testid="add-role"
              />
            </div>
          </div>
        </div>
        {usersList.length === 0 && (
          <div className={styles.userthereisnouserslistdwon}></div>
        )}
        <div className={styles.fotter}>
          <div
            className={`${styles.button}
                         ${
                           saveButtonState === 'success'
                             ? styles.successButton
                             : saveButtonState === 'failure'
                             ? styles.failureButton
                             : ''
                         }`}
            onClick={handleAddUser}
          >
            <div className={styles.textbutton} data-testid="save-button">
              {getButtonText()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ManegUsers;

function BasicMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    props.handleEdit(props.mail);
    handleClose();
  };

  const handleDelete = () => {
    props.handleDelete(props.mail);
    handleClose();
  };

  return (
    <div style={{ height: '100%' }}>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        className={styles.dotsButton}
        style={{ height: '100%' }}
      >
        <img className={styles.dots} src={dots} alt="dots" />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        PaperProps={{
          style: {
            boxShadow: 'none',
            background: '#FFFFFF',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.18)',
            borderRadius: '8px',
            border: '1px solid #E0E0E0',
            backgroundColor: '#f1f2f5',
          },
        }}
      >
        <MenuItem onClick={handleEdit}>Edit</MenuItem>
        <hr />
        <MenuItem onClick={handleDelete}> Delete</MenuItem>
      </Menu>
    </div>
  );
}
