//new version of static compare

import React, { useState } from 'react';
import styles from './ListGenresMatch.module.css';
import BookCard from './BookCard';
import arrow from '../../../icons/title/arrow-down.svg';
import { useRecoilState } from 'recoil';
import { isNewVersionSimilarBooksState } from '../../../state';

const ListGenresMatch = ({ books }) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const [nelsonSimilaBooks, setNelsonSimilaBooks] = useRecoilState(
    isNewVersionSimilarBooksState
  );

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className={styles.listContainer}>
      {/* <button onClick={toggleExpand}>
        {isExpanded ? 'Collapse' : 'Expand'}
      </button> */}
      {isExpanded && (
        <div className={styles.booksContainer}>
          {!nelsonSimilaBooks
            ? books.books.map((book, index) => (
                <BookCard key={index} {...book} />
              ))
            : Object.keys(books).map((key) => (
                <BookCard key={key} isbn={key} {...books[key]} />
              ))}
        </div>
      )}
    </div>
  );
};

export default ListGenresMatch;

//old version of dynamic compare
// import React, { useState } from "react";
// import styles from "./ListGenresMatch.module.css";
// import BookCard from "./BookCard";
// import arrow from "../../../icons/title/arrow-down.svg";

// const ListGenresMatch = ({ genreName, books }) => {
//   const [isExpanded, setIsExpanded] = useState(true);

//   const toggleExpand = () => {
//     setIsExpanded(!isExpanded);
//   };

//   return (
//     <div className={styles.listContainer}>
//       <div className={styles.titleContainer}>
//         <div className={styles.title}>
//           {genreName} ({books.length})
//         </div>
//         <div className={styles.expandSign} onClick={toggleExpand}>
//           {/* {isExpanded ? <strong>-</strong>: <strong>+</strong>} */}
//           {isExpanded ?  <img src={arrow} className={styles.arrowup}/> :  <img src={arrow} className={styles.arrow }  /> }
//         </div>
//       </div>
//       <hr />
//       {isExpanded && (
//         <div className={styles.booksContainer}>
//           {books.map((book, index) => (
//           <BookCard key={index} {...book} />
//         ))}
//         </div>
//       )}
//     </div>
//   );
// };

// export default ListGenresMatch;
