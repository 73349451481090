import React, { useState, useEffect } from 'react';
import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
} from 'amazon-cognito-identity-js';
import { Link, useLocation } from 'react-router-dom';
import styles from './Login.module.css';
import UncoverLeft from './UncoverLeft';
import { useNavigate } from 'react-router-dom';
import { renderCenteredAlert } from '../CenteredAlert';

import { useRecoilState } from 'recoil';
import { accessTokenState, userInfoState, idTokeneState } from '../../state'; // Update the path
import useApiUtils from '../../useApiUtils';
import CircularProgress from '@mui/material/CircularProgress';

const REACT_APP_BUILD_ENV =
  process.env.REACT_APP_BUILD_ENV || 'REACT_APP_BUILD_ENV-build_env';
const REACT_APP_API_ID =
  process.env.REACT_APP_API_ID || 'REACT_APP_BUILD_ENV2-build_env';

const poolData = {
  UserPoolId: process.env.REACT_APP_USER_POOL_ID || 'USER_POOL_ID-build_env',
  ClientId:
    process.env.REACT_APP_CLIENT_ID_USER_POOL ||
    'CLIENT_ID_USER_POOL-build_env',
};

const userPool = new CognitoUserPool(poolData);

const Login = (props) => {
  const [idToken, setIdToken] = useRecoilState(idTokeneState);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useRecoilState(userInfoState); // Update the path
  const location = useLocation();
  const [saveButtonState, setSaveButtonState] = useState('normal'); // 'normal', 'saving', 'success', 'failure'

  const [accessToken, setAccessToken] = useRecoilState(accessTokenState);

  const {
    getManuscriptsInfo,
    initialgetBookChapter,
    get_book_scores,
    getbookgenrekeywords,
    get_user_info,
    // Add other functions from the hook if needed
  } = useApiUtils();

  useEffect(() => {
    // Extract the email from the query parameter
    const queryParams = new URLSearchParams(location.search);
    const emailFromQuery = queryParams.get('email');
    if (emailFromQuery) {
      setEmail(emailFromQuery);
    }
  }, [location.search]);

  const handleLogin = async (event) => {
    event.preventDefault();
    if (saveButtonState === 'loading') {
      return;
    }
    setSaveButtonState('loading');
    localStorage.clear();
  
    const authenticationData = {
      Username: email,
      Password: password,
    };
  
    const authenticationDetails = new AuthenticationDetails(authenticationData);
    const userData = {
      Username: email,
      Pool: userPool,
    };
  
    const cognitoUser = new CognitoUser(userData);
  
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: async (session) => {
        try {
          // Clear local storage before login
          localStorage.clear();
          const userId = session.getIdToken().payload.sub;
          localStorage.setItem('userId', userId);
          setIdToken(session.getIdToken().getJwtToken());
          setAccessToken(session.getAccessToken().getJwtToken());
  
          localStorage.setItem('access_token', session.getAccessToken().getJwtToken());
          localStorage.setItem('access_token_expiration', session.getAccessToken().getExpiration());
          localStorage.setItem('id_token', session.getIdToken().getJwtToken());
          localStorage.setItem('id_token_expiration', session.getIdToken().getExpiration());
          localStorage.setItem('userId', session.getIdToken().payload.sub);
          localStorage.setItem('refresh_token', session.getRefreshToken().getToken());
          localStorage.setItem('email', email);
  
          await get_user_info();
            setSaveButtonState('normal');
            navigate('/submissions');
          
        } catch (error) {
          console.error('Error getting user info:', error);
          localStorage.clear();
          renderCenteredAlert(error.message); 
          setSaveButtonState('normal');

          // renderCenteredAlert('An unexpected error occurred. Please try again later or contact our support team with the following correlation ID for assistance:', error.message.split(':').pop().trim());
         
        }
      },
      onFailure: (error) => {
        console.error('Authentication failed', error);
        setSaveButtonState('normal');
        setErrorMessage(error.message);
      },
    });
  };
  

  const handleLinkClick = (event) => {
    event.preventDefault(); // Prevent the default link behavior

    window.open('/privacy-policy', '_blank');
  };

  const getButtonText = () => {
    switch (saveButtonState) {
      case 'saving':
        return 'Saving...';
      case 'success':
        return 'Saved Successfully';
      case 'failure':
        return 'Failed to Save';
      case 'loading':
        return <CircularProgress size={15} color="inherit" />;
      default:
        return 'Log-in';
    }
  };
  return (
    <div className={styles.container}>
      <UncoverLeft />
      <div className={styles.logocontainer}>
        <div className={styles.logincontainer}>
          <div className={styles.linktosignup}>
            <div className={styles.beforelink}>New to Storywise?</div>
            <Link to="/signup" className={styles.linkto}>
              {' '}
              Sign up
            </Link>
          </div>
          <div className={styles.loginpart}>
            <div className={styles.titlelogo}>
              <div className={styles.loginparttitle}>
                Welcome Back!
                <div className={styles.loginparttitlepart2}>
                  {' '}
                  Login to Continue
                </div>
              </div>
            </div>
            <div className={styles.loginpartsubtitle}>
              <strong>Need help? </strong> You can reach us anytime via{' '}
              <a className={styles.linktomail} href="mailto:info@storywise.ai">
                info@storywise.ai
              </a>
            </div>

            <div className={styles.formpart} onSubmit={handleLogin}>
              <label className={styles.label}>Email</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className={styles.input}
                placeholder="Email"
              />
              <label className={styles.label}>Password</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className={styles.input}
                placeholder="Password"
              />
              {errorMessage && (
                <p className={styles['error-message']}>{errorMessage}</p>
              )}
              <div className={styles.lastrow}>
                <div className={styles.forgetpassword}>
                  <Link
                    to={`/forgot-password?email=${encodeURIComponent(email)}`}
                    className={styles['forgot-password']}
                  >
                    Forgot password ?
                  </Link>
                </div>
                <div
                  type="submit"
                  className={`${styles.loginbutton}
                 ${
                   saveButtonState === 'success'
                     ? styles.successButton
                     : saveButtonState === 'failure'
                     ? styles.failureButton
                     : ''
                 }`}
                  onClick={handleLogin}
                >
                  <div className={styles.loginbuttontext}>
                    {getButtonText()}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.link_pp}>
            <a
              href="/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
              onClick={handleLinkClick}
              className={styles.linkto}
            >
              Privacy Policy
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
