import styles from './UserM.module.css';

const UserM = (props) => {
//this component will represent a message from the user

return (
    <div className={styles.userMContainer}>
        {/* <div className={styles.userMBody}> */}
            <div className={styles.userMText}>
            {props.message}
            </div>
            {/* <div className={styles.userMTime}>
                12:00
            </div> */}
        {/* </div> */}
    </div>
)


}

export default UserM;