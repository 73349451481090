import styles from './GenreCard.module.css';


const GenreCard = ({ genre, selected, onClick ,genre_image}) => {

    const handleClick = () => {
        onClick(genre);
    }

    // const displayGenre = genre === 'General' || genre === 'General Fiction' || genre ==='General non-fiction' ? 'OTHER...' : genre;


    return (
        <div className={`${styles['genre-card']} ${selected ? styles['selected'] : ''}`} onClick={handleClick}>
        <img src={genre_image} alt={genre} className={styles.imagegenre}/>
        <div className={styles['genre-name']}>{genre}</div>
        </div>
    );
    }

export default GenreCard;