import styles from './DadicatedEmail.module.css';
import React, { useState } from 'react';
import useApiUtils from '../../useApiUtils';
import { useEffect, useRef } from 'react';
import { useRecoilState } from 'recoil';
import xxx from '../../icons/xxx.svg';
import trash from '../../icons/vuesax/outline/trash.svg';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';

import gmail from '../../icons/gmail.svg';
import gmailHover from '../../icons/google_signin_buttons 2/web/1x/btn_google_signin_light_focus_web.png';
import gmailPreesed from '../../icons/google_signin_buttons 2/web/1x/btn_google_signin_light_pressed_web.png';
import outlook from '../../icons/outlook.svg';
import { useScrollTrigger } from '@mui/material';
import {
  companyInfoState,
  userInfoState,
  numberOfUploadsState,
  uploadMethodOptionState,
  manuscriptsUploadsState,
  uploadMethodcheckedState,
  uploadMethodProviderState,
} from '../../state';
import { renderCenteredAlert } from '../CenteredAlert';

const DadicatedEmail = (props) => {
  const [saveButtonState, setSaveButtonState] = useState('normal'); // 'normal', 'saving', 'success', 'failure'

  const [errorMessage, setErrorMessage] = React.useState('');
  const [userInfo, setUserInfo] = useRecoilState(userInfoState); // Update the path
  const [options, setOptions] = useRecoilState(uploadMethodOptionState);
  const [dedicatedEmail, setDedicatedEmail] = React.useState('');
  const {
    getManuscriptsInfo,
    initialgetBookChapter,
    get_book_scores,
    getbookgenrekeywords,
    get_user_info,
    getCompanyInformation,
    uploadMethod,

    // Add other functions from the hook if needed
  } = useApiUtils();

  //i want to detect click outside of the deletebox
  const deleteboxRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        deleteboxRef.current &&
        !deleteboxRef.current.contains(event.target)
      ) {
        handleCancel();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [deleteboxRef]);

  const handleCancel = () => {
    props.setDedicated_window(false);
  };

  const handleDedicatdEmail = async () => {
    if (saveButtonState === 'loading') {
      return;
    }

    setSaveButtonState('loading');

    const upload_preferences = {
      uploads_via_manual: options.find(
        (option) => option.value === 'Manual uploads'
      ).selected,
      uploads_via_integrated_email: false, // NB EXTERNAL_EMAIL connector type
      uploads_via_dedicated_email: true, // NB INTERNAL_EMAIL connector type
      integrate_email: {
        // for adding new email connector
        provider: 'microsoft',
        code: 'pending',
        type: 'internal_email',
        email_address: dedicatedEmail,
        status: 'pending',
        max_lookback_days: 0,
      },
    };

    const payload = {
      user_id: userInfo.user_id,
      upload_preferences: upload_preferences,
      ingest_connectors: {},
    };
    try {
      await uploadMethod(payload);
      setSaveButtonState('normal');

      handleCancel();
    } catch (error) {
      setSaveButtonState('normal');

      handleCancel();
    }
  };
  const getButtonText = () => {
    switch (saveButtonState) {
      case 'saving':
        return 'Saving...';
      case 'success':
        return 'Saved Successfully';
      case 'failure':
        return 'Failed to Save';
      case 'loading':
        return <CircularProgress size={15} color="inherit" />;
      default:
        return 'Create Dedicated Email';
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.deletebox} ref={deleteboxRef}>
        <div className={styles.boxsplit}>
          <div className={styles.title}>
            <div className={styles.titleText}>Dedicated email</div>
            <div className={styles.buttonOut} onClick={handleCancel}>
              <img className={styles.xbutton} src={xxx} />
            </div>
          </div>
          <div className={styles.deleteboxContent}>
            <div className={styles.editboxContentTextNimage}>
              <div className={styles.editboxContentTextNimage}>
                <div className={styles.book_name}>
                  We are about to create a dedicated email address for you.
                  <div className={styles.titles}>
                    This email address will be used to receive your manuscript
                    submissions.
                  </div>
                </div>

                {/* <div className={styles['word-count-container']}>
                  <div className={styles.titles}>Select Email Address: </div>
                  <input
                    value={dedicatedEmail}
                    onChange={(e) =>
                      setDedicatedEmail(
                        e.target.value.replace(/@storywise.ai$/, '')
                      )
                    }
                    placeholder={
                      'Enter email prefix ( @storywise.ai will be added automatically)'
                    }
                    className={styles.input}
                  />
                </div> */}
              </div>
            </div>
            <div className={styles.buttons}>
              <div className={styles.cancelButtton} onClick={handleCancel}>
                <div className={styles.cancelButttonText}>Cancel</div>
              </div>
              <div
                className={styles.deleteButtton}
                onClick={handleDedicatdEmail}
              >
                <div
                  className={`${styles.deleteButttonText}${
                    saveButtonState === 'success'
                      ? styles.successButton
                      : saveButtonState === 'failure'
                      ? styles.failureButton
                      : ''
                  }`}
                >
                  {getButtonText()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DadicatedEmail;
