import React from 'react';
import styles from './CoosenCard.module.css';
import editfield from '../../../icons/fieldlistitem/iconcolomnOrder.svg';
import iconlock from '../../../icons/fieldlistitem/iconlock.svg';
import xxx from '../../../icons/field list item/iconeix.svg';


const CoosenCard = (props) => {
  const { index, genre, removeGenre, removable ,column_field} = props;

  const removeCard = () => {
    removeGenre(genre, column_field);
  };

  return (
    <div className={styles.cardContainer}>
      <div className={styles.genre_icon}>
      <img src={editfield} alt="dots" />
        <div className={styles.genre} >
          {genre}
        </div>
      </div>
      {!removable && <div className={styles.dButton} >

        <img src={iconlock} alt="lock" />
      </div>}
      {removable && <div className={styles.deleteButton} onClick={removeCard}>
        <img src={xxx} alt="lock" />
      </div>}
    </div>
  );
};

export default CoosenCard;
