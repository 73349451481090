import React from 'react';
// import { Auth } from 'aws-amplify';
import styles from './UserMenuDropdown.module.css'; // Import your CSS module
import Preferences from './Preferences';
import ManegUsers from './ManegUsers';
import ManuscriptsUploads from './ManuscriptsUploads';
import EmailTemplate from './EmailTemplate';
import Header from '../header/header';
import { useRecoilState } from 'recoil';
import {
  userRejected_ApprovedNoteState,
  initialHighlightState,
  most_similar_bookAtom,
  fileState,
  fileInfoState,
  idTokeneState,
  accessTokenState,
  notes_highlightsState,
  companyInfoState,
  numberOfUploadedFilesState,
  userInfoState,
  headerColumnsTranslationState,
  loadingmanuscriptState,
  pendingProcessingState,
  loadingStateCompare,
  currentManuscriptAtom,
  afterGetBookChapter,
  clickedManuscriptAtom,
  genresState,
  keywordsState,
  genresDataState,
  scoreDataAtom,
  chapterNamesAtom,
  selectedChapterAtom,
  contentAtom,
  notesAtom,
  manuscriptInfoListAtom,
  startPollingState,
  books_id_dwonloadState,
  preferencesState,
  emailTemplateState,
  manuscriptsUploadsState,
  UserRightsandRolesState,
  profileState,
} from '../../state';
import IconSelected from '../../icons/side_manu 2/Icon.svg';
import Icon1 from '../../icons/side_manu 2/Icon1.svg';
import Icon2 from '../../icons/side_manu 2/Icon2.svg';
import Icon3 from '../../icons/side_manu 2/Icon3.svg';
import Selectedemail from '../../icons/side_manu 2/Selectedemail.svg';
import selectedpre from '../../icons/side_manu 2/selectedpre.svg';
import adduser from '../../icons/side_manu 2/adduser.svg';
import upload from '../../icons/side_manu 2/upload.svg';
import pre from '../../icons/side_manu 2/pre.svg';
import logout from '../../icons/side_manu 2/logout.svg';
import Avatar from '@mui/material/Avatar';
import { useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

import { useState, useEffect } from 'react';

import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
} from 'amazon-cognito-identity-js';

const REACT_APP_BUILD_ENV =
  process.env.REACT_APP_BUILD_ENV || 'REACT_APP_BUILD_ENV-build_env';
const REACT_APP_API_ID =
  process.env.REACT_APP_API_ID || 'REACT_APP_BUILD_ENV2-build_env';

const poolData = {
  UserPoolId: process.env.REACT_APP_USER_POOL_ID || 'USER_POOL_ID-build_env',
  ClientId:
    process.env.REACT_APP_CLIENT_ID_USER_POOL ||
    'CLIENT_ID_USER_POOL-build_env',
};

const userPool = new CognitoUserPool(poolData);

const UserMenuDropdown = ({ user }) => {
  const [books_id_dwonload, setBooks_id_dwonload] = useRecoilState(
    books_id_dwonloadState
  ); // Update the path
  const [startPolling, setStartPolling] = useRecoilState(startPollingState); // Update the path
  const [userInfo, setUserInfo] = useRecoilState(userInfoState); // Update the path
  const [manuscriptInfoList, setManuscriptInfoList] = useRecoilState(
    manuscriptInfoListAtom
  );
  const [clickedManuscript, setClickedManuscript] = useRecoilState(
    clickedManuscriptAtom
  );
  const [currentManuscript, setCurrentManuscript] = useRecoilState(
    currentManuscriptAtom
  );
  const [headerColumnsTranslation, setHeaderColumnsTranslation] =
    useRecoilState(headerColumnsTranslationState);
  const [pendingProcessing, setPendingProcessing] = useRecoilState(
    pendingProcessingState
  );
  const [loadingState, setLoadingState] = useRecoilState(loadingStateCompare);
  const [loadingmanuscript, setLoadingmanuscript] = useRecoilState(
    loadingmanuscriptState
  );
  const [genresData, setGenresData] = useRecoilState(genresDataState);
  const [keywords, setKeywords] = useRecoilState(keywordsState);
  const [genres, setGenres] = useRecoilState(genresState);
  const [notes, setNotes] = useRecoilState(notesAtom);
  const [content, setContent] = useRecoilState(contentAtom);
  const [chapterNames, setChapterNames] = useRecoilState(chapterNamesAtom);
  const [scoreData, setScoreData] = useRecoilState(scoreDataAtom);
  const [file, setFile] = useRecoilState(fileState);
  const [fileInfo, setFileInfo] = useRecoilState(fileInfoState);
  const [idToken, setIdToken] = useRecoilState(idTokeneState);
  const [accessToken, setAccessToken] = useRecoilState(accessTokenState);
  const [notes_highlights, setNotes_highlights] = useRecoilState(
    notes_highlightsState
  );
  const [numberOfUploadedFiles, setNumberOfUploadedFiles] = useRecoilState(
    numberOfUploadedFilesState
  );
  const [most_similar_book, setMost_similar_book] = useRecoilState(
    most_similar_bookAtom
  );
  const [initialHighlight, setInitialHighlight] = useRecoilState(
    initialHighlightState
  );
  const [userRejected_ApprovedNote, setUserRejected_ApprovedNote] =
    useRecoilState(userRejected_ApprovedNoteState);
  const [selectedChapter, setSelectedChapter] =
    useRecoilState(selectedChapterAtom);
  const [companyInfo, setCompanyInfo] = useRecoilState(companyInfoState);

  //function to empty all the stares
  const emptyAllStates = () => {
    setUserInfo(null);
    setClickedManuscript([]);
    setCurrentManuscript([]);
    setHeaderColumnsTranslation([]);
    setPendingProcessing(0);
    setLoadingState(false);
    setLoadingmanuscript(false);
    setGenresData({});
    setKeywords([]);
    setGenres([]);
    setNotes([]);
    setManuscriptInfoList([]);
    setContent([]);
    setChapterNames([]);
    setScoreData({});
    setFile([]);
    setStartPolling(false);
    setSelectedChapter(0);
    setFileInfo([]);
    setIdToken(null);
    setAccessToken(null);
    setNotes_highlights([]);
    setNumberOfUploadedFiles(0);
    setMost_similar_book([]);
    setUserRejected_ApprovedNote([]);
    setBooks_id_dwonload([]);
    setCompanyInfo(null);
  };

  const logoutUser = () => {
    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.signOut();
    }

    // Clear tokens and user-related data from sessionStorage
    localStorage.removeItem('access_token');
    localStorage.removeItem('access_token_expiration');
    localStorage.removeItem('id_token');
    localStorage.removeItem('id_token_expiration');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('userId');
    localStorage.removeItem('email');
    emptyAllStates();
    navigate('/'); // Use the appropriate route here
  };

  const navigate = useNavigate();

  const [preferences, setPreferences] = useRecoilState(preferencesState);
  const [emailTemplate, setEmailTemplate] = useRecoilState(emailTemplateState);
  const [manuscriptsUploads, setManuscriptsUploads] = useRecoilState(
    manuscriptsUploadsState
  );
  const [UserRightsandRoles, setUserRightsandRoles] = useRecoilState(
    UserRightsandRolesState
  );

  const [Profile, setProfile] = React.useState(false);

  const handleSignOut = async () => {};

  const handleEmailTemplate = () => {
    setPreferences(false);
    setEmailTemplate(true);
    setManuscriptsUploads(false);
    setUserRightsandRoles(false);
    setProfile(false);
    handleBackDrop('emailTemplate');
  };

  const handleUploads = () => {
    setPreferences(false);
    setEmailTemplate(false);
    setManuscriptsUploads(true);
    setUserRightsandRoles(false);
    setProfile(false);
    handleBackDrop('manuscriptsUploads');
  };

  const handleUserRightsandRoles = () => {
    setPreferences(false);
    setEmailTemplate(false);
    setManuscriptsUploads(false);
    setUserRightsandRoles(true);
    setProfile(false);
    handleBackDrop('userRightsandRoles');
  };

  const handleProfile = () => {
    setPreferences(false);
    setEmailTemplate(false);
    setManuscriptsUploads(false);
    setUserRightsandRoles(false);
    setProfile(true);
    handleBackDrop('profile');
  };

  const handlePreferences = () => {
    setPreferences(true);
    setEmailTemplate(false);
    setManuscriptsUploads(false);
    setUserRightsandRoles(false);
    setProfile(false);
    handleBackDrop('preferences');
  };

  const handleBackDrop = (openoption) => {
    //remove the openoption from the local storage only if threre is one
    if (localStorage.getItem('openoption')) {
      localStorage.removeItem('openoption');
    }
    //save openoption in the local storage
    localStorage.setItem('openoption', openoption);
  };

  //intial the state that in if there is openoption in the local storage
  React.useEffect(() => {
    if (localStorage.getItem('openoption')) {
      switch (localStorage.getItem('openoption')) {
        case 'preferences':
          setPreferences(true);
          setEmailTemplate(false);
          setManuscriptsUploads(false);
          setUserRightsandRoles(false);
          setProfile(false);
          break;
        case 'emailTemplate':
          setPreferences(false);
          setEmailTemplate(true);
          setManuscriptsUploads(false);
          setUserRightsandRoles(false);
          setProfile(false);
          break;
        case 'manuscriptsUploads':
          setManuscriptsUploads(true);
          break;
        case 'userRightsandRoles':
          setPreferences(false);
          setEmailTemplate(false);
          setManuscriptsUploads(false);
          setUserRightsandRoles(true);
          setProfile(false);
          handleBackDrop('userRightsandRoles');
          break;
        case 'profile':
          setPreferences(false);
          setEmailTemplate(false);
          setManuscriptsUploads(false);
          setUserRightsandRoles(false);
          setProfile(true);
          break;
        default:
          break;
      }
      //remove the openoption from the local storage
      localStorage.removeItem('openoption');
    }
    //if non of them is true so set the preferences to true
    else {
      setPreferences(true);
      setEmailTemplate(false);
      setManuscriptsUploads(false);
      setUserRightsandRoles(false);
      setProfile(false);
    }

    const storedState = localStorage.getItem('currentState');
    if (storedState) {
      handleUploads();
    }
  }, []);

  let name = '';
  let role = '';
  let firstLetter;
  let colorCode;
  if (userInfo) {
    firstLetter = getInitials(userInfo?.name);
    colorCode = generateColorCode(firstLetter); // Generate color code based on owner's name
    name = userInfo?.name;
    role = userInfo?.role;
    //if role ===\v\1 so Editor else if role ===\v\2 so Reviewer else if role ===\v\3 so Manager
    if (role === 'lvl1') {
      role = 'Editor';
    } else if (role === 'lvl2') {
      role = 'Manager';
    }
  } else {
    firstLetter = getInitials('NA');
    colorCode = generateColorCode(firstLetter); // Generate color code based on owner's name
  }

  function getInitials(name) {
    if (!name) {
      return 'NA';
    }
    const words = name.split(' ');
    if (words.length === 1) {
      const firstLetter = words[0].charAt(0).toUpperCase();
      // const secondLetter = words[0].charAt(1).toLowerCase();
      // return `${firstLetter}${secondLetter}`;
      return `${firstLetter}`;
    } else {
      const firstWordInitial = words[0].charAt(0).toUpperCase();
      const secondWordInitial = words[1].charAt(0).toUpperCase();
      return `${firstWordInitial}${secondWordInitial}`;
    }
  }

  const avatarStyle = {
    backgroundColor: colorCode,
    width: '40px',
    height: '40px',
    fontSize: '14px',
  };

  const selectStyle = {
    cursor: 'crosshair',
    border: 'none', // Remove the border
    outline: 'none', // Remove the focus outline
    width: '100%', // Set the width to fill the cell (excluding the avatar)
    marginLeft: 8, // Add margin to the left of the select box
    background: 'transparent', // Make the background transparent
    fontSize: 'inherit', // Inherit font size from the parent
    color: 'inherit', // Inherit color from the parent
    textAlign: 'left', // Align text to the left
    padding: 0, // Remove padding
  };

  // React.useEffect(() => {
  //     checkAuthentication();
  //     }, [preferences, emailTemplate, manuscriptsUploads, UserRightsandRoles, Profile]);

  function generateColorCode(name) {
    // Simple hash calculation based on the name
    let hash = 0;
    for (let i = 0; i < name.length; i++) {
      hash += name.charCodeAt(i);
    }

    // Generate a color code using the hash value
    const hue = hash % 360; // Use the hash value modulo 360 to determine the hue
    const saturation = 70; // Set a fixed saturation value
    const lightness = 60; // Set a fixed lightness value

    // Convert HSL values to a CSS color code
    const colorCode = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    return colorCode;
  }

  const handleLogout = () => {
    // checkAuthentication();

    logoutUser();
    // checkAuthentication();
    navigate('/'); // Use the appropriate route here
  };

  useEffect(() => {
    if (!localStorage.getItem('id_token')) {
      navigate('/');
    }
  }, []);

  const checkAuthentication = () => {
    const cognitoUser = userPool.getCurrentUser();

    if (cognitoUser) {
      cognitoUser.getSession((err, session) => {
        if (err) {
          console.error('Error getting session:', err);
          // Error handling, session is not valid
        } else {
          const idToken = session.getIdToken().getJwtToken();
          // Send the `idToken` to Cognito's token introspection endpoint
          // Check the response to determine if the token is valid
        }
      });
    } else {
      // No current user, indicating the user is logged out
    }
  };

  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);

  const showErrorMessage = (message) => {
    setShowError(true);
    setTimeout(() => {
      setShowError(false);
    }, 5000);
  };

  React.useEffect(() => {
    showSuccessMessage();
  }, []);

  const showSuccessMessage = (message) => {
    setShowSuccess(true);
    setTimeout(() => {
      setShowSuccess(false);
    }, 5000);
  };

  return (
    <div className={styles.PageRoot}>
      <Header issubmission={false} issettings={true} />
      <div className={styles.backdrop}>
        {/* { showSuccess && userInfo?.role === 'lvl1' && (
                    <div className={`${styles.successMessage} ${styles.successMessageLeft}`}>
                        <Stack sx={{ width: '50%' }} spacing={4} >
                                 <Alert severity="error">Only manager can edit the setting  </Alert>
                       
                        </Stack>

                    </div>
                )} */}
        {showSuccess && userInfo?.role === 'lvl1' && (
          <div
            className={`${styles.errorflashMessage} ${styles.successMessageLeft}`}
          >
            <Stack sx={{ width: '50%' }} spacing={4}>
              <Alert severity="error">
                Error - Only manager can edit the setting!
              </Alert>
            </Stack>
          </div>
        )}
        <div className={styles.userMenuDropdown}>
          <ul className={styles.menuList}>
            {preferences && (
              <li
                className={`${styles.menuItemActive} ${
                  preferences ? styles.activeMenuItem : ''
                }`}
                onClick={handlePreferences}
              >
                <i className={`icon-profile ${styles.menuIcon}`} />
                <img src={pre} className={styles.menuIcon} />
                Preferences
              </li>
            )}
            {!preferences && (
              <li
                className={`${styles.menuItem} ${
                  preferences ? styles.activeMenuItem : ''
                }`}
                onClick={handlePreferences}
              >
                <i className={`icon-profile ${styles.menuIcon}`} />
                <img src={IconSelected} className={styles.menuIcon} />
                Preferences
              </li>
            )}
            {emailTemplate && (
              <li
                className={`${styles.menuItemActive} ${
                  emailTemplate ? styles.activeMenuItem : ''
                }`}
                onClick={handleEmailTemplate}
              >
                <i className={`icon-email ${styles.menuIcon}`} />
                <img src={Icon1} className={styles.menuIcon} />
                Email Template
              </li>
            )}
            {!emailTemplate && (
              <li
                className={`${styles.menuItem} ${
                  emailTemplate ? styles.activeMenuItem : ''
                }`}
                onClick={handleEmailTemplate}
              >
                <i className={`icon-email ${styles.menuIcon}`} />
                <img src={Selectedemail} className={styles.menuIcon} />
                Email Template
              </li>
            )}
            {manuscriptsUploads && (
              <li
                className={`${styles.menuItemActive} ${
                  manuscriptsUploads ? styles.activeMenuItem : ''
                }`}
                onClick={handleUploads}
              >
                <i className={`icon-upload ${styles.menuIcon}`} />
                <img src={Icon2} className={styles.menuIcon} />
                Upload Method
              </li>
            )}
            {!manuscriptsUploads && (
              <li
                className={`${styles.menuItem} ${
                  manuscriptsUploads ? styles.activeMenuItem : ''
                }`}
                onClick={handleUploads}
              >
                <i className={`icon-upload ${styles.menuIcon}`} />
                <img src={upload} className={styles.menuIcon} />
                Upload Method
              </li>
            )}
            {UserRightsandRoles && (
              <li
                className={`${styles.menuItemActive} ${
                  UserRightsandRoles ? styles.activeMenuItem : ''
                }`}
                onClick={handleUserRightsandRoles}
              >
                <i className={`icon-rights ${styles.menuIcon}`} />
                <img src={Icon3} className={styles.menuIcon} />
                Users Management
              </li>
            )}
            {!UserRightsandRoles && (
              <li
                className={`${styles.menuItem} ${
                  UserRightsandRoles ? styles.activeMenuItem : ''
                }`}
                onClick={handleUserRightsandRoles}
              >
                <i className={`icon-rights ${styles.menuIcon}`} />
                <img src={adduser} className={styles.menuIcon} />
                Users Management
              </li>
            )}
          </ul>
          {/*  */}
          <div className={styles.profileTitle}>Profile</div>
          <div className={styles.profilerow}>
            <Avatar style={avatarStyle}>{firstLetter}</Avatar>
            <div className={styles.userText}>
              <div className={styles.userName}>{name}</div>
              <div className={styles.userRole}>{role}</div>
              {/* add company name company_name */}
              <div className={styles.userRole}>{companyInfo?.company_name}</div>
            </div>
          </div>

          <button className={styles.logoutButton} onClick={handleLogout}>
            <img src={logout} className={styles.logoutIcon} />
            Log Out
          </button>
        </div>

        <div className={styles.secondpart}>
          {preferences && <Preferences showErrorMessage={showErrorMessage} />}
          {emailTemplate && (
            <EmailTemplate showErrorMessage={showErrorMessage} />
          )}
          {manuscriptsUploads && (
            <ManuscriptsUploads
              setManuscriptsUploads={setManuscriptsUploads}
              showErrorMessage={showErrorMessage}
            />
          )}
          {UserRightsandRoles && (
            <ManegUsers showErrorMessage={showErrorMessage} />
          )}
          {/* {Profile && <div>Profile</div>} */}
        </div>
      </div>
    </div>
  );
};

export default UserMenuDropdown;
