import styles from './UncoverLeft.module.css';
import leftImage from '../../icons/AUTH/Frameuncover.svg';
import logo from '../../icons/AUTH/logo.svg';

const UncoverLeft = ({ children }) => {
    // create a component that render the logo and the text: Uncover the Undiscovered and than an image
    return (
        <div className={styles.container}>
            {/* <div className={styles.left}> */}
                <div className={styles.logo}>
                    <img src={logo} alt="logo" />
                </div>
                <div className={styles.text}>
                   Uncover the Undiscovered
                </div>
            {/* </div> */}
            <div className={styles.imageLeft}>
                <img src={leftImage} alt="imageLeft" />
            </div>
        </div>
    );
};

export default UncoverLeft;