import { useState, useEffect } from 'react';
import styles from './Note.module.css';
import dots from '../../../icons/3dots.svg';
import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditNote from './EditNote';
import AvatarCostom from '../../../UI/AvatarCostom';
import { useRecoilState } from 'recoil';
import {
  notesAtom,
  selectedChapterAtom,
  chapterNamesAtom,
  clickedManuscriptAtom,
} from '../../../state';
import { renderCenteredAlert } from '../../CenteredAlert';

const Note = (props) => {
  let { name, date, textNote, type } = props;

  const [selectedChapter, setSelectedChapter] =
    useRecoilState(selectedChapterAtom);
  const [chapterNames, setChapterNames] = useRecoilState(chapterNamesAtom);
  const [showAddNote, setShowAddNote] = useState(false);
  const [addNotePosition, setAddNotePosition] = useState({
    left: '5%',
    top: '5%',
  });
  const [clickedManuscript, setClickedManuscript] = useRecoilState(
    clickedManuscriptAtom
  );
  const handleChapterClick = (event) => {
    const isMenuElement =
      event.target.closest('#basic-button') ||
      event.target.closest('#basic-menu');

    if (!isMenuElement) {
      const index = chapterNames.findIndex(
        (chapterName) => chapterName === props.chapter
      );
      if (index > -1) {
        setSelectedChapter(index);
      }
    }
  };

  const handleChapterClickdots = () => {
    const index = chapterNames.findIndex(
      (chapterName) => chapterName === props.chapter
    );
    if (index > -1) {
      setSelectedChapter(index);
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleOptionClick = (event) => {
    event.stopPropagation();
    handleChapterClickdots();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    props.handleDelete(
      props.id,
      props.chapter,
      props.type,
      props.note_id,
      clickedManuscript.book_id
    );
    handleClose();
  };

  const handleEdit = () => {
    // if( props.type === 'approved' || props.type === 'reject'){
    //   renderCenteredAlert('You can not edit Approved or Rejected notes');
    //   handleClose();
    //   return;
    // }
    if (props.type === 'highlight') {
      renderCenteredAlert('You can not edit Highlight notes');
      handleClose();
      return;
    } else if (props.type === `approved` || props.type === `reject`) {
      renderCenteredAlert('You can not edit Approved or Rejected notes');
      handleClose();
      return;
    }
    handleClose();
    setShowAddNote(true);
  };

  useEffect(() => {
    name = props.name;
    date = props.date;
    textNote = props.textNote;
  }, [props.name, props.date, props.textNote]);

  // Set different class names based on 'type' prop
  let noteRootClassName = styles.NoteRootRoot; // Default style for 'note' type

  if (type === 'highlight') {
    noteRootClassName = styles.HighlightRoot;
    // Truncate 'textNote' for 'highlight' type
    textNote =
      textNote.split(' ').slice(0, 10).join(' ') +
      (textNote.split(' ').length > 10 ? '...' : '');
  } else if (type === 'approved') {
    noteRootClassName = styles.ApprovedRoot;
  } else if (type === 'reject') {
    noteRootClassName = styles.RejectRoot;
  }

  // Truncate 'textNote' to a maximum of 10 words with three dots for 'highlight'
  const truncatedTextNote =
    type === 'highlight' &&
    textNote.split(' ').slice(0, 10).join(' ') +
      (textNote.split(' ').length > 10 ? '...' : '');

  return (
    // <div className={noteRootClassName} onClick={handleChapterClick}>
    <div className={noteRootClassName}>
      {showAddNote && (
        <div
          id="context-note"
          style={{
            position: 'absolute',
            left: addNotePosition.left,
            top: addNotePosition.top,
            zIndex: 9999,
          }}
        >
          <EditNote
            HideNote={setShowAddNote}
            initialText={textNote}
            id={props.id}
            type={type}
            chapter={props.chapter}
            bookd={clickedManuscript.book_id}
          />
        </div>
      )}
      <div className={styles.Group}>
        <div className={styles.Group1}>
          {/* <img className={styles.writerImg} src="https://file.rendit.io/n/4KBl1nCjOdwZtGjiGDoV.png" /> */}
          <AvatarCostom name={props.name} />
          <div className={styles.writer}>{name}</div>
          <div className={styles.date_button}>
            <div className={styles.date}>{date}</div>
          </div>
        </div>

        <div style={{ height: '100%' }}>
          <Button
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleOptionClick}
            className={styles.dotsButton}
            style={{ height: '100%' }}
          >
            <img className={styles.threeDots} src={dots} />
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            {props.type === 'note' && (
              <MenuItem onClick={handleEdit}>Edit</MenuItem>
            )}
            {/* <MenuItem onClick={handleEdit}>Edit</MenuItem> */}
            <MenuItem onClick={handleDelete}>Delete</MenuItem>
          </Menu>
        </div>
      </div>
      {type === 'highlight' ? (
        <div className={styles.textNote}>{truncatedTextNote}</div>
      ) : (
        <div className={styles.textNote}>{textNote}</div>
      )}
    </div>
  );
};

export default Note;
