import syles from './Chat.module.css';
import sendIcon from '../../../icons/sendIcon.svg';
import UserM from './UserM';
import BotM from './BotM';
import AvatarC from '../../../UI/AvatarCostom';
import x from "../../../icons/vuesax/outline/addx.svg";
import {useEffect, useState ,useRef} from 'react';
import {FaTimes, FaRegClosedCaptioning, FaRegTrashAlt } from 'react-icons/fa';

import bot from '../../../icons/bot.svg';
import { chatHistoryMessagesAtom, chatHistoryAtom, clickedManuscriptAtom } from "../../../state";
import { useRecoilState } from "recoil";
import useApiUtils from '../../../useApiUtils';
import { GrRefresh } from 'react-icons/gr';
const Chat = (props) => {
    const [input, setInput] = useState(''); // input from the user
    const [clickedManuscript, setClickedManuscript] = useRecoilState(clickedManuscriptAtom);
    const [chatHistoryMessages, setChatHistoryMessages] = useRecoilState(chatHistoryMessagesAtom);
    const [typing, setTyping] = useState(false); // true if the bot is typing
    const [proccessedQuestion, setProccessedQuestion] = useState(''); // the question that is being proccessed
    const [inClearChat, setInClearChat] = useState(false); // true if the user clicked on the clear chat button
    const {
        Book_Chat,
    } = useApiUtils()


    // Create a ref for the chat body div
  const chatBodyRef = useRef(null);

  // Function to scroll chat body to the bottom
  const scrollToBottom = () => {
    if (chatBodyRef.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    // Scroll to the bottom when chatHistoryMessages change
    scrollToBottom();
}, [chatHistoryMessages, typing]); // Include typing as a dependency


    const handleInputChange = (e) => {
        setInput(e.target.value);
    }

    const handleClose = () => {
        props.closeChat(false);
    }

    

    const handleClearChat = async () => {
        setInClearChat(true);
        setChatHistoryMessages([]);
        setInput('');
        setTyping(false);
        setProccessedQuestion('');
        const emptyChatHistory = '';
        await Book_Chat(clickedManuscript.book_id, emptyChatHistory);
        setInClearChat(false);

    }

    const handleSendPrompt = async () => {

        if (typing || inClearChat) {
            return;
        }
        if (input !== '') {
            setTyping(true);
            const inpotMessage = input;
            setProccessedQuestion(input);
            setInput('');
            // Call Book_Chat with the input as a question
            await Book_Chat(clickedManuscript.book_id, inpotMessage);
            setTyping(false);
            setProccessedQuestion('');

        }
    }

    return (
        <div className={syles.chatContainer}>
            <div className={syles.chatall}>
                <div className={syles.chatHeader}>
                    <div className={syles.avatar_name}>
                        < img src={bot} className={syles.avatar} />
                        <div className={syles.name}>
                            {clickedManuscript.manuscript_name}
                        </div>
                    </div>
                    <div className={syles.buttons}>
                            <div className={syles.clearChat} onClick={handleClearChat}>
                          {/* &empty;   */}
                          <FaRegTrashAlt color="white" size="1.5em" />
                    {/* <GrRefresh   color="white"/> */}
                        </div>
                        <div className={syles.close} onClick={handleClose}>
                            {/* <img src={x} alt="close" className={syles.closeimg} /> */}
                            <FaTimes color="white" size="1.5em" />
                        </div>
                      
                    </div>
                </div>
                <div className={syles.chatBody} ref={chatBodyRef}>
                    <BotM message="Hello!" />
                    <BotM message=" Welcome to storywise chat with the book!" />
                    {!clickedManuscript.book_id &&
                    <BotM message="Please select a book to chat with it" />}
                    {chatHistoryMessages.map((message, index) => (
                        <div key={index} className={syles.chatBodyinside}>
                            <UserM message={message.question} />
                            {message.answer !== '' &&
                                <BotM message={message.answer} />
                            }
                        </div>
                    ))}
                    {typing &&
                        <div className={syles.chatBodyinside}>
                            <UserM message={proccessedQuestion} />
                            <BotM message="..." />
                        </div>
                    }
                </div>

                <div className={syles.chatFooter}>
                    <div className={syles.inputfield}>
                        <input
                         type="text" 
                         value={input}
                          onChange={handleInputChange}
                          placeholder="Type a message to chat with the book..." 
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault(); // Prevent the default behavior of Enter key in an input field
                              handleSendPrompt();
                            }
                          }}
                          />
                    </div>
                    <div className={syles.sendButton} onClick={handleSendPrompt}>
                        <img src={sendIcon} alt="send" className={syles.sendButtonimg} />
                    </div>
                </div>
            </div>
        </div>
    )


}

export default Chat;