
import styles from './Delete.module.css';
import trash from '../../../icons/vuesax/outline/trash.svg';
import xxx from '../../../icons/xxx.svg';
import { useRecoilState } from "recoil";
import { fileState, fileInfoState ,clickedUploadFileState} from "../../../state"


const Delete = (props) => {


    const [clickedUploadFile, setClickedUploadFile] = useRecoilState(clickedUploadFileState);
    const [fileInfo, setFileInfo] = useRecoilState(fileInfoState);

    const handleOut = () => {
        props.handleDelete(false);
    };

    const handleDelete = () => {
        props.handleDelete(false);
        // delit clickedUploadFile from fileInfo
        let newFileInfo = fileInfo.filter((file) => file !== clickedUploadFile);
        setFileInfo(newFileInfo);
    };

    const handleCancel = () => {
        props.handleDelete(false);
    };
    
    return (
        <div className={styles.container}>
            <div className={styles.deletebox}>
                <div className={styles.boxsplit}>
                    <div className={styles.title}>
                        <div className={styles.titleText}>
                            Delete Manuscript?
                        </div>
                        <div className={styles.buttonOut} onClick={handleOut}>
                            <img className={styles.xbutton} src={xxx} />
                        </div>
                    </div>
                    <div className={styles.deleteboxContent}>
                        <div className={styles.editboxContentTextNimage}>
                            <div className={styles.imageUploadPart}>
                                <img className={styles.book_image} src={clickedUploadFile.image} />
                                <div className={styles.book_name}>
                                    {/* {props.manuscriptName} */}
                                    {clickedUploadFile.manuscript_name}
                                </div>
                            </div>
                            <div className={styles.editboxContentTextP}>
                                <div className={styles.editboxContentTextTitle}>
                                    Are You Sure You Want to Delete This Manuscript?
                                </div>
                            </div>
                        </div>
                        <div className={styles.buttons} onClick={handleCancel}>
                            <div className={styles.cancelButtton}>
                                <div className={styles.cancelButttonText}>
                                    Cancel
                                </div>
                            </div>
                            <div className={styles.deleteButtton} onClick={handleDelete}>
                                <img className={styles.deleteButttonImage} src={trash} alt="delete" />
                                <div className={styles.deleteButttonText}>
                                    Delete
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}



export default Delete;
