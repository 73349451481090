import React from 'react';
// import ReactDOM from 'react-dom';
import ReactDOM from  'react-dom/client';
import { RecoilRoot } from 'recoil';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// ReactDOM.render(
//   <React.StrictMode>
//     <RecoilRoot> {/* Wrap your app with RecoilRoot */}
//       <App />
//     </RecoilRoot>
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// reportWebVitals();
const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);

// Wrap your app with RecoilRoot
root.render(
  <React.StrictMode>
    <RecoilRoot>
      <App />
    </RecoilRoot>
  </React.StrictMode>
);

reportWebVitals();
