import React from 'react';
import styles from './LoadingDots.module.css'; // Adjust the path as needed

const LoadingDots = () => (
  <div className={styles.dotsContainer} title="loading">
    <span className={styles['jumping-dots']}>
      <span className={styles['dot-1']}></span>
      <span className={styles['dot-2']}></span>
      <span className={styles['dot-3']}></span>
    </span>
  </div>
);

export default LoadingDots;
