import styles from './DeletesFiles.module.css';



import trash from '../../../icons/vuesax/outline/trash.svg';
import xxx from '../../../icons/xxx.svg';
import { useEffect, useRef } from 'react';
import { useRecoilState } from "recoil";
import {books_id_dwonloadState, pendingProcessingState, clickedManuscriptAtom, manuscriptInfoListAtom } from "../../../state";
import useApiUtils from '../../../useApiUtils';


const Delete = (props) => {
    const [manuscriptInfoList, setManuscriptInfoList] = useRecoilState(manuscriptInfoListAtom);
    const [pendingProcessing, setPendingProcessing] = useRecoilState(pendingProcessingState);
    const [dwonload, setDwonload] = useRecoilState(books_id_dwonloadState);   
    
     const {
        getManuscriptsInfo,
        initialgetBookChapter,
        get_book_scores,
        getbookgenrekeywords,
        // Add other functions from the hook if needed
        Deletemanuscript,
      } = useApiUtils()
    
//i want to detect click outside of the deletebox
    const deletfeboxRef = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (deletfeboxRef.current && !deletfeboxRef.current.contains(event.target)) {
                props.handleDeletefewManuscripts(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [deletfeboxRef]);





    const handleOut = () => {
        props.handleDeletefewManuscripts(false);

    };

  





    const handleCancel = () => {
        props.handleDeletefewManuscripts(false);
    };

    const handleDeletefewManuscripts = () => {
        
        props.handleDeletefewManuscripts(false);
        Deletemanuscript(dwonload , true);
        setDwonload([]);
    };






        return (
            <div className={styles.container}  >
                <div className={styles.deletebox} ref={deletfeboxRef}>
                    <div className={styles.boxsplit}>
                        <div className={styles.title}>
                            <div className={styles.titleText}>
                                Delete Manuscript?
                            </div>
                            <div className={styles.buttonOut} onClick={handleOut}>
                                <img className={styles.xbutton} src={xxx} />
                            </div>
                        </div>
                        <div className={styles.deleteboxContent}>
                            <div className={styles.editboxContentTextNimage}>
                            <div className={styles.imageUploadPart}>
                                <img className={styles.book_image}
                                    src={trash}
                                />
                                <div className={styles.book_name}>
                                    {/* {props.manuscriptName} */}
                                    
                                </div>
                            </div>
                                <div className={styles.editboxContentTextP}>
                                    <div className={styles.editboxContentTextTitle}>
                                        Are you sure you want to delete these {dwonload.length} manuscripts?
                                    </div>
                                </div>
                            </div>
                            <div className={styles.buttons} onClick={handleCancel}>
                                <div className={styles.cancelButtton}>
                                    <div className={styles.cancelButttonText}>
                                        Cancel
                                    </div>
                                </div>
                                <div className={styles.deleteButtton} onClick={handleDeletefewManuscripts}>
                                    <img className={styles.deleteButttonImage} src={trash} alt="delete" />
                                    <div className={styles.deleteButttonText}>
                                        Delete
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
                                

}



export default Delete;
