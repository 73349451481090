// new version of static compare
import React from "react";
import Genre from "./Genre";
import Keywords from "./Keywords ";
import styles from "./ComparisonItems.module.css";
import axios from "axios";
import { useRecoilState } from "recoil";
import {userInfoState,clickedManuscriptAtom,  loadingStateCompare, currentManuscriptAtom, genresState, keywordsState, genresDataState } from "../../../state";
import retur from "../../../icons/return.svg";
import { useState, useEffect } from "react";



const ComparisonItems = (props) => {

  const [genres, setGenres] = useRecoilState(genresState);
  const [keywords, setKeywords] = useRecoilState(keywordsState);
  const [totalCard, setTotalCard] = React.useState(0);
  const [currentManuscript, setCurrentManuscript] = useRecoilState(currentManuscriptAtom);
  const [genresData, setGenresData] = useRecoilState(genresDataState);
  const [loading, setLoading] = useRecoilState(loadingStateCompare); //for the books
      const [clickedManuscript, setClickedManuscript] = useRecoilState(clickedManuscriptAtom);
      const [userInfo, setUserInfo] = useRecoilState(userInfoState); // Update the path




  // // Update the totalCard value whenever genres or keywords change
  React.useEffect(() => {
    setTotalCard(genres.length + keywords.length);
  }, [genres, keywords]);


  return (
    <div className={styles.container}>
      <div className={styles.titleWrapper}>
        <h3 className={styles.title}>Comparison Items ({totalCard})</h3>
        <div className={styles.Buttons}>
        </div>
      </div>
      <div className={styles.groups}>
        <div className={styles.group} style={{ height: "100%" }} >
          <Genre className={styles.group}
          />
        </div>

        <div className={styles.group} style={{ height: "100%" }} >
          <Keywords className={styles.group}
          />
        </div>
      </div>
    </div>
  );

};

export default ComparisonItems;






