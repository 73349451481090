


import styles from "./Manuscript.module.css";
import TextEditor from "./TextEditor";
import React, { useRef, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import {notes_highlightsState, initialHighlightState, currentManuscriptAtom, afterGetBookChapter, notesAtom, contentAtom, selectedChapterAtom, highlightedTextAtom, changeInNotesAtom, chapterNamesAtom } from "../../../../state"; // Assuming you have a selectedChapterAtom to track the selected chapter index
import useApiUtils from '../../../../useApiUtils';

const Manuscript = (props) => {


  const containerRef = useRef(null);
  const [content, setContent] = useRecoilState(contentAtom);
  const [highlightedText, setHighlightedText] = useRecoilState(highlightedTextAtom);
  const [currentManuscript, setCurrentManuscript] = useRecoilState(currentManuscriptAtom);
  const [notes, setNotes] = useRecoilState(notesAtom);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [changeInNotes, setChangeInNotes] = useRecoilState(changeInNotesAtom);
  const [chapterNames, setChapterNames] = useRecoilState(chapterNamesAtom);
  const[ inithighlight , setInithighlight] = useRecoilState(initialHighlightState);
  const [afterGetBookChapte, setAfterGetBookChapte] = useRecoilState(afterGetBookChapter);
  const [notes_highlights, setNotes_highlights] = useRecoilState(notes_highlightsState);



  const {
    getManuscriptsInfo,
    initialgetBookChapter,
    get_book_scores,
    getbookgenrekeywords,
    get_user_info,
    getCompanyInformation,
    processResponse,

    // Add other functions from the hook if needed
  } = useApiUtils()


  

  

  useEffect(() => {

    scrollToChapter(props.selectedChapter);
  }, [props.selectedChapter]);

  const scrollToChapter = (chapterIndex) => {
    const chapterElement = containerRef.current.querySelector(
      `#chapter-${chapterIndex + 1}`
    );
    if (chapterElement) {
      chapterElement.scrollIntoView({ behavior: "smooth" });
    }
  };


  const updateHighlights = (chapterName, highligh) => {
    setHighlightedText((prevHighlightedText) =>
      prevHighlightedText.map((chapter) =>
        chapter.chapter === chapterName
          ? { ...chapter, highlights: [...highligh] }
          : chapter
      )
    );
  };


  return (
    <div className={styles.FrameRootRoot} ref={containerRef}>
      {content.map((chapter, index) => (
        <div key={index} className={styles.chapter} id={`chapter-${index + 1}`}>
          <TextEditor
            // highlightRanges={chapter.highlights}
            initialState={chapter.text}
            title={chapter.chapter_name}
            updateHighlights={updateHighlights}
            notesAndHighlights={
              notes_highlights.find(item => item.chapter_name === chapter.chapter_name)?.notesAndHighlights || []
            }
          />
        </div>
      ))}
    </div>
  );
};

export default Manuscript;
