



import React, { useState, useEffect } from "react";
import styles from "./MatchGenresList.module.css";
import ListGenresMatch from "./ListGenresMatch";
import axios from "axios";
import { useRecoilState } from "recoil";
import { userInfoState, isNewVersionSimilarBooksState, currentManuscriptAtom, genresState, keywordsState, genresDataState, loadingStateCompare } from "../../../state";
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';




const MatchGenresList = (props) => {
  const [genres, setGenres] = useRecoilState(genresState);
  const [keywords, setKeywords] = useRecoilState(keywordsState);
  const [currentManuscript, setCurrentManuscript] = useRecoilState(currentManuscriptAtom);
  const [userInfo, setUserInfo] = useRecoilState(userInfoState); // Update the path

  const [genresData, setGenresData] = useRecoilState(genresDataState); //for the books
  const [loading, setLoading] = useRecoilState(loadingStateCompare); //for the books
  const [totalBooks, setTotalBooks] = useState(0);
  const [nelsonSimilaBooks, setNelsonSimilaBooks] = useRecoilState(isNewVersionSimilarBooksState);

  useEffect(() => {
    if (nelsonSimilaBooks) {
      const updatetotalBooks = Object.keys(genresData).length;
      setTotalBooks(updatetotalBooks);
    }
    else {
      const updatetotalBooks = Object.values(genresData).reduce(
        (totalCount, genreBooks) => totalCount + genreBooks.length,
        0
      );
      setTotalBooks(updatetotalBooks);
    }
  }, [genresData, nelsonSimilaBooks]);



  return (
    <div className={styles.allcontainer} >
      {loading ? (
        <div className={styles.loadingContainer} >
          <div className={styles.titleWrapperloading}>
            <Skeleton animation="wave" variant="rectangular" height="4vh" borderRadios='8px'
              style={
                { borderRadius: '12px', background: 'linear-gradient(104deg, rgba(240, 244, 245, 0.60) 50%, rgba(221, 222, 224, 0.00) 100%)' }
              } />
            <Skeleton animation="wave" variant="rectangular" height="2vh" borderRadios='8px'
              style={
                { borderRadius: '12px', background: 'linear-gradient(104deg, rgba(240, 244, 245, 0.60) 50%, rgba(221, 222, 224, 0.00) 100%)' }
              } />
          </div>
          <div className={styles.titleWrapperloading}>
            <Skeleton animation="wave" variant="rectangular" height="2vh" borderRadios='8px'
              style={
                { marginBottom: '5vh', borderRadius: '12px', background: 'linear-gradient(104deg, rgba(240, 244, 245, 0.60) 50%, rgba(221, 222, 224, 0.00) 100%)' }
              } />
            <Skeleton animation="wave" variant="rectangular" height="7vh" borderRadios='8px'
              style={
                { borderRadius: '12px', background: 'linear-gradient(104deg, rgba(240, 244, 245, 0.60) 50%, rgba(221, 222, 224, 0.00) 100%)' }
              } />
            <Skeleton animation="wave" variant="rectangular" height="7vh" borderRadios='8px'
              style={
                { borderRadius: '12px', background: 'linear-gradient(104deg, rgba(240, 244, 245, 0.60) 50%, rgba(221, 222, 224, 0.00) 100%)' }
              } />
            <Skeleton animation="wave" variant="rectangular" height="7vh" borderRadios='8px'
              style={
                { borderRadius: '12px', background: 'linear-gradient(104deg, rgba(240, 244, 245, 0.60) 50%, rgba(221, 222, 224, 0.00) 100%)' }
              } />
          </div>

          <div className={styles.titleWrapperloading}>
            <Skeleton animation="wave" variant="rectangular" height="2vh" borderRadios='8px'
              style={
                { marginBottom: '5vh', borderRadius: '12px', background: 'linear-gradient(104deg, rgba(240, 244, 245, 0.60) 50%, rgba(221, 222, 224, 0.00) 100%)' }
              } />
            <Skeleton animation="wave" variant="rectangular" height="7vh" borderRadios='8px'
              style={
                { borderRadius: '12px', background: 'linear-gradient(104deg, rgba(240, 244, 245, 0.60) 50%, rgba(221, 222, 224, 0.00) 100%)' }
              } />
            <Skeleton animation="wave" variant="rectangular" height="7vh" borderRadios='8px'
              style={
                { marginBottom: '1vh', borderRadius: '12px', background: 'linear-gradient(104deg, rgba(240, 244, 245, 0.60) 50%, rgba(221, 222, 224, 0.00) 100%)' }
              } />

          </div>
        </div>
      ) : (
        <div className={styles.container}>
          {totalBooks > 0 && (
            <div className={styles.titleWrapper}>
              <div className={styles.title}>
                {totalBooks} Books Found Matching Tags
              </div>
            </div>
          )}
          {totalBooks > 0 && (
            <div className={styles.genres}>
                <ListGenresMatch
                  books={genresData}
                />
            </div>
          )}
          {totalBooks === 0 && (
            <div className={styles.noBookscard}>
              <div className={styles.noBooks}>
                Sorry, we can't provide comparisons for this type of book. Please try with a different title.
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MatchGenresList;

