import React, { useState } from 'react';
import styles from './AddNote.module.css';
import { useRecoilState } from "recoil";
import {userInfoState, userRejected_ApprovedNoteState, notes_highlightsState, notesAtom ,changeInNotesAtom, clickedManuscriptAtom} from "../../../state";
import useApiUtils from '../../../useApiUtils';



const AddNote = (props) => {
  const [noteTitle, setNoteTitle] = useState('');
  const [changeInNotes, setChangeInNotes] = useRecoilState(changeInNotesAtom);
  const [notes_highlights, setNotes_highlights] = useRecoilState(notes_highlightsState);
  const [noteApproveReject, setNoteApproveReject] = useRecoilState(userRejected_ApprovedNoteState);
  const [clickedManuscript, setClickedManuscript] = useRecoilState(clickedManuscriptAtom);
  const [userInfo, setUserInfo] = useRecoilState(userInfoState); // Update the path

  const {
    getManuscriptsInfo,
    initialgetBookChapter,
    get_book_scores,
    getbookgenrekeywords,
    sendRejection,
    sendApprove,
    // save_user_book_notes_highlights,
    save_user_book_notes_highlights_add_notes,
    save_user_book_notes_highlights_delete_notes,

    // Add other functions from the hook if needed
  } = useApiUtils()

  const handleSaveNote = () => {
    const currentDate = new Date();
    const currentTime = currentDate.toLocaleTimeString('en-US', {
      hour12: false,
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    });
    const formattedDateTime = currentDate.toISOString().split('T')[0] + ' ' + currentTime;

    const newNote = {
      text: noteTitle,
      date: formattedDateTime,
      owner_name: userInfo.name,
      user:userInfo.user_id,
      index: [props.start, props.end],
      id: `${props.chapter} ${props.start} ${props.end}`,
      chapter: props.chapter,
      type: 'note',
      id_pos:`${props.chapter} ${props.start} ${props.end}`


    };
    setChangeInNotes(true);

    setNotes_highlights((prevNotes) => {
      const updatedNotesHighlights = [...prevNotes];
  
      for (let i = 0; i < updatedNotesHighlights.length; i++) {
        if (updatedNotesHighlights[i].chapter_name === props.chapter) {
  
          if (!Array.isArray(updatedNotesHighlights[i].notesAndHighlights)) {
            updatedNotesHighlights[i].notesAndHighlights = [];

          }

          const filteredNotesAndHighlights = updatedNotesHighlights[i].notesAndHighlights.filter((item) => {
            const [itemStart, itemEnd] = item.index;
            // Check if the item's range does not overlap with the current range
            if (itemEnd <= props.start || itemStart >= props.end) {
              return true; // Keep items that do not overlap
            } else {
              // Send the item to delete function
              save_user_book_notes_highlights_delete_notes(item ,clickedManuscript.book_id);
              return false; // Do not keep items that overlap
            }
          });

          const newnotesAndHighlights = [
              ...filteredNotesAndHighlights,
              newNote
            ];
    
          newnotesAndHighlights.sort((a, b) => b.index[1] - a.index[1]);
    
          // Create a new object with the updated property
          updatedNotesHighlights[i] = {
            ...updatedNotesHighlights[i],
            notesAndHighlights: newnotesAndHighlights
          };
    
          break;
        }
      }
      // save_user_book_notes_highlights(updatedNotesHighlights ,noteApproveReject)
      save_user_book_notes_highlights_add_notes(newNote,clickedManuscript.book_id )

      return updatedNotesHighlights;
    });
  
  
    props.HideNote();
  };


  const handleNoteTitleChange = (event) => {

    setNoteTitle(event.target.value);

  };


  const handleCancelNote = () => {
    // Pass the noteTitle to the parent component or perform any other action
    props.HideNote();

  };

  return (
    <div className={styles.NotesSectionRoot}>
      <div className={styles.NoteSection}>
        <textarea
          type="text"
          className={styles.NoteTitle}
          value={noteTitle}
          onChange={handleNoteTitleChange}
          placeholder="Write a note"
        />
      </div>
      <div className={styles.ButtonsSection}>
        <button className={styles.CancelButtonContainer}>
          <div className={styles.CancelButton} onClick={handleCancelNote}> Cancel</div>
        </button>
        <button className={styles.SaveButtonContainer} onClick={handleSaveNote}>
          <div className={styles.SaveButton}>Save</div>
        </button>
      </div>
    </div>
  );
};

export default AddNote;



