import styles from './EditNote.module.css';
import React, { useState } from 'react';
import { useRecoilState } from "recoil";
import {userInfoState, userRejected_ApprovedNoteState, notes_highlightsState, notesAtom } from "../../../state";
import useApiUtils from '../../../useApiUtils';



const EditNote = (props) => {

  const [noteTitle, setNoteTitle] = useState(props.initialText);
  const [notes_highlights, setNotes_highlights] = useRecoilState(notes_highlightsState);

  const [noteApproveReject, setNoteApproveReject] = useRecoilState(userRejected_ApprovedNoteState);

  const [userInfo, setUserInfo] = useRecoilState(userInfoState); // Update the path

  const {
    getManuscriptsInfo,
    initialgetBookChapter,
    get_book_scores,
    getbookgenrekeywords,
    sendRejection,
    sendApprove,
    save_user_book_notes_highlights_edit_notes,

    // Add other functions from the hook if needed
  } = useApiUtils()


  const handleNoteTitleChange = (event) => {
    setNoteTitle(event.target.value);
  };


  const handleEditSave = () => {
    const currentDate = new Date();
    const currentTime = currentDate.toLocaleTimeString('en-US', {
      hour12: false,
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    });
    const formattedDateTime = currentDate.toISOString().split('T')[0] + ' ' + currentTime;

    const updatedNotesHighlights = notes_highlights.map((chapter) => {
      if (chapter.chapter_name === props.chapter) {
        const updatedNotesAndHighlights = chapter.notesAndHighlights.map((note) => {
          if (note.id_pos === props.id) {
            save_user_book_notes_highlights_edit_notes({
              ...note,
              text: noteTitle,
              date: formattedDateTime,
              owner_name: userInfo.name,
              user:userInfo.user_id,            }, props.bookd)

            return {
              ...note,
              text: noteTitle,
              date: formattedDateTime,
            };
          }
          return note;
        });

        return {
          ...chapter,
          notesAndHighlights: updatedNotesAndHighlights,
        };
      }
      return chapter;
    });
    setNotes_highlights(updatedNotesHighlights);
    props.HideNote(false);
  };






    const handleCancelNote = () => {
      props.HideNote(false);
    };

    // const handleSaveNote = () => {

    //     props.HideNote(false);    };
    //     const newNoteText = noteTitle
    // props.onSave(props.id, newNoteText);

    return (
      <div className={styles.NotesSectionRoot}>
        <div className={styles.NoteSection}>
          <textarea
            type="text"
            className={styles.NoteTitle}
            value={noteTitle}
            onChange={handleNoteTitleChange}
            placeholder="Write a note"
          />
        </div>
        <div className={styles.ButtonsSection}>
          <button className={styles.CancelButtonContainer}>
            <div className={styles.CancelButton} onClick={handleCancelNote}> Cancel</div>
          </button>
          <button className={styles.SaveButtonContainer} onClick={handleEditSave}>
            <div className={styles.SaveButton}>Save</div>
          </button>
        </div>
      </div>
    );
  };

  export default EditNote;