import React, { useEffect, useRef } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';
import TableManuscripts from './TableManuscripts';
import styles from './SubmissionsPage.module.css';
import download from '../../icons/vuesax/outline/document-download.svg';
import editfield from '../../icons/editfield.svg';
import keyI from '../../icons/vuesax/twotone/filter.svg';
import { useState } from 'react';
import AboutBook from './popupBook/AboutBook';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { useNavigate } from 'react-router-dom';
import {
  fileState,
  selectManuscriptByRatingState,
  fileInfoState,
  userInfoState,
  numberOfUploadedFilesState,
  pendingProcessingState,
  startPollingState,
  loadingmanuscriptState,
  manuscriptInfoListAtom,
  books_id_dwonloadState,
} from '../../state';
import Filter from './popupBook/Filter';
import Edit from './manuOption/Edit';
import Delete from './manuOption/Delete';
import EditFields from './edittable/EditFields';
import NavigationBar from '../../NavigationBar';
import LinearProgress from '@mui/material/LinearProgress';
import UserMenuDropdown from '../../components/propileInfo/UserMenuDropdown';
import useApiUtils from '../../useApiUtils';
import icontrash from '../../icons/icontrash.svg';
import CircularProgress from '@mui/material/CircularProgress';

import Email from './Email';
import Header from '../header/header';
import DeleteFiles from './popupBook/DeletesFiles';
import { renderCenteredAlert } from '../CenteredAlert';

const SubmissionsPage = () => {
  const [sideManu, setSideManu] = useState(false);
  const [numberOfUploadedFiles, setNumberOfUploadedFiles] = useRecoilState(
    numberOfUploadedFilesState
  );
  const [userInfo, setUserInfo] = useRecoilState(userInfoState); // Update the path

  const [edit, setEdit] = useState(false);
  const [deleteManu, setDeleteManu] = useState(false);
  const [showEditFields, setShowEditFields] = useState(false);
  const navigate = useNavigate();
  const [manuscriptInfoList, setManuscriptInfoList] = useRecoilState(
    manuscriptInfoListAtom
  );
  const [loadingmanuscript, setLoadingmanuscript] = useRecoilState(
    loadingmanuscriptState
  );
  const [pendingProcessing, setPendingProcessing] = useRecoilState(
    pendingProcessingState
  );
  // const menuscriptNumber = 29
  const [showBookinfo, setShowBookInfo] = useState(false);
  const [id_manuscript, setId_manuscript] = useState(1);
  const [selectManuscriptByRating, setSelectManuscriptByRating] =
    useRecoilState(selectManuscriptByRatingState);

  const [showFilter, setShowFilter] = useState(false);

  const [saveButtonState, setSaveButtonState] = useState('normal'); // 'normal', 'saving', 'success', 'failure'
  const [openEmail, setOpenEmail] = useState(false);

  const [files, setFiles] = useRecoilState(fileState);
  const [fileInfo, setFileInfo] = useRecoilState(fileInfoState);

  const {
    getManuscriptsInfo,
    initialgetBookChapter,
    get_book_scores,
    getbookgenrekeywords,
    downloadManuscripts,
    AssignUser,
    handleLogoutClick,
    downloadEmail,
    // Add other functions from the hook if needed
  } = useApiUtils();

  const handleShowFilter = (ans) => {
    setShowFilter(ans);
  };

  const showFilterclick = () => {
    setShowFilter(true);
  };

  useEffect(() => {
    if (selectManuscriptByRating) {
      setShowBookInfo(true);
      setSelectManuscriptByRating(false);
    }
  }, [selectManuscriptByRating]);

  useEffect(() => {
    if (!localStorage.getItem('id_token')) {
      navigate('/');
    }
  }, []);

  const handelSelectManuscript = (show) => {
    if (show == true) {
      setShowBookInfo(true);
    } else {
      setShowBookInfo(false);
    }
  };

  const showEditField = () => {
    setShowEditFields(true);
  };

  const [ispolling, setIspolling] = useRecoilState(startPollingState);

  useEffect(() => {
    if (pendingProcessing > 0) {
      setLoadingmanuscript(true);
    } else {
      setLoadingmanuscript(false);
    }
  }, [pendingProcessing]);

  const startPolling = () => {
    if (pendingProcessing > 0) {
      getManuscriptsInfo(false); // Call your function here to fetch data
    }
  };

  // Use the useInterval hook with the desired delay (20000ms)
  useInterval(startPolling, pendingProcessing > 0 ? 20000 : null);

  /////////show success message////////

  const [showSuccess, setShowSuccess] = useState(false);

  const showSuccessMessage = (message) => {
    setShowSuccess(true);
    setTimeout(() => {
      setShowSuccess(false);
    }, 5000);
  };

  const handleNavigateUpload = () => {
    setFileInfo([]);
    setFiles([]);
    navigate('/upload');
  };

  const handleEdit = (ans) => {
    setEdit(ans);
    setShowBookInfo(false);
  };

  const handleDelete = (ans) => {
    setDeleteManu(ans);
    setShowBookInfo(false);
  };

  const handleShowManuSide = () => {
    setSideManu(!sideManu);
  };

  const [dwonload, setDwonload] = useRecoilState(books_id_dwonloadState);

  const handleDownload = async () => {
    if (saveButtonState === 'loading') {
      return;
    }
    if (dwonload.length === 0) {
      renderCenteredAlert('please select a book to download');
      return;
    }
    setSaveButtonState('loading');

    try {
      await downloadManuscripts(dwonload);
      setDwonload([]);
      setSaveButtonState('normal');
    } catch (error) {
      setSaveButtonState('normal');
    }
  };

  const [deletefewfiles, setDeletefewfiles] = useState(false);
  const handleDeletefewfiles = () => {
    if (dwonload.length === 0) {
      renderCenteredAlert('please select a book to delete');
      return;
    }
    setDeletefewfiles(true);
  };

  const handleDeletefew = (ans) => {
    setDeletefewfiles(ans);
    setShowBookInfo(false);
  };

  const fetchManuscriptsInfo = () => {
    getManuscriptsInfo(false); // Call your function to fetch data
  };

  // Use the useInterval hook with a 15-minute delay (15 * 60 * 1000 milliseconds)
  useInterval(fetchManuscriptsInfo, 15 * 60 * 1000);

  const handleShowEmail = (ans) => {
    setOpenEmail(ans);
  };

  return (
    <div className={styles.submissionsPageContainer}>
      <Header
        issubmission={true}
        issettings={false}
        show={handleShowManuSide}
      />

      {showSuccess && (
        <div
          className={`${styles.successMessage} ${styles.successMessageLeft}`}
        >
          <Stack sx={{ width: '50%' }} spacing={2}>
            <Alert severity="success">
              {' '}
              Your File Was downloaded Successfully!
            </Alert>
          </Stack>
        </div>
      )}
      {showFilter && <Filter return={handleShowFilter} />}
      {openEmail && <Email return={handleShowEmail} />}
      {edit && <Edit handleEdit={handleEdit} />}
      {deleteManu && <Delete handleDelete={handleDelete} />}
      {deletefewfiles && (
        <DeleteFiles
          handleDeletefewManuscripts={handleDeletefew}
          fewManuscripts={deletefewfiles}
        />
      )}
      {showEditFields && <EditFields setShowEditFields={setShowEditFields} />}
      {showBookinfo && (
        <AboutBook
          id_manuscript={id_manuscript}
          return={setShowBookInfo}
          showSuccessMessage={showSuccessMessage}
          fewManuscripts={deletefewfiles}
        />
      )}
      <div className={styles.submissionsPageC}>
        <div className={styles.submissionsPage}>
          <div className={styles.submissionsPageTitle}>
            My Manuscripts ({manuscriptInfoList?.length || 0})
          </div>
          <div className={styles.allManuscripyPart2}>
            {/* {numberOfUploadedFiles > 0 && (
                            <div className={styles.progress}>
                                <label htmlFor="file">{pendingProcessing} books left to process</label>

                                <progress id="file" max={numberOfUploadedFiles} value={numberOfUploadedFiles - pendingProcessing}
                                            style={{ color: '#1CEAA0' }} 
>
                                    {((numberOfUploadedFiles - pendingProcessing) / numberOfUploadedFiles) * 100}%
                                </progress>
                               

                            </div>
                        )} */}

            {/* <button className={styles.keyicon} onClick={showFilterclick}>
                            <img className={styles.imgKey} src={keyI} />
                        </button> */}

            <button
              className={styles.buttonDownload}
              onClick={handleDeletefewfiles}
            >
              <img className={styles.downimg} src={icontrash} />
              <div className={styles.dwomtext}>Delete </div>
            </button>
            {saveButtonState === 'normal' && (
              <button
                className={styles.buttonDownload}
                onClick={handleDownload}
              >
                <img className={styles.downimg} src={download} />
                <div className={styles.dwomtext}>Download </div>
              </button>
            )}
            {saveButtonState !== 'normal' && (
              <button
                className={styles.buttonDownload}
                onClick={handleDownload}
              >
                <CircularProgress size={15} color="inherit" />
              </button>
            )}
            <button className={styles.add} onClick={handleNavigateUpload}>
              <div className={styles.addText}>+ Add New </div>
            </button>
          </div>
        </div>
        {/* <div className={styles.progress}> */}
        {loadingmanuscript && (
          <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
            <LinearProgress color="inherit" />
          </Stack>
        )}
        <div className={styles.tableManuscripts}>
          <TableManuscripts
            selectManuscript={handelSelectManuscript}
            handleDelete={handleDelete}
            handleEdit={handleEdit}
            dwonload={download}
            setDwonload={setDwonload}
            openEmail={setOpenEmail}
          />
        </div>
      </div>
    </div>
  );
};

export default SubmissionsPage;

function useInterval(callback, delay) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const intervalId = setInterval(tick, delay);
      return () => clearInterval(intervalId);
    }
  }, [delay]);
}

// export default useInterval;
